import { FC, forwardRef } from "react";

import { Cancel, Search } from "@mui/icons-material";
import { Box, Button, SxProps, IconButton, ButtonProps } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Input, InputLoader } from "@/shared/ui";

type TSearch = {
  sxInput: SxProps;
  disabled: boolean;
  isLoading?: boolean;
  onClick: () => void;
  placeholder: string;
  onClear?: () => void;
  value: null | string;
  showClearIcon: boolean;
  buttonProps: ButtonProps;
  searchButtonText: string;
  showSearchButton: boolean;
  onChange: (arg: string) => void;
};

const EndAdornment: FC<
  Pick<TSearch, "value" | "isLoading" | "showClearIcon"> & {
    handleClearClick: () => void;
  }
> = ({ value, isLoading, showClearIcon, handleClearClick }) => {
  if (isLoading) {
    return <InputLoader isLoading />;
  }

  if (showClearIcon && value?.length !== 0) {
    return (
      <IconButton sx={styles.closeButton} onClick={handleClearClick}>
        <Cancel style={styles.clearIcon} />
      </IconButton>
    );
  }

  return null;
};

// eslint-disable-next-line react/display-name
export const SearchInput = forwardRef<HTMLInputElement, TSearch>(
  (
    {
      onClear,
      value = "",
      sxInput = {},
      disabled = false,
      buttonProps = {},
      isLoading = false,
      onClick = () => null,
      showClearIcon = false,
      onChange = () => null,
      showSearchButton = false,
      searchButtonText = "FIND",
      placeholder = "Введите сюда текст",
    },
    ref,
  ) => {
    const t = useTranslations();
    const handleOnChange = (val: string) => onChange(val);
    const handleOnClearClick = () => onClear?.();

    return (
      <Box sx={styles.wrapper}>
        <Input
          ref={ref}
          onClick={onClick}
          sxInput={sxInput}
          disabled={disabled}
          value={value ?? ""}
          showFocusRing={false}
          onChange={handleOnChange}
          placeholder={placeholder}
          sxWrapper={styles.sxWrapper}
          inputWrapper={styles.inputWrapper}
          startAdornment={
            <Search
              sx={{
                color:
                  value?.length !== 0 ? "primary.main" : "content.grey-200",
              }}
            />
          }
          endAdornment={
            <EndAdornment
              value={value}
              isLoading={isLoading}
              showClearIcon={showClearIcon}
              handleClearClick={handleOnClearClick}
            />
          }
        />
        {showSearchButton && (
          <Button
            variant="contained"
            disabled={disabled}
            {...buttonProps}
            disableElevation
          >
            {t(searchButtonText)}
          </Button>
        )}
      </Box>
    );
  },
);
