import { TCroppedPreviews } from "@/shared/model/types/constructor-parser";

export enum EPreviewType {
  Default = "default",
  MainPreview = "main_preview",
  ListPreview = "list_preview",
  InnerPreview = "inner_preview",
}

type TGetPreview = {
  isPhone: boolean;
  type: EPreviewType;
  preview: null | string;
  croppedPreviews: [] | TCroppedPreviews;
};

export const getCroppedPreview = ({
  type,
  isPhone,
  preview,
  croppedPreviews,
}: TGetPreview) => {
  const deviceType = isPhone ? "mobile" : "desktop";
  if (
    !Array.isArray(croppedPreviews) &&
    croppedPreviews[type]?.[deviceType].image
  ) {
    return croppedPreviews[type]?.[deviceType].image;
  }

  if (preview) {
    return preview;
  }

  return undefined;
};
