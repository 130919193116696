import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

type TProps = {
  children?: JSX.Element | JSX.Element[];
};

export const TourNotFoundSimple: FC<TProps> = ({ children }) => {
  const t = useTranslations();

  return (
    <Box sx={styles.tourNotFound}>
      <Typography sx={styles.title}>{t("NOT_FOUND")}</Typography>
      <Typography sx={styles.subtitle}>
        {t("TRY_CHANGING_SEARCH_PARAMETERS")}
      </Typography>

      {children && <Box sx={styles.actionsWrapper}>{children}</Box>}
    </Box>
  );
};
