import { FC, memo } from "react";

import { SxProps, Breakpoint } from "@mui/material";

import { styles } from "./styles";

import { DialogWrapper, SwipeableDrawer } from "@/shared/ui";

type TProps = {
  title: string;
  isOpen: boolean;
  isPhone?: boolean;
  desktopSx?: SxProps;
  onOpen?: () => void;
  onClose: () => void;
  fullHeight?: boolean;
  Footer?: JSX.Element;
  hideBackdrop?: boolean;
  Content: string | JSX.Element;
  onBackButtonClick?: () => void;
  desktopWidth?: false | Breakpoint;
};

export const AdaptiveDrawer: FC<TProps> = memo(
  ({
    title,
    isOpen,
    Footer,
    onClose,
    isPhone,
    Content,
    desktopSx = {},
    onBackButtonClick,
    fullHeight = false,
    onOpen = () => null,
    hideBackdrop = true,
    desktopWidth = false,
  }) => {
    if (isPhone) {
      return (
        <SwipeableDrawer
          title={title}
          Footer={Footer}
          onOpen={onOpen}
          isOpen={isOpen}
          onClose={onClose}
          hideBackdrop={hideBackdrop}
          onBackButtonClick={onBackButtonClick}
          backButton={Boolean(onBackButtonClick)}
          childrenWrapperSx={styles.childrenWrapperSx}
          paperSx={{
            ...styles.paperSx,
            height: fullHeight ? "100vh" : "auto",
          }}
        >
          {Content}
        </SwipeableDrawer>
      );
    }

    return (
      <DialogWrapper
        keepMounted
        open={isOpen}
        title={title}
        Footer={Footer}
        onClose={onClose}
        maxWidth={desktopWidth}
        sxTitle={styles.dialogTitle}
        sx={{ ...styles.dialog, ...desktopSx }}
      >
        {Content}
      </DialogWrapper>
    );
  },
);
