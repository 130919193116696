import { FC } from "react";

import { Box, CircularProgress } from "@mui/material";

import { styles } from "./styles";

type TProps = {
  isLoading: boolean;
};

export const InputLoader: FC<TProps> = ({ isLoading }) => {
  if (!isLoading) {
    return <Box sx={styles.default} />;
  }

  return (
    <Box sx={styles.wrapper}>
      <CircularProgress size={16} />
    </Box>
  );
};
