/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable perfectionist/sort-objects */
import { Query } from "@farfetched/core";
import { createFactory } from "@withease/factories";
import { sample, createEvent, createEffect, StoreWritable } from "effector";

export type TResetParams = {
  store?: StoreWritable<any>;
  query?: Query<any, any, any>;
};

export const resetOnLogout = createEvent();

export const handleReset = createFactory((params: TResetParams) => {
  const { query, store } = params;

  const queryOrStore = query || store;

  if (!queryOrStore) {
    throw new TypeError("You should provide Query or store");
  }

  const resetQueryFx = createEffect(() => {
    query?.reset();
    store?.reset();
  });

  sample({
    clock: resetOnLogout,
    target: resetQueryFx,
  });
});
