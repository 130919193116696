export enum ETouristType {
  Adult = "adult",
  Child = "child",
  Infant = "infant",
}

export type TTourist = {
  sex: string;
  id?: string;
  iin: string;
  buyer?: boolean;
  expired?: boolean;
  birthdate: string;
  last_name: string;
  first_name: string;
  type?: ETouristType;
  second_name: string;
  document_type: string;
  document_serie: string;
  document_given: string;
  document_number: string;
  document_issued: string;
  document_expire: string;
  citizenship_country_id: string;
};
