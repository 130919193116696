import { mainTheme } from "shared/ui/styles";

const palette = mainTheme.palette;

export const styles = {
  phone: { width: "100%" },

  linkText: (isDefaultColor: boolean) => ({
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    marginRight: "8px",
    color: isDefaultColor ? palette.content.primary : palette.dominant.white,
  }),

  linkWrapper: {
    transition: "0.2s",

    [mainTheme.breakpoints.up("sm")]: {
      "&:hover": {
        opacity: "0.7",
      },
    },

    "& a": {
      display: "flex",
      columnGap: "9px",
      PointerEvent: "none",
      alignItems: "center",
      textDecoration: "none",
    },
  },
};
