"use client";

import { FC } from "react";

import { Tab, Tabs, TabProps, TabsProps } from "@mui/material";

import { tabStyles, tabsStyles } from "./styles";

type TCommonProps = {
  appearance?: "primary" | "secondary";
};

export const CustomTabs: FC<TabsProps & TCommonProps> = (props) => {
  const { appearance = "secondary" } = props;
  return (
    <Tabs
      variant="fullWidth"
      sx={[tabsStyles.common, tabsStyles[appearance]]}
      {...props}
    />
  );
};

export const CustomTab: FC<TabProps & TCommonProps> = (props) => {
  const { appearance = "secondary" } = props;
  return (
    <Tab
      sx={[tabStyles.common, tabStyles[appearance]]}
      {...props}
      disableRipple
    />
  );
};
