import { FC } from "react";

import { ArrowForward } from "@mui/icons-material";
import { Box, Button, SxProps, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Link, RoundedBorderContainer } from "@/shared/ui";

type TLinkProps = {
  target: "_top" | "_self" | "_blank" | "_parent";
};

type TProps = {
  id?: string;
  text: string;
  href?: string;
  icon?: JSX.Element;
  wrapperSx?: SxProps;
  onClick?: () => void;
  isSeparate?: boolean;
  linkProps?: TLinkProps;
  iconSize?: "large" | "small" | "medium" | "inherit";
};

const CardWrapper = ({
  id,
  href,
  onClick,
  children,
  linkProps = {
    target: "_self",
  },
}: Pick<TProps, "id" | "href" | "onClick" | "linkProps"> & {
  children: JSX.Element;
}) => {
  if (href) {
    return (
      <Link id={id} href={href} target={linkProps.target}>
        {children}
      </Link>
    );
  }

  if (onClick) {
    return (
      <Button id={id} fullWidth onClick={onClick} sx={styles.button}>
        {children}
      </Button>
    );
  }

  return children;
};

export const SupportActionCard: FC<TProps> = ({
  id,
  href,
  text,
  icon,
  onClick,
  linkProps,
  wrapperSx = {},
  isSeparate = false,
  iconSize = "medium",
}) => {
  const t = useTranslations();

  const CommonCard = (
    <CardWrapper id={id} href={href} onClick={onClick} linkProps={linkProps}>
      <Box sx={styles.actionCard}>
        <Box sx={styles.flexBox}>
          {icon}
          <Typography sx={styles.text(Boolean(icon))}>{t(text)}</Typography>
        </Box>
        <Box sx={styles.arrowWrapper}>
          <ArrowForward color={"primary"} fontSize={iconSize} />
        </Box>
      </Box>
    </CardWrapper>
  );

  if (isSeparate) {
    return (
      <RoundedBorderContainer sx={{ ...wrapperSx, ...styles.containerPadding }}>
        {CommonCard}
      </RoundedBorderContainer>
    );
  }

  return <Box sx={wrapperSx}>{CommonCard}</Box>;
};
