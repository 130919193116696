export const styles = {
  divider: {
    borderColor: "dominant.cloud",
  },

  contentWrapper: {
    rowGap: "12px",
    display: "flex",
    flexDirection: "column",
  },

  wrapper: {
    width: "100%",
    rowGap: "16px",
    display: "flex",
    marginBottom: "16px",
    flexDirection: "column",
  },
};
