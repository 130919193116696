import { FC, useRef, ChangeEvent } from "react";

import { Box, Divider } from "@mui/material";

import { NewInput } from "./NewInput";
import { styles } from "./styles";
import { TNewInput } from "./types";

type TProps = {
  firstInputConfig: TNewInput;
  secondInputConfig: TNewInput;
};

export const NewDoubleInput: FC<TProps> = ({
  firstInputConfig,
  secondInputConfig,
}) => {
  const firstInputRef = useRef<HTMLInputElement>(null);
  const secondInputRef = useRef<HTMLInputElement>(null);

  const handleFirstInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (firstInputConfig.onChange) {
      firstInputConfig.onChange(event);
      return;
    }

    if (!firstInputConfig.inputProps?.maxLength) {
      return;
    }

    if (event.target.value.length >= firstInputConfig.inputProps.maxLength) {
      secondInputRef.current?.focus();
    }
  };

  const handleSecondInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (secondInputConfig.onChange) {
      secondInputConfig.onChange(event);
      return;
    }

    if (!event.target.value.length) {
      firstInputRef.current?.focus();
    }
  };

  return (
    <Box sx={styles.doubleInputWrapper}>
      <NewInput
        ref={firstInputRef}
        inputWrapperSx={styles.inputWrapperLeft}
        {...firstInputConfig}
        onChange={handleFirstInputChange}
      />

      <Divider sx={styles.hr} orientation="vertical" />

      <NewInput
        ref={secondInputRef}
        inputWrapperSx={styles.inputWrapperRight}
        {...secondInputConfig}
        onChange={handleSecondInputChange}
      />
    </Box>
  );
};
