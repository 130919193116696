export const styles = {
  iconWrapper: {
    width: "14px",

    "& svg": {
      color: "success.main",
    },
  },

  tooltipWrapper: {
    display: "flex",
    columnGap: "8px",
    backgroundColor: "dominant.dop",
  },
};
