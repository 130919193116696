import { FC } from "react";

import { Box, CircularProgress } from "@mui/material";

import { styles } from "./styles";

export const SwitchLoader: FC = () => {
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress size={16} />
    </Box>
  );
};
