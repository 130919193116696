import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/system";

import type { TCustomTheme } from "@/shared/ui/styles/theme/types";

export const FabIconButton = styled(IconButton)(({
  theme,
}: {
  theme: TCustomTheme;
}) => {
  return {
    width: "32px",
    height: "32px",
    outline: "1px solid",
    transition: "all .3s ease",
    outlineColor: "transparent",
    color: theme.palette.grey.dark,
    "& > *": {
      flexShrink: 0,
    },
    backgroundColor: theme.palette.grey.white,
    "&:active": {
      color: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.grey.default,
    },
    "&:focus-visible": {
      color: theme.palette.primary.main,
      outlineColor: theme.palette.grey.extralight,
    },
    "&:disabled": {
      pointerEvents: "none",
      color: theme.palette.grey.extralight,
      backgroundColor: theme.palette.grey.white,
    },
  };
});
