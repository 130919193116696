import { attach } from "effector";

import { TFFRequest } from "../../types/common/entities/common";
import { TOrderResponseData } from "../../types/orders";
import { TProfileCounters } from "../../types/profile";

import { requestFx } from "@/shared/config";

type TCountersResponse = TFFRequest<TProfileCounters>;
type TLatestOrderResponse = TFFRequest<TOrderResponseData>;

export const getCountersFx = attach<void, TCountersResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: "profile/data-count",
  }),
});

export const getLatestOrderFx = attach<void, TLatestOrderResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: "orders/latest",
  }),
});
