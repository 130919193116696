import { COLORS } from "shared/ui/styles";

export const styles = {
  timeContainer: {
    height: "36px",
    width: "142px",
    display: "flex",
    marginLeft: "8px",
  },

  informationBanner: {
    height: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: COLORS.EXTRA_LIGHT_BLUE,
  },

  p: {
    display: "flex",
    fontSize: "24px",
    fontWeight: "500",
    color: COLORS.BLUE,
    lineHeight: "28px",
    textAlign: "center",
    alignItems: "center",
  },

  box: {
    width: "31px",
    display: "flex",
    minWidth: "31px",
    marginLeft: "4px",
    padding: "4px 8px",
    borderRadius: "6px",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    forColon: {
      background: "none",
      padding: "4px 0px",
    },
    background: `linear-gradient(0deg, ${COLORS.SOLITUDE} 0%, ${COLORS.ALICE_BLUE} 100%)`,
  },
};
