import { mainTheme } from "shared/ui/styles";

export const styles = {
  textMargin: {
    marginBottom: "12px",
  },

  wrapper: {
    width: "100%",
    color: "content.secondary",
  },

  phoneLink: {
    "& a": {
      textDecoration: "none",
      color: "content.secondary",
      transition: "opacity 0.2s",

      [mainTheme.breakpoints.up("smd")]: {
        "&:hover": {
          opacity: 0.7,
        },
      },
    },
  },
};
