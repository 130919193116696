import { Dayjs } from "dayjs";

import { TSearchData, BACKEND_KEYS } from "../../common";

export enum ARRIVAL_STEPS {
  REGIONS = 1,
  COUNTRIES = 0,
}

export type TArrivalSlice = {
  arrival: string;
  isError: boolean;
  regions: null | string[];
  arrivalStep: ARRIVAL_STEPS;
  arrivalFilterByRegion: string;
  arrivalFilterByCountry: string;
};

export type TSearchFetchAvailableCalendarDatesParams = {
  arrivalId: string;
  departureId: string;
};

export type TSearchFetchUpcomingDatesParams = {
  [BACKEND_KEYS.arrival]: string;
  [BACKEND_KEYS.departure]: string;
  [BACKEND_KEYS.rangeStartDay]: string;
  [BACKEND_KEYS.rangeEndDay]?: null | string;
};

export type TSearchFetchNightsParams = {
  [BACKEND_KEYS.arrival]: string;
  [BACKEND_KEYS.departure]: string;
  [BACKEND_KEYS.rangeEndDay]: string;
  [BACKEND_KEYS.rangeStartDay]: string;
};

export type TCalendarData = {
  date: string;
  price: number;
  currency: string;
  cheapest: boolean;
  has_charter_flights: boolean;
};

export type TUpcomingDates = {
  nights: number[];
  date_from: string;
  available: string | number;
  push(arg0: {
    nights: number[];
    available: number;
    date_from: string;
  }): unknown;
};

export type TAvailableNights = {
  nights: number[];
  default: {
    to: number;
    from: number;
  };
};

export type TDays = {
  date: string;
  label: string;
  isAvailable: boolean;
  isCurrentMonth: boolean;
};

export type TMonthsDays = {
  nextMonthDays: TDays[];
  currentMonthDays: TDays[];
};

export type TMobileMonthDays = {
  date: Dayjs;
  days: {
    date: string;
    label: string;
    isAvailable: boolean;
    isCurrentMonth: boolean;
  }[];
}[];

export type TQuerySearchData = Partial<TSearchData> & {
  hotel: null | string;
};
