import { FC } from "react";

import { Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

type TProps = {
  title: string;
  subtitle?: string;
};

export const LabelWithSpan: FC<TProps> = ({
  title,
  subtitle = "LATIN_ALPHABET",
}) => {
  const t = useTranslations();

  return (
    <>
      {title}{" "}
      {!!subtitle && (
        <Typography component="span" sx={styles.span}>
          ({t(subtitle)})
        </Typography>
      )}
    </>
  );
};
