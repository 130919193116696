import { FC, useMemo } from "react";

import { SxProps, Typography } from "@mui/material";

import { CustomTooltip } from "@/shared/ui";

type TPlacement =
  | "top"
  | "left"
  | "right"
  | "bottom"
  | "top-end"
  | "left-end"
  | "top-start"
  | "right-end"
  | "left-start"
  | "bottom-end"
  | "right-start"
  | "bottom-start";

type TProps = {
  title?: string;
  textSx?: SxProps;
  sliceAt?: number;
  placement?: TPlacement;
  customTooltip?: string;
};

const sliceString = (text?: string, sliceAt?: number) => {
  if (!text) return "";

  return `${text.slice(0, sliceAt)}...`;
};

export const TooltipWithTextSlice: FC<TProps> = ({
  title,
  sliceAt = 30,
  customTooltip,
  placement = "top",
  textSx = { margin: 0 },
}) => {
  const slicedText = useMemo(
    () => sliceString(title, sliceAt),
    [sliceAt, title],
  );

  if (!title) return null;

  if (title.length <= sliceAt) {
    return (
      <Typography sx={textSx} component="span">
        {title}
      </Typography>
    );
  }

  return (
    <CustomTooltip
      arrow
      placement={placement}
      title={customTooltip ? customTooltip : title}
    >
      <Typography sx={textSx} component="span">
        {slicedText}
      </Typography>
    </CustomTooltip>
  );
};
