import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { PAYMENT_TEXTS } from "../../../lib/constants";
import { PaymentsDetailsButton } from "../PaymentDetailsModal";
import { PaymentLink } from "../PaymentLink";
import { styles } from "./styles";

import { EFaqCategories } from "@/widgets/orders/OrderLayout/lib/constants";

import { priceFormatter } from "@/shared/lib/helpers";
import { useGetOrderNumber } from "@/shared/lib/hooks";
import { Link, RoundedBorderContainer } from "@/shared/ui";

import InfoIcon from "@/public/icons/common/info.svg";

type TProps = {
  paymentInfo: { value: number; label: string }[];
};

export const PartialPaymentContent: FC<TProps> = ({ paymentInfo = [] }) => {
  const t = useTranslations();
  const { orderNumber } = useGetOrderNumber();

  return (
    <>
      <Box sx={styles.contentWrapper}>
        <RoundedBorderContainer sx={styles.sumsBlockWrapper}>
          {paymentInfo.map(({ value, label }, index) => (
            <Box key={index} sx={styles.sumRowWrapper}>
              <Typography>{t(label)}:</Typography>
              <Typography sx={styles.sumsValue}>
                {priceFormatter(value)}
              </Typography>
            </Box>
          ))}
        </RoundedBorderContainer>
        <RoundedBorderContainer sx={styles.infoBlock}>
          <Box sx={styles.iconWrapper}>
            <InfoIcon />
          </Box>
          <Box>
            <Typography sx={styles.infoText}>
              {t(
                "THE_REMAINING_AMOUNT_IS_UPDATED_DAILY_DEPENDING_ON_THE_EXCHANGE_RATE_OF_THE_DOLLAR",
              )}
            </Typography>
            <Link
              href={{
                pathname: "/voprosy",
                query: { code: EFaqCategories.Search },
              }}
            >
              {t("FIND_OUT_WHY")}
            </Link>
          </Box>
        </RoundedBorderContainer>
        <PaymentsDetailsButton />
        <PaymentLink
          text={t(PAYMENT_TEXTS.linkText)}
          link={`/payment/${orderNumber}`}
        />
      </Box>
    </>
  );
};
