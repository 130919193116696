import { FC } from "react";

import { Error } from "@mui/icons-material";
import {
  Box,
  Step,
  Stepper,
  StepLabel,
  Typography,
  StepContent,
} from "@mui/material";
import { useTranslations } from "next-intl";

import {
  EDetailsSteps,
  PAYMENT_CANCEL_STATUSES,
  ORDER_STATUSES_FOR_PAYMENT_STEP,
} from "../../lib/constants";
import { getActiveStep } from "../../lib/helpers";
import { BookingStep } from "../BookingStep";
import { CancellationStep } from "../CancellationStep";
import { PaymentStep } from "../PaymentStep/PaymentStep";
import { styles } from "./styles";

import { EOrderStatus, EOrderPayment } from "@/shared/lib/constants/orders";
import { TOrderResponseData } from "@/shared/model/types/orders";
import { PaymentStatusBadge, BookingStatusBadge } from "@/shared/ui";

type TProps = {
  orderData?: TOrderResponseData;
};

export const DetailsStepper: FC<TProps> = ({ orderData }) => {
  const t = useTranslations();

  const activeStep = getActiveStep(
    orderData?.order_status.code,
    orderData?.payment_status.code,
  );

  const isBookingPending = Boolean(
    orderData &&
      [EOrderStatus.Created, EOrderStatus.ConfirmationPending].includes(
        orderData.order_status.code,
      ),
  );

  const isPartialPaid =
    orderData?.payment_status.code === EOrderPayment.PartlyPaid;

  const isBookingError = Boolean(
    activeStep === EDetailsSteps.BOOKING &&
      !isBookingPending &&
      orderData &&
      ![EOrderStatus.Ready, EOrderStatus.Confirmed].includes(
        orderData.order_status.code,
      ),
  );

  const isPaymentPossible = Boolean(
    orderData &&
      orderData.payment_status.code === EOrderPayment.Unpaid &&
      ORDER_STATUSES_FOR_PAYMENT_STEP.includes(orderData.order_status.code),
  );
  const arrivalCountry = orderData?.details.hotel.country;

  const isPassengersFilled = !!orderData?.details.passengers.every(
    (passengerDetails) => Object.keys(passengerDetails).includes("id"),
  );
  const isPassengerBuyer = !!orderData?.details.passengers.some(
    (passengerDetails) => passengerDetails.buyer === true,
  );
  const isOrderCanceled = Boolean(
    orderData &&
      PAYMENT_CANCEL_STATUSES.includes(orderData.payment_status.code),
  );

  return (
    <Stepper sx={styles.wrapper} orientation="vertical" activeStep={activeStep}>
      <Step>
        <StepLabel>
          <Box sx={styles.stepLabel}>
            <Typography>{t("PAYMENT")}</Typography>
            <PaymentStatusBadge
              label={orderData?.payment_status.name}
              status={orderData?.payment_status.code}
            />
          </Box>
        </StepLabel>
        <StepContent sx={styles.stepPaymentContent}>
          <PaymentStep
            amount={orderData?.amount}
            isPartialPaid={isPartialPaid}
            arrivalCountry={arrivalCountry}
            isPaymentPossible={isPaymentPossible}
            paymentInfo={orderData?.details.payment_info}
            orderStep={
              isPassengersFilled && isPassengerBuyer ? "payment" : "order"
            }
          />
        </StepContent>
      </Step>

      <Step>
        <StepLabel icon={isBookingError && <Error sx={styles.icon} />}>
          <Box sx={styles.stepLabel}>
            <Typography>{t("BOOKING")}</Typography>
            <BookingStatusBadge
              label={orderData?.order_status.name}
              status={orderData?.order_status.code}
            />
          </Box>
        </StepLabel>
        <StepContent>
          <BookingStep isError={isBookingError} isPending={isBookingPending} />
        </StepContent>
      </Step>

      {isOrderCanceled && (
        <Step>
          <StepLabel icon={<Error sx={styles.icon} />}>
            <Box sx={styles.stepLabel}>
              <Typography>{t("REFUND")}</Typography>
              <PaymentStatusBadge
                label={orderData?.payment_status.name}
                status={orderData?.payment_status.code}
              />
            </Box>
          </StepLabel>
          <StepContent>
            <CancellationStep
              tourPrice={orderData?.details.price}
              cancellationDetails={orderData?.details.canceling}
              isRefundFinished={
                orderData?.payment_status.code === EOrderPayment.Refunded
              }
            />
          </StepContent>
        </Step>
      )}
    </Stepper>
  );
};
