import { FC } from "react";

import { Box, SxProps, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { priceFormatter } from "@/shared/lib/helpers";
import { TDiscount } from "@/shared/model/types/common";
import { RoundedBorderContainer } from "@/shared/ui";

type TTourPrice = {
  price: number;
  fullPrice: number;
};

type TProps = {
  bonusUsed?: number;
  wrapperSx?: SxProps;
  tourPrice: TTourPrice;
  discounts: TDiscount[];
};

type TTextProps = {
  children: string | number;
};

const Text: FC<TTextProps> = ({ children }) => {
  return <Typography sx={styles.text}>{children}</Typography>;
};

export const TourPriceBox: FC<TProps> = ({
  tourPrice,
  bonusUsed = 0,
  discounts = [],
  wrapperSx = { marginBottom: "12px" },
}) => {
  const t = useTranslations();

  return (
    <RoundedBorderContainer sx={wrapperSx}>
      <Box sx={styles.lineContainer}>
        <Text>{t("TOUR_PRICE")}</Text>
        <Text>{priceFormatter(tourPrice.fullPrice)}</Text>
      </Box>

      {discounts.length ? (
        discounts.map(({ id, percent, discount, description }) => (
          <Box key={id} sx={styles.lineContainer}>
            <Text>
              {percent ? `${description} (${percent}%)` : description}
            </Text>
            <Text>{priceFormatter(discount)}</Text>
          </Box>
        ))
      ) : (
        <></>
      )}

      {bonusUsed > 0 && (
        <Box sx={styles.lineContainer}>
          <Text>{t("BONUS_USED")}</Text>
          <Text>{priceFormatter(bonusUsed)}</Text>
        </Box>
      )}

      <Box sx={{ ...styles.lineContainer, ...styles.totalContainer }}>
        <Typography>{t("TOTAL")}:</Typography>
        <Typography>{priceFormatter(tourPrice.price)}</Typography>
      </Box>
    </RoundedBorderContainer>
  );
};
