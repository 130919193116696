import {
  TMeal,
  TImage,
  TAmenity,
  TAirline,
  TDiscount,
  TTourGroup,
  EFlightTypes,
  TPaymentPlan,
  TCountriesTags,
  TFlightListItem,
} from "../../common";

export enum EFlightModalTypes {
  Gds = "gds",
  Change = "change",
  Charter = "charter",
  Actualize = "actualize",
}

type TTourContentRoom = {
  id: string;
  name: string;
  adults: number;
  infants: number;
  children: number;
  images: TImage[];
  area: null | string;
  display_name: string;
  amenities: TAmenity[];
  description: null | string;
};

type TTourOperator = {
  id: string;
  name: string;
  logo: null | string;
};

export type TToursCombinedAsRooms = {
  id: string;
  meal: TMeal;
  price: number;
  options: null;
  nights: number;
  adults: number;
  services: null;
  infants: number;
  check_in: string;
  children: number;
  currency: string;
  check_out: string;
  full_price: number;
  calculated: boolean;
  nights_total: number;
  room: TTourContentRoom;
  tags: TCountriesTags[];
  discounts: TDiscount[];
  tour_group: TTourGroup;
  moment_confirm: boolean;
  flight_type: EFlightTypes;
  itinerary: TFlightListItem;
  airlines: null | TAirline[];
  tour_operator: TTourOperator;
  payment_plan: null | TPaymentPlan;
};
