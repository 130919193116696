import { createQuery } from "@farfetched/core";

import { getHotToursFx, getHeaderMenuDataFx } from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getHotToursQuery = createQuery({
  effect: getHotToursFx,
  name: "getHotToursData",
  mapData: ({ result }) => result.data.data,
});

export const getHeaderMenuQuery = createQuery({
  name: "getHeaderMenuData",
  effect: getHeaderMenuDataFx,
  mapData: ({ result }) => result.data,
});

QueryTools(getHotToursQuery).errors.handleAll();
QueryTools(getHeaderMenuQuery).errors.handleAll();
