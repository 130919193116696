export const styles = {
  downloadFilesLink: {
    gap: "8px",
    display: "flex",

    link: {
      paddingBottom: "16px",
    },
  },
};
