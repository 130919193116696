import { FC, ReactNode } from "react";

import { Popover, PopoverProps, PopoverOrigin } from "@mui/material";

type TProps = PopoverProps & {
  trigger: ReactNode;
};

const DEFAULT_CLASSES = { paper: "menu-popover-custom" };

const DEFAULT_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 54,
  horizontal: "center",
};

const DEFAULT_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};

export const PopoverWithTrigger: FC<TProps> = ({
  classes,
  trigger,
  anchorOrigin,
  transformOrigin,
  ...props
}) => {
  return (
    <>
      {trigger}
      <Popover
        classes={classes ?? DEFAULT_CLASSES}
        anchorOrigin={anchorOrigin ?? DEFAULT_ANCHOR_ORIGIN}
        transformOrigin={transformOrigin ?? DEFAULT_TRANSFORM_ORIGIN}
        {...props}
      />
    </>
  );
};

PopoverWithTrigger.displayName = "PopoverWithTrigger";
