export const styles = {
  dialog: {
    padding: "24px",
  },

  dialogTitle: {
    padding: "24px",
  },

  paperSx: {
    borderRadius: "16px 16px 0 0",
  },

  childrenWrapperSx: {
    height: "100%",
    overflow: "auto",
    padding: "0 16px 16px",
  },
};
