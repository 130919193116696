import { FC, memo } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  SxProps,
  IconButton,
  SwipeableDrawer as MuiSwipeableDrawer,
} from "@mui/material";
import * as CSS from "csstype";

import { KazTourLogoLink } from "../KazTourLogoLink";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";

type TProps = {
  isOpen: boolean;
  paperSx?: SxProps;
  onOpen: () => void;
  footerSx?: SxProps;
  headerSx?: SxProps;
  onClose: () => void;
  sx?: CSS.Properties;
  paperHeight?: number;
  backButton?: boolean;
  virtualized?: boolean;
  closeButton?: boolean;
  keepMounted?: boolean;
  hideBackdrop?: boolean;
  title: string | boolean;
  childrenWrapperSx?: SxProps;
  onBackButtonClick?: () => void;
  Header?: string | JSX.Element | JSX.Element[];
  Footer?: string | JSX.Element | JSX.Element[];
  children: string | JSX.Element | JSX.Element[];
};

export const SwipeableDrawer: FC<TProps> = memo(
  ({
    title,
    isOpen,
    onOpen,
    Footer,
    Header,
    onClose,
    sx = {},
    children,
    paperSx = {},
    footerSx = {},
    headerSx = {},
    onBackButtonClick,
    closeButton = true,
    backButton = false,
    keepMounted = true,
    hideBackdrop = true,
    childrenWrapperSx = {},
  }: TProps) => {
    return (
      <MuiSwipeableDrawer
        sx={{ sx }}
        open={isOpen}
        anchor="bottom"
        onOpen={onOpen}
        onClose={onClose}
        transitionDuration={200}
        disableSwipeToOpen={false}
        hideBackdrop={hideBackdrop}
        ModalProps={{ keepMounted }}
        disableBackdropTransition={true}
        PaperProps={{ sx: { ...styles.defaultPaperProps, ...paperSx } }}
      >
        <Condition match={!Header}>
          <Box sx={styles.header}>
            <Box sx={styles.titleBox}>
              <Condition match={backButton}>
                <ArrowBackIcon fontSize="medium" onClick={onBackButtonClick} />
              </Condition>
              <Condition match={typeof title === "boolean" && title}>
                <Box sx={styles.logoWrapper}>
                  <KazTourLogoLink isHomePage color="blue" />
                </Box>
              </Condition>
              <Condition match={typeof title === "string"}>{title}</Condition>
            </Box>
            {closeButton && (
              <IconButton
                size="small"
                aria-label="open"
                onClick={onClose}
                sx={styles.arrowBack}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Condition>
        <Condition match={!!Header}>
          <Box sx={{ ...styles.header, ...headerSx }}>{Header}</Box>
        </Condition>

        <Box
          sx={{
            ...styles.defaultChildrenWrapper,
            ...childrenWrapperSx,
          }}
        >
          {children}
        </Box>

        <Condition match={!!Footer}>
          <Box sx={{ ...styles.footer, ...footerSx } as SxProps}>{Footer}</Box>
        </Condition>
      </MuiSwipeableDrawer>
    );
  },
);
