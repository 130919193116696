export const styles = {
  bagde: {
    alignSelf: "start",
    width: "fit-content",
    color: "accent.brand.primary",
    backgroundColor: "accent.brand.lightest",
  },

  hotelPreview: {
    display: "grid",
    columnGap: "8px",
    marginBottom: "16px",
    position: "relative",
    gridTemplateColumns: "min-content auto",

    name: {
      rowGap: "8px",
      display: "flex",
      maxWidth: "100%",
      overflow: "hidden",
      flexDirection: "column",

      hotelName: {
        cursor: "pointer",
        transition: "0.2s",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",

        "&:hover": {
          opacity: "0.7",
          color: "accent.brand.primary",
        },
      },
    },

    image: {
      width: "70px",
      height: "70px",
      overflow: "hidden",
      borderRadius: "6px",
      position: "relative",
      placeContent: "center",
      backgroundColor: "cloud",

      "@media screen and (max-width: 980px)": {
        width: "78px",
        height: "81px",
      },

      "& svg": {
        width: "25px",
        height: "25px",
        fill: "transparent",
        color: "grey.extralight",
      },
    },

    reviews: {
      gap: "8px",
      display: "flex",
      marginTop: "16px",
      gridColumn: "1 / 3",
      alignItems: "center",

      ".MuiTypography-root": {
        marginTop: "0px",
      },

      rating: {
        color: "white",
        display: "flex",
        fontWeight: 500,
        fontSize: "16px",
        padding: "4px 5px",
        lineHeight: "18px",
        borderRadius: "6px",
        alignItems: "flex-end",
        justifyContent: "center",
        "& span": {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
          color: "accent.green.rating",
        },
      },
    },
  },
};
