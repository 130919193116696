import { mainTheme } from "../styles";

export const styles = {
  title: {
    fontSize: 26,
    lineHeight: "30px",
  },

  subtitle: {
    fontSize: 18,
    marginTop: "8px",
    lineHeight: "24px",
  },

  actionsWrapper: {
    gap: "16px",
    display: "flex",
    marginTop: "24px",

    [mainTheme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },

  tourNotFound: {
    padding: "24px",
    display: "flex",
    border: "1px solid",
    borderColor: "cloud",
    borderRadius: "16px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "dominant.white",
  },
};
