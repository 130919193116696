export const styles = {
  mobileBaseContainer: {
    position: "static",
  },

  flexContainer: {
    display: "flex",
    alignItems: "center",
  },

  mobileButtonWrapper: {
    width: "100%",
    marginTop: "24px",
    position: "relative",
  },

  commonText: {
    fontSize: "16px",
    lineHeight: "18px",
    color: "content.secondary",
  },

  wrapperAnimation: {
    hidden: {
      opacity: 0,
      visibility: "hidden",
    },

    visible: {
      opacity: 100,
      visibility: "visible",
    },
  },

  mobileContainer: {
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    width: "100%",
    display: "flex",
    position: "fixed",
    borderTop: "1px solid",
    flexDirection: "column",
    justifyContent: "center",
    padding: "16px 16px 32px",
    borderColor: "grey.white",
    backgroundColor: "dominant.white",
  },
};
