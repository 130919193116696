import { mainTheme } from "shared/ui/styles";

export const styles = {
  icon: {
    fontSize: "70px",
  },

  errorMessage: {
    fontWeight: 500,
    margin: "24px 0",
    fontSize: "18px",
    lineHeight: "20px",
  },

  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    backgroundColor: "rgba(248, 248, 248, 0.6)",
  },

  errorWrapper: {
    padding: "48px",
    display: "flex",
    maxWidth: "350px",
    textAlign: "center",
    borderRadius: "8px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "dominant.white",

    [mainTheme.breakpoints.down("md")]: {
      marginBottom: "30%",
    },
  },
};
