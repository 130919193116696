import { useRouter, usePathname } from "next/navigation";

import { useUnit } from "effector-react";

import {
  getCityNameFromHTUrl,
  getCountryNameFromHTUrl,
} from "@/shared/lib/helpers";
import { $$hotTourModels } from "@/shared/model/store/hot-tour";

export const useHandleGetUrl = () => {
  const router = useRouter();
  const pathname = usePathname();
  const setConstructorPageUrl = useUnit($$hotTourModels.setConstructorPageUrl);

  return ({
    id,
    url,
    hasCountry,
  }: {
    id?: string;
    url: string;
    hasCountry?: boolean;
  }) => {
    const countryName = getCountryNameFromHTUrl(pathname);
    const newUrl =
      id === "all"
        ? hasCountry
          ? `hot-tours/${countryName}`
          : "hot-tours/all"
        : `${hasCountry ? `hot-tours/${getCityNameFromHTUrl(url)}/${countryName}` : `hot-tours/${getCityNameFromHTUrl(url)}`}`;
    setConstructorPageUrl(newUrl);
    router.push(`/s/${newUrl}`);
  };
};
