import { mainTheme } from "shared/ui/styles";

export const styles = {
  button: {
    width: "100%",
    padding: "8px",
    display: "flex",
    columnGap: "4px",
    transition: "0.2s",
    alignItems: "center",
    color: "accent.red.error",
    justifyContent: "flex-start",

    "&:hover": {
      opacity: "0.7",
    },

    [mainTheme.breakpoints.down("md")]: {
      height: "unset",
      fontSize: "18px",
      lineHeight: "22px",
      padding: "20px 8px",
      borderTop: "1px solid",
      borderColor: "accent.brand.lightest",
    },
  },
};
