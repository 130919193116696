import { useSearchParams } from "next/navigation";
import { FC } from "react";

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { ContentCopy, LinkOutlined } from "@mui/icons-material";
import {
  Input,
  Button,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { isClient } from "@/shared/lib/helpers/isClient";
import { useCustomTheme } from "@/shared/lib/hooks";
import { openNotification } from "@/shared/model/store/notifications";
import { styles } from "@/shared/ui/ShareTourLinkPopup/styles";

type TShareTourLinkPopupProps = {
  open?: boolean;
  onClose?: () => void;
  anchorEl?: null | HTMLElement;
  socialMediaLinks?: {
    url: string;
    label: string;
    icon: ReactJSXElement;
  }[];
};

const INSTAGRAM_URL = "https://www.instagram.com";
const INSTAGRAM_LABEL = "Instagram";

export const ShareTourLinkPopup: FC<TShareTourLinkPopupProps> = ({
  anchorEl,
  open = false,
  onClose = () => null,
  socialMediaLinks = [],
}) => {
  const t = useTranslations();
  const query = useSearchParams();
  const theme = useCustomTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fullPath = isClient && window.location.href;
  const path = String(fullPath).split("?");

  const setNotification = useUnit(openNotification);

  const queryParams = new URLSearchParams(query as never).toString();
  const encodedParams = encodeURIComponent("?" + queryParams);

  const href = path[0] + encodedParams;

  const copyToClipBoard = (url: false | string) => {
    if (typeof url === "string") {
      void navigator.clipboard
        .writeText(url)
        .then(() => {
          setNotification({
            severity: "success",
            message: "LINK_COPIED",
          });
        })
        .catch(() => {
          setNotification({
            severity: "error",
            message: "FAILED_TO_COPY_LINK",
          });
        });
    }
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      sx={styles.popover}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {isMobile ? (
        <Box sx={styles.mobileWrapper}>
          <Box sx={styles.inputWrapper}>
            <Input
              value={fullPath}
              disableUnderline
              sx={styles.linkInput}
              endAdornment={
                <ContentCopy
                  sx={styles.copyIcon}
                  onClick={() => copyToClipBoard(fullPath)}
                />
              }
            />
          </Box>
          <Box sx={styles.socialMediaWrapper}>
            {socialMediaLinks.map((media, index) => (
              <Box key={index} sx={styles.iconWrapper}>
                {media.label === INSTAGRAM_LABEL ? (
                  <a target="_blank" rel="noreferrer" href={INSTAGRAM_URL}>
                    {media.icon}
                  </a>
                ) : (
                  <a target="_blank" rel="noreferrer" href={media.url + href}>
                    {media.icon}
                  </a>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={styles.wrapper}>
          <Box sx={styles.inputWrapper}>
            <Input
              value={fullPath}
              disableUnderline
              sx={styles.linkInput}
              startAdornment={<LinkOutlined sx={styles.linkIcon} />}
            />
            <Button
              variant="outlined"
              sx={styles.copyButton}
              onClick={() => copyToClipBoard(fullPath)}
            >
              {t("COPY_LINK")}
            </Button>
          </Box>
          <Box sx={styles.socialMediaWrapper}>
            {socialMediaLinks.map((media, index) => (
              <Box key={index} sx={styles.iconWrapper}>
                {media.label === INSTAGRAM_LABEL ? (
                  <a target="_blank" rel="noreferrer" href={INSTAGRAM_URL}>
                    {media.icon}
                    <Typography sx={styles.mediaLabel}>
                      {media.label}
                    </Typography>
                  </a>
                ) : (
                  <a target="_blank" rel="noreferrer" href={media.url + href}>
                    {media.icon}
                    <Typography sx={styles.mediaLabel}>
                      {media.label}
                    </Typography>
                  </a>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Popover>
  );
};
