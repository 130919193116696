export const styles = {
  wrapper: {
    width: "100%",
  },

  stepPaymentContent: {
    paddingRight: 0,
  },

  icon: {
    fontSize: "28px",
    color: "accent.orange.attention",
  },

  stepLabel: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
