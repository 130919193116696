import { FC } from "react";

import { Box } from "@mui/material";
import * as CSS from "csstype";

import { RoundedBorderContainer } from "../RoundedBorderContainer";
import { styles } from "./styles";

import InfoIcon from "@/public/icons/common/info.svg";

type TProps = {
  sx?: CSS.Properties;
  children?: JSX.Element | JSX.Element[];
};

export const InformationBox: FC<TProps> = ({ sx = {}, children }) => {
  return (
    <RoundedBorderContainer sx={{ ...styles.tooltipWrapper, ...sx }}>
      <Box sx={styles.iconWrapper}>
        <InfoIcon />
      </Box>
      <Box>{children}</Box>
    </RoundedBorderContainer>
  );
};
