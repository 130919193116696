import { FC } from "react";

import { Box, Divider } from "@mui/material";
import { useUnit } from "effector-react";

import { PaymentDetails } from "../PaymentDetails";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { getOrderPaymentsDetailsQuery } from "@/shared/model/api/orders";

export const PaymentModalContent: FC = () => {
  const { data: paymentsDetails } = useUnit(getOrderPaymentsDetailsQuery);

  return (
    <Box sx={styles.wrapper}>
      {paymentsDetails?.map((paymentDetails, index) => (
        <>
          <Condition match={Boolean(index)}>
            <Divider sx={styles.divider} />
          </Condition>
          <Box key={index} sx={styles.contentWrapper}>
            <PaymentDetails
              paymentIndex={index}
              paymentDetails={paymentDetails}
            />
          </Box>
        </>
      ))}
    </Box>
  );
};
