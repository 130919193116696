export const styles = {
  desktop: {
    title: {
      fontSize: "16px",
      lineHeight: "24px",
      marginLeft: "12px",
    },

    value: {
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "right",
      color: "content.primary",
    },

    textWrapper: {
      display: "flex",
      alignItems: "center",

      "& svg": {
        fill: "transparent !important",
      },
    },

    wrapper: {
      display: "flex",
      alignItems: "center",
      fill: "none !omportant",
      color: "content.grey-400",
      justifyContent: "space-between",
    },
  },

  mobile: {
    value: {
      fontSize: "16px",
      lineHeight: "18px",
      color: "content.primary",
    },

    title: {
      fontSize: "12px",
      lineHeight: "16px",
      textTransform: "uppercase",
    },

    textWrapper: {
      marginLeft: "14px",

      "& svg": {
        fill: "transparent !important",
      },
    },

    wrapper: {
      display: "flex",
      border: "1px solid",
      padding: "12px 16px",
      alignItems: "center",
      borderRadius: "12px",
      backgroundColor: "white",
      color: "content.grey-400",
      borderColor: "accent.brand.lightest",

      "& svg": {
        fill: "transparent !important",
      },
    },
  },
};
