import { FC } from "react";

import { SxProps } from "@mui/joy/styles/types";
import { MuiOtpInputProps } from "mui-one-time-password-input";

import { MuiOtpInputStyled } from "./styles";

import { NUMBER_REGEX } from "@/shared/lib/constants";

type TProps = {
  sx?: SxProps;
  disabled?: boolean;
  type?: "tel" | "text";
} & MuiOtpInputProps;

export const OtpInput: FC<TProps> = ({
  disabled,
  length = 4,
  type = "tel",
  ...props
}) => {
  return (
    <MuiOtpInputStyled
      {...props}
      length={length}
      TextFieldsProps={{ type, disabled, placeholder: "_" }}
      validateChar={(value) => NUMBER_REGEX.test(value) || value === ""}
    />
  );
};
