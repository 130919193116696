/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { FC } from "react";

import { Box, Stack, Rating, Typography } from "@mui/material";
import isNumber from "lodash/isNumber";
import toNumber from "lodash/toNumber";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { THotelData } from "@/shared/model/types/common";
import { Link, Badge, Image, HotelRating } from "@/shared/ui";

export const TourHeadingInfo: FC<THotelData> = ({
  slug,
  name,
  rating,
  country,
  category,
  locations,
  images = [],
  cover = null,
  reviews_count,
}) => {
  const t = useTranslations();

  const numericValue = toNumber(category?.value);

  const imageSrc = images?.length !== 0 && !cover ? images[0]?.src : cover;

  return (
    <Box sx={styles.hotelPreview}>
      <Image
        alt="preview"
        src={imageSrc ?? ""}
        imageWrapperSx={styles.hotelPreview.image}
      />

      <Box sx={styles.hotelPreview.name}>
        <Stack spacing={0.5}>
          {category?.value && (
            <>
              {isNumber(numericValue) && !isNaN(numericValue) ? (
                <Rating
                  readOnly
                  size="small"
                  max={numericValue}
                  value={numericValue}
                  sx={{ verticalAlign: "bottom" }}
                />
              ) : (
                <Badge size="small" sx={styles.bagde}>
                  {category.value}
                </Badge>
              )}
            </>
          )}
        </Stack>
        <Link href={{ pathname: `/oteli/${slug}` }}>
          <Typography
            fontSize={16}
            width={"100%"}
            fontWeight={500}
            overflow={"hidden"}
            color={"content.primary"}
            textOverflow={"ellipsis"}
            sx={styles.hotelPreview.name.hotelName}
          >
            {name}
          </Typography>
        </Link>

        <Typography fontSize={14} color={"content.grey-400"}>
          {country?.name}
          {locations?.length ? `, ${locations?.[0].name}` : ""}
        </Typography>
      </Box>

      <Condition match={Boolean(rating || reviews_count)}>
        <Box sx={styles.hotelPreview.reviews}>
          <Condition match={Boolean(rating)}>
            <HotelRating rating={rating} singleHotelView />
          </Condition>

          <Condition match={Boolean(reviews_count)}>
            <Typography fontSize={14} color="content.secondary">
              {t("REVIEWS_DECLINATIONS", { count: reviews_count })}
            </Typography>
          </Condition>
        </Box>
      </Condition>
    </Box>
  );
};
