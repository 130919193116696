import { COLORS, mainTheme } from "shared/ui/styles";

export const styles = {
  paragraph: {
    fontSize: "18px",
    margin: "24px 0",
    textAlign: "center",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "14px",
      margin: "12px 0",
    },
  },

  subtitle: {
    fontSize: "26px",
    lineHeight: "30px",
    textAlign: "center",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "20px",
    },
  },

  title: {
    margin: "8px 0",
    fontWeight: 500,
    fontSize: "64px",
    lineHeight: "64px",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "32px",
      lineHeight: "34px",
    },
  },

  container: {
    margin: "auto",
    padding: "48px",
    display: "flex",
    maxWidth: "866px",
    marginTop: "120px",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.WHITE_BLUE,

    [mainTheme.breakpoints.down("md")]: {
      marginTop: "90px",
    },

    [mainTheme.breakpoints.down("sm")]: {
      marginTop: "24px",
    },
  },
};
