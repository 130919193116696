import { FC, memo, useRef, useMemo, useCallback } from "react";

import { useMediaQuery } from "@mui/material";
import { Box, ClickAwayListener } from "@mui/material";
import * as CSS from "csstype";

import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

type TProps = {
  open: boolean;
  order?: number;
  wrapperId?: string;
  isInModal?: boolean;
  fullInputWidth?: boolean;
  closePopover: () => void;
  popoverStyles?: CSS.Properties;
  popoverWidth?: string | number;
  trigger: string | JSX.Element | JSX.Element[];
  children: string | JSX.Element | JSX.Element[];
  boxPosition?: "fixed" | "static" | "absolute" | "relative";
};

const POPOVER_MARGIN = 12;

// eslint-disable-next-line react/display-name
export const Popover: FC<TProps> = memo(
  ({
    open,
    trigger,
    children,
    wrapperId,
    popoverWidth,
    closePopover,
    isInModal = false,
    popoverStyles = {},
    fullInputWidth = false,
    boxPosition = "relative",
  }: TProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const boundingClientRect = ref.current?.getBoundingClientRect();

    const theme = useCustomTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down("smd"));

    const handleClickAway = useCallback(() => {
      if (!open) return;

      closePopover();
    }, [closePopover, open]);

    const wrapperStyles = useMemo(
      () =>
        styles.wrapperStyles({
          fullInputWidth,
          position: boxPosition,
        }),
      [boxPosition, fullInputWidth],
    );

    const stylesKey = isInModal ? "modal" : "default";

    const position = useMemo(() => {
      if (!boundingClientRect || !isInModal || !ref.current) {
        return {};
      }

      if (isPhone) {
        return {
          top: ref.current.offsetHeight,
        };
      }

      return boundingClientRect.top <
        window.innerHeight - boundingClientRect.bottom
        ? {
            position: "fixed",
            left: boundingClientRect.left,
            right: boundingClientRect.right,
            top: boundingClientRect.bottom + POPOVER_MARGIN,
          }
        : {
            position: "fixed",
            left: boundingClientRect.left,
            right: boundingClientRect.right,
            bottom:
              window.innerHeight -
              (boundingClientRect.bottom -
                ref.current.offsetHeight -
                POPOVER_MARGIN),
          };
    }, [boundingClientRect, isInModal, isPhone]);

    const width = useMemo(() => {
      if (isPhone) return "100%";

      if (popoverWidth) return popoverWidth;

      if (ref.current) return ref.current.offsetWidth;

      return "auto";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPhone, popoverWidth, open]);

    const childrenStyles = useMemo(
      () => ({
        ...styles[stylesKey].common,
        ...(open && styles[stylesKey].isOpen),
        ...position,
        width,
        ...popoverStyles,
      }),
      [stylesKey, open, popoverStyles, position, width],
    );

    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box id={wrapperId} sx={wrapperStyles}>
          <Box ref={ref}>{trigger}</Box>
          <Box sx={childrenStyles as CSS.Properties}>{children}</Box>
        </Box>
      </ClickAwayListener>
    );
  },
);
