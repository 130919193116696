"use client";
import { FC, useState, useEffect } from "react";

import { Box, SxProps, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

type TCommonTimeComponent = {
  text: string;
  boxSx?: SxProps;
};

const CommonTimeComponent: FC<TCommonTimeComponent> = ({
  text,
  boxSx = styles.box,
}) => {
  return (
    <Box sx={boxSx}>
      <Typography sx={styles.p}>{text}</Typography>
    </Box>
  );
};

export const CustomCountdown: FC = () => {
  const t = useTranslations();
  const [minutes, setMinutes] = useState<string>("10");
  const [seconds, setSeconds] = useState<string>("00");

  const lettersOfMin = minutes.toString().split("");
  const lettersOfSec = seconds.toString().split("");

  useEffect(() => {
    let timeMinute = 600;
    // eslint-disable-next-line prefer-const
    let timerId: ReturnType<typeof setInterval>;

    const countdown = () => {
      const _seconds = timeMinute % 60;
      const _minutes = (timeMinute / 60) % 60;

      if (timeMinute <= 0) {
        clearTimeout(timerId);
      } else {
        setMinutes(
          _minutes === 10 ? String(_minutes) : `0${Math.trunc(_minutes)}`,
        );
        setSeconds(_seconds < 10 ? `0${_seconds}` : String(_seconds));
      }
      --timeMinute;
    };
    timerId = setInterval(countdown, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <Box sx={styles.informationBanner}>
      <Typography variant="h5" fontSize={18} fontWeight={400}>
        {t("TOUR_PRICE_MAY_CHANGE")}
      </Typography>

      <Box sx={styles.timeContainer}>
        {lettersOfMin.map((text, index) => (
          <CommonTimeComponent key={index} text={text} />
        ))}
        <CommonTimeComponent
          text={":"}
          boxSx={[styles.box, styles.box.forColon]}
        />
        {lettersOfSec.map((text, index) => (
          <CommonTimeComponent key={index} text={text} />
        ))}
      </Box>
    </Box>
  );
};
