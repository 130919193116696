import type { FC, MouseEventHandler } from "react";

import { Box, Typography } from "@mui/material";
import { COLORS } from "shared/ui/styles";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";

import FileDownloadIcon from "@/public/icons/common/download.svg";

type TDownloadFileLink = {
  size: string;
  label: string;
  format: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};

export const DownloadFileLink: FC<TDownloadFileLink> = ({
  onClick,
  size = "",
  label = "",
  format = "",
}) => {
  return (
    <Box onClick={onClick} style={{ cursor: "pointer" }}>
      <Box sx={styles.downloadFilesLink}>
        <Typography
          fontSize={16}
          color={COLORS.BLUE}
          sx={styles.downloadFilesLink.link}
        >
          {label} ·
        </Typography>
        <Typography
          fontSize={16}
          color={COLORS.BLUE}
          sx={styles.downloadFilesLink.link}
        >
          {format}
          <Condition match={Boolean(size)}>, </Condition>
        </Typography>

        <Condition match={Boolean(size)}>
          <Typography
            fontSize={16}
            color={COLORS.BLUE}
            sx={styles.downloadFilesLink.link}
          >
            {size}
          </Typography>
        </Condition>

        <Box>
          <FileDownloadIcon />
        </Box>
      </Box>
    </Box>
  );
};
