import { FC } from "react";

import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { useTranslations } from "next-intl";

import { THeaderDate } from "../model/types";
import { styles } from "./styles";

const DATE_FORMAT = "D MMM YYYY";
const WEEKDAY = "dd";

export const HeaderDate: FC<THeaderDate> = ({ date = "", title = "THERE" }) => {
  const t = useTranslations();

  return (
    <Box sx={styles.dates.date}>
      <Typography sx={styles.dates.title}>{t(title)}</Typography>
      <Typography sx={styles.dates.content}>
        {dayjs(date).format(DATE_FORMAT)},{" "}
        <Typography component={"span"} sx={styles.dates.weekDay}>
          {dayjs(date).format(WEEKDAY)}
        </Typography>
      </Typography>
    </Box>
  );
};
