"use client";
import { FC, useCallback } from "react";

import { useUnit } from "effector-react";

import { styles } from "./styles";

import { getOrderCategoriesQuery } from "@/shared/model/api/profile";
import {
  $currentFilter,
  changeCurrentFilter,
} from "@/shared/model/store/profile";
import { EOrderCategory } from "@/shared/model/types/common";
import { CustomTab, CustomTabs, CustomChip } from "@/shared/ui";

type TProps = {
  isPhone: boolean;
  isLoading: boolean;
};

export const OrdersFilters: FC<TProps> = ({ isLoading, isPhone = false }) => {
  const [currentFilter, changeFilter] = useUnit([
    $currentFilter,
    changeCurrentFilter,
  ]);

  const { data: orderCategories } = useUnit(getOrderCategoriesQuery);

  const handleFilterChange = useCallback(
    (category: EOrderCategory) => {
      changeFilter(category);
      window.history.pushState(
        {},
        "",
        window.location.pathname + "?" + `filter=${category}`,
      );
    },
    [changeFilter],
  );

  if (isPhone) {
    return (
      <CustomTabs
        value={currentFilter}
        onChange={(_, v: EOrderCategory) => handleFilterChange(v)}
      >
        {orderCategories?.map(({ id, name, code }) => (
          <CustomTab
            key={id}
            label={name}
            value={code}
            disabled={isLoading}
            id={`my_orders_${code}_btn_test`}
          />
        ))}
      </CustomTabs>
    );
  }

  return (
    <>
      {orderCategories?.map(({ id, name, code }) => (
        <CustomChip
          key={id}
          label={name}
          sx={styles.chip}
          variant={"outlined"}
          disabled={isLoading}
          id={`my_orders_${code}_btn_test`}
          onClick={() => handleFilterChange(code)}
          color={currentFilter === code ? "primary" : "default"}
        />
      ))}
    </>
  );
};
