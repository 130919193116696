import { FC, useMemo, useState } from "react";

import { Box, Fade, Popper, SxProps } from "@mui/material";
import type { PopperProps } from "@mui/material/Popper";

import { styles } from "./styles";

export const CustomPopper: FC<PopperProps> = ({
  sx,
  children,
  modifiers = [],
  ...otherProps
}) => {
  const [arrowRef, setArrowRef] = useState();
  const popperModifiers = useMemo(() => {
    return [
      ...modifiers,
      {
        name: "arrow",
        enabled: true,
        options: {
          element: arrowRef,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ];
  }, [modifiers, arrowRef]);
  return (
    <Popper
      sx={styles.tooltipWrapper}
      modifiers={popperModifiers}
      {...otherProps}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Box sx={{ ...styles.tooltip, ...sx } as SxProps}>
            <>
              {children}
              <Box
                ref={setArrowRef}
                sx={styles.tooltipArrow}
                className="tooltip-arrow"
              ></Box>
            </>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};
