import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useUnit } from "effector-react";

import { styles } from "./styles";

import { $contactPhones } from "@/entities/footer/model";

import { sendDataLayerEvent } from "@/shared/model/analytics";

import PhoneIcon from "@/public/icons/common/phone_green.svg";

type TProps = {
  isPhone?: boolean;
  isDefaultColor?: boolean;
};

export const HeaderPhoneNumber: FC<TProps> = ({
  isPhone = false,
  isDefaultColor = false,
}) => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);
  const phoneData = useUnit($contactPhones)[0];

  return phoneData?.value ? (
    <Typography sx={styles.linkWrapper}>
      <Box
        component="a"
        sx={styles.phone}
        href={`tel:${phoneData.value}`}
        onClick={() =>
          sendDataLayerEventFn({
            event: "call",
          })
        }
      >
        <PhoneIcon />

        {!isPhone && (
          <Typography component="span" sx={styles.linkText(isDefaultColor)}>
            {phoneData.value}
          </Typography>
        )}
      </Box>
    </Typography>
  ) : null;
};
