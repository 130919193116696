import { FC } from "react";

import { ExpandMore } from "@mui/icons-material";
import { Box, Button, Typography, ButtonProps } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

type TProps = {
  isOpen: boolean;
  isIconHidden?: boolean;
};

export const CollapseButton: FC<TProps & ButtonProps> = ({
  isOpen,
  children,
  isIconHidden = false,
  ...props
}) => {
  const t = useTranslations();
  const arrowStyles = isOpen ? styles.downIcon.rotateIcon : styles.downIcon;

  return (
    <Button {...props}>
      {children ?? (
        <Typography sx={styles.collapseText}>
          {t(!isOpen ? "SHOW_MORE" : "HIDE")}
        </Typography>
      )}
      {!isIconHidden && (
        <Box sx={{ ...styles.flexContainer, ...arrowStyles }}>
          <ExpandMore fontSize="small" />
        </Box>
      )}
    </Button>
  );
};
