import { mainTheme } from "shared/ui/styles";

export const styles = {
  container: {
    backgroundColor: "accent.yellow.loan",
  },

  bar: {
    width: "100%",
    padding: "16px",
    display: "flex",
    margin: "0 auto",
    borderRadius: "0",
    maxWidth: "1344px",
    alignItems: "center",
    justifyContent: "center",
  },

  text: {
    fontSize: "20px",
    fontWeight: "500",
    textAlign: "center",
    width: "calc(100% - 48px)",

    [mainTheme.breakpoints.down("sm")]: {
      fontSize: "16px",
      textAlign: "left",
      fontWeight: "400",
    },
  },
};
