import { mainTheme } from "shared/ui/styles";

export const styles = {
  buttonWrapper: {
    width: "100%",
    display: "flex",
    columnGap: "24px",
    alignItems: "center",
    padding: "0 24px 24px",
    justifyContent: "space-between",

    [mainTheme.breakpoints.down("md")]: {
      rowGap: "16px",
      justifyContent: "center",
      padding: "16px 16px 36px",
      flexDirection: "column-reverse",
    },
  },

  confirmationContent: {
    text: {
      fontWeight: 500,
      fontSize: "20px",
      marginTop: "24px",
      lineHeight: "24px",
      textAlign: "center",
    },

    wrapper: {
      width: "374px",
      display: "flex",
      alignItems: "center",
      padding: "16px 0 12px",
      flexDirection: "column",
      justifyContent: "center",

      [mainTheme.breakpoints.down("md")]: {
        width: "100%",
        padding: "16px 0 0",
      },
    },
  },
};
