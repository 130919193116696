import { FC } from "react";

import { Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Link } from "@/shared/ui";

type TProps = {
  sx?: Record<string, unknown>;
};

export const Agreement: FC<TProps> = ({ sx = {} }) => {
  const t = useTranslations();

  return (
    <Typography component="p" sx={{ ...styles.wrapper, ...sx }}>
      {t("AGREE_WITH_PRIVACY_POLICY_AND")}{" "}
      <Link target="_blank" href="/politika-konfidenczialnosti">
        <Typography component="span" sx={styles.link}>
          {t("PRIVACY_POLICY_AND")}
        </Typography>
      </Link>{" "}
      {t("AND_WITH")}{" "}
      <Link target="_blank" href="/polzovatelskoe-soglashenie">
        <Typography component="span" sx={styles.link}>
          {t("TERMS_AND_CONDITIONS_WITH")}{" "}
        </Typography>
      </Link>
      {t("AGREE_WITH_PRIVACY_POLICY_AND_END")}
    </Typography>
  );
};
