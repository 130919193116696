/* eslint-disable perfectionist/sort-objects */
import dayjs from "dayjs";
import { sample, createEvent, createEffect } from "effector";
import Cookies from "js-cookie";

import { CLIENT_DATE_FORMAT } from "../../lib/constants";
import { isClient } from "../../lib/helpers/isClient";
import { TokenService } from "../api/token";

import { DATA_LAYER_EVENT_NAMES } from "@/shared/lib/constants/analytics/data-layer";

export type TDataLayerEvent = keyof typeof DATA_LAYER_EVENT_NAMES;

const sendEventToDataLayerFx = createEffect(
  (event: Record<string, unknown>) => {
    if (isClient && window.dataLayer) {
      window.dataLayer.push(event);
    }
  },
);

export const sendDataLayerEvent = createEvent<{
  event: TDataLayerEvent;
  data?: Record<string, unknown>;
}>();

sample({
  clock: sendDataLayerEvent,
  fn: ({ data, event }) => {
    return {
      event,
      // TODO: https://learn.42px.org/front-arch/statement/stages.html
      isLoggedIn: TokenService.getCookiesAccessToken(),
      // TODO: locales issue
      localization: Cookies.get("NEXT_LOCALE"),
      clickUrl: window.location.href,
      dateTime: dayjs().format(CLIENT_DATE_FORMAT),
      name: DATA_LAYER_EVENT_NAMES[event] ?? event,
      ...data,
    };
  },
  target: sendEventToDataLayerFx,
});
