import FacebookIconColored from "@/public/icons/socials/facebook_colored.svg";
import InstagramIconColored from "@/public/icons/socials/instagram_colored.svg";
import TelegramIconColored from "@/public/icons/socials/telegram_colored.svg";
import WhatsappIconColored from "@/public/icons/socials/whatsapp_colored.svg";
export const SOCIAL_MEDIA_LINKS = [
  {
    label: "Whatsapp",
    icon: <WhatsappIconColored />,
    url: "https://api.whatsapp.com/send?text=",
  },
  {
    label: "Telegram",
    icon: <TelegramIconColored />,
    url: "https://telegram.me/share/url?url=",
  },
  {
    label: "Instagram",
    icon: <InstagramIconColored />,
    url: "instagram://library?Caption=",
  },
  {
    label: "Facebook",
    icon: <FacebookIconColored />,
    url: "https://www.facebook.com/sharer/sharer.php?u=",
  },
];
