import { EViewMode } from "./map";

export const PRODUCTION_BASE_URL = "https://kaztour.kz";
export const ASTANAHUB_LINK = "https://astanahub.com/ru/";
export const CORPORATE_LINK = "https://corporate.kaztour.kz/";

export const PLACEHOLDER_IMAGE = "/img/common/placeHolder.svg";
export const MENU_PLACEHOLDER_IMAGE = "/img/common/MenuPlaceholder.png";
export const IMAGE_DEFAULT_QUALITY = 90;
export const LOCAL_IMAGES_START_PATHS = ["data:", "/"];
export const SUPPORTED_IMAGE_FORMATS = ["jpg", "jpeg", "png", "webp"];

export const PROTECTED_ROUTES = [
  "/profile/passengers",
  "/profile/my-orders",
  "/profile/favorites",
  "/profile/settings",
  "/profile/notifications",
];

// code for useGetBannersQuery:
export enum EBanners {
  Middle = "middle",
  MiddleKz = "middle-kz",
}

export const RU_DAYS_OF_WEEK = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

export const SHORT_MONTHS_NAME = [
  "Янв",
  "Фев",
  "Мар",
  "Апр",
  "Май",
  "Июн",
  "Июл",
  "Авг",
  "Сен",
  "Окт",
  "Ноя",
  "Дек",
].map((item, index) => ({
  id: index,
  label: item,
  value: index,
}));

export const DATE_FORMAT = "D MMM, HH:mm";
export const TIME_FORMAT = "HH:mm";

export const DEFAULT_AMOUNT_OF_NIGHTS = 4;
export const DEFAULT_AMOUNT_OF_ADULTS = 2;
export const DEFAULT_AMOUNT_OF_CHILDREN = 0;
export const DEFAULT_AMOUNT_OF_INFANTS = 0;

export const POPPER_TIMER = 5000;
export const HUNDRED_HOURS = 360000;

export const KZ_RU_PHONE_REGEX = /\+7\s7\d{2}\s\d{3}-\d{2}-\d{2}/;

export const PHONE_REGEX =
  "\\+[0-9]\\s[0-9]{3}\\s[0-9]{3}\\-[0-9]{2}\\-[0-9]{2}";

export const LATIN_ALPHABET_REGEX = /^[A-Za-z]*$/;

export const EMAIL_REGEX = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

export const NUMBER_REGEX = /^\d+$/;

export const containerProps = {
  main: 1312,
  mainLg: 1376,
  secondary: 866,
  secondarySm: 692,
  defaultPadding: 16,
};

export enum EClientSteps {
  SELECT = 1,
  DETAILS = 2,
  BOOKING = 3,
  PAYMENT = 4,
}

export const LAST_SEEN_KEY = "lastSeen";

export const REVALIDATE_TIME_IN_SECONDS = 60 * 60 * 24;

export const COUNTDOWN_TIME = 60 * 1000;
export const SMS_LIMIT_COUNTDOWN_TIME = 60 * 1000 * 30;

export const CREDIT_BANNER_KEY = "isCreditInfoHidden";

export const ACCOUNTABILITY_MESSAGE =
  "WE_ARE_NOT_RESPONSIBLE_FOR_THE_TOUR_OR_HOTEL_SELECTED_BY_THE_CLIENT_BY_MISTAKE";

export const APP_LINKS = {
  ios: {
    image: "/img/appDownloadingImages/AppleAppIcon.svg",
    link: "https://apps.apple.com/kz/app/kaztour-kz/id1662524807",
  },
  android: {
    image: "/img/appDownloadingImages/GoogleAppIcon.svg",
    link: "https://play.google.com/store/apps/details?id=kz.kaztour.app&pli=1",
  },
};

export const AUTH_ERROR_CODE = 401;

export const MAIN_PAGE_LINK = {
  href: "/",
  title: "HOME",
};

export const SUCCESS_COLORS = {
  color: "accent.green.success",
  backgroundColor: "accent.green.tagbg",
};

export const PENDING_COLORS = {
  color: "accent.brand.dark",
  backgroundColor: "primary.whiteblue",
};

export const ERROR_COLORS = {
  color: "error.main",
  backgroundColor: "accent.red.light",
};

export const ERROR_FINISH_COLORS = {
  color: "dominant.white",
  backgroundColor: "accent.red.error",
};

export const ALMATY_DEPARTURE_CODE = "ALA";
export const ALMATY_DEPARTURE_ID = "1b4f3cf0-8686-11e8-af9f-1134e98f2f31";

export const OFFICES_VIEW_TABS = [
  { label: "ON_MAP", value: EViewMode.Map },
  { label: "LIST_VIEW", value: EViewMode.List },
];

export const PAGE_KEY = "page";
export const PER_PAGE_KEY = "perPage";

export const TRANSACTION_HISTORY_LINK = "/profile/bonuses/history";
export const BONUSES_LINK = "/profile/bonuses";

export enum EAmenityTypes {
  meal = "meal",
  room = "room",
  flight = "flight",
  transfer = "transfer",
  duration = "duration",
  insurance = "insurance",
  passengers = "passengers",
}

export const SWIPER_MODAL_STYLES = {
  ".MuiBackdrop-root": {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
};
