/* eslint-disable perfectionist/sort-objects */
import { sample, createEvent, createStore } from "effector";
import { createGate } from "effector-react";

import { getBonusesAmountQuery } from "@/shared/model/api/bonuses";
import {
  getOrdersQuery,
  getCountersQuery,
  getLatestOrderQuery,
  getOrderCategoriesQuery,
} from "@/shared/model/api/profile";
import { EOrderCategory } from "@/shared/model/types/common";

export const ProfileGate = createGate<boolean | undefined>();
export const MyOrdersGate = createGate<undefined | EOrderCategory>();

export const $currentFilter = createStore<EOrderCategory>(
  EOrderCategory.Active,
);

export const changeCurrentFilter = createEvent<EOrderCategory>();

sample({
  clock: ProfileGate.open,
  fn: () => undefined,
  target: [getCountersQuery.refresh, getBonusesAmountQuery.refresh],
});

sample({
  clock: ProfileGate.open,
  filter: (isPhone) => Boolean(isPhone),
  fn: () => undefined,
  target: getLatestOrderQuery.refresh,
});

sample({
  clock: MyOrdersGate.open,
  target: getOrderCategoriesQuery.refresh,
});

sample({
  clock: MyOrdersGate.open,
  fn: (filter) =>
    Object.values(EOrderCategory).includes(filter as EOrderCategory)
      ? (filter as EOrderCategory)
      : EOrderCategory.Active,
  target: $currentFilter,
});

sample({
  clock: changeCurrentFilter,
  target: $currentFilter,
});

sample({
  clock: [$currentFilter, getOrderCategoriesQuery.finished.success],
  source: $currentFilter,
  target: getOrdersQuery.refresh,
});
