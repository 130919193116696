export const styles = {
  roundedBorderContainer: {
    padding: "16px",
    border: "1px solid",
    marginBottom: "16px",
    borderRadius: "16px",
    height: "max-content",
    width: "-webkit-fill-available",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },
};
