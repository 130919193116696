import dayjs from "dayjs";
import "dayjs/locale/kk";
import "dayjs/locale/ru";
import { useTranslations } from "next-intl";

type TProps = {
  nights: number;
  regions: number;
  endDate: string;
  startDate: string;
  nightsTotal: number;
  adults: number | string;
  children: number | string;
};

const DATE_FORMAT = "D MMM";
const FULL_MONTH = " MMMM";

export const useGetDeclinations = (
  {
    adults,
    nights,
    regions,
    endDate,
    children,
    startDate,
    nightsTotal,
  }: Partial<TProps>,
  isShortened?: boolean,
) => {
  const t = useTranslations();

  let adultsText = "";
  let childrenText = "";
  let regionsText = "";
  let datesText = "";
  let daysText = "";
  let nightsTotalText = "";

  if (startDate || endDate) {
    datesText =
      startDate === endDate
        ? dayjs(endDate).format(DATE_FORMAT)
        : `${dayjs(startDate).format(
            `D${dayjs(startDate).isSame(endDate, "month") ? "" : FULL_MONTH}`,
          )} - ${dayjs(endDate).format(DATE_FORMAT)}`;
  }

  if (nights) {
    daysText = t("NIGHT_DECLINATIONS", { count: nights });
  }

  if (nightsTotal && nightsTotal - Number(nights) > 0) {
    nightsTotalText = t("NIGHT_TOTAL_DECLINATIONS", {
      count: nights,
      total: nightsTotal - Number(nights),
    });
  }

  if (children) {
    childrenText = `, ${t(isShortened ? "CHILDREN_DECLINATIONS_SHORT" : "CHILDREN_DECLINATIONS", { count: children })}`;
  }

  if (adults) {
    adultsText = t(
      isShortened ? "ADULT_DECLINATIONS_SHORT" : "ADULT_DECLINATIONS",
      { count: adults },
    );
  }

  if (regions) {
    regionsText = `, ${t("REGIONS_DECLINATIONS", { count: regions })}`;
  }

  const passengersText = `${adultsText}${childrenText}`;

  return {
    daysText,
    datesText,
    adultsText,
    regionsText,
    childrenText,
    passengersText,
    nightsTotalText: nightsTotalText || daysText,
  };
};
