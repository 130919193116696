export const styles = {
  toTopButton: {
    zIndex: "10",
    left: "37px",
    bottom: "37px",
    position: "fixed",

    "@media screen and (max-width: 980px)": {
      left: "16px",
      bottom: "30px",
      border: "none !important",
    },
  },

  button: {
    width: "48px",
    height: "48px",
    display: "flex",
    borderRadius: "36px",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(4, 23, 44, 0.88)",
    boxShadow: "0px 2px 4px rgba(74, 85, 104, 0.12)",
  },
};
