import { FC, Ref, forwardRef, ReactElement } from "react";

import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DialogProps } from "@mui/material";
import {
  Box,
  Slide,
  Dialog,
  SxProps,
  Typography,
  IconButton,
  DialogTitle,
  useMediaQuery,
  DialogContent,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

export const CLOSE_ON_ESC = "escapeKeyDown";
export const CLOSE_ON_BACKDROP = "backdropClick";

export type TCloseReason = typeof CLOSE_ON_ESC | typeof CLOSE_ON_BACKDROP;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide ref={ref} direction="up" {...props} />;
});

type TProps = DialogProps & {
  step?: string;
  title?: string;
  sxTitle?: SxProps;
  footerSx?: SxProps;
  background?: string;
  backButton?: boolean;
  onClose?: () => void;
  keepMounted?: boolean;
  closeButtonId?: string;
  WrapperProps?: SxProps;
  secondTitleLabel?: string;
  closeReason?: TCloseReason;
  onBackButtonClick?: () => void;
  Footer?: null | JSX.Element | JSX.Element[];
};

export const DialogWrapper: FC<TProps> = ({
  sx = {},
  onClose,
  children,
  step = "",
  title = "",
  sxTitle = {},
  open = false,
  closeButtonId,
  footerSx = {},
  Footer = null,
  background = "",
  PaperProps = {},
  maxWidth = "sm",
  scroll = "paper",
  fullWidth = false,
  WrapperProps = {},
  BackdropProps = {},
  fullScreen = false,
  backButton = false,
  keepMounted = false,
  secondTitleLabel = "",
  onBackButtonClick = () => null,
  closeReason = CLOSE_ON_BACKDROP,
}) => {
  const theme = useCustomTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Dialog
      open={open}
      scroll={scroll}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={PaperProps}
      fullScreen={fullScreen}
      keepMounted={keepMounted}
      BackdropProps={BackdropProps}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      TransitionComponent={matches ? undefined : Transition}
      onClose={onClose ? (e) => onClose(e, closeReason) : undefined}
      sx={{
        ...styles.dialogWrapper,
        backgroundImage: `url(${background})`,
        ...WrapperProps,
      }}
    >
      <Condition match={!!title}>
        <DialogTitle
          sx={{
            ...styles.dialogTitle.wrapper,
            ...sxTitle,
          }}
        >
          <Box sx={styles.dialogTitle}>
            <Box sx={styles.dialogTitle.startBox}>
              <Condition match={backButton}>
                <ArrowBackIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                  onClick={onBackButtonClick}
                />
              </Condition>
              {title}
            </Box>
            <Box sx={styles.dialogTitle.endBox}>
              <Condition match={Boolean(step)}>
                <Typography sx={styles.dialogTitle.endBox.step}>
                  {step}
                </Typography>
              </Condition>
              {onClose ? (
                <IconButton
                  onClick={onClose}
                  id={closeButtonId}
                  aria-label="close"
                  sx={styles.dialogTitle.closeIcon}
                >
                  <Close />
                </IconButton>
              ) : null}
            </Box>
          </Box>
          <Condition match={!!secondTitleLabel}>
            <Typography sx={styles.dialogTitle.secondLabel}>
              {secondTitleLabel}
            </Typography>
          </Condition>
        </DialogTitle>
      </Condition>
      <DialogContent sx={(styles.dialogContent, { ...sx })}>
        {children}
      </DialogContent>

      <Box sx={(styles.dialogFooter, { ...footerSx })}>{Footer}</Box>
    </Dialog>
  );
};
