import { mainTheme } from "shared/ui/styles";

export const styles = {
  tab: {
    zIndex: 20,
    fontSize: "16px",
    lineHeight: "18px",
    borderRadius: "8px",
    paddingBottom: "8px",
    textTransform: "none",
    backgroundColor: "unset",
  },

  errorText: {
    maxWidth: "50%",
    fontSize: "20px",
    lineHeight: "22px",
    textAlign: "center",

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
  },

  documentWrapper: {
    marginTop: "12px",
    maxHeight: "65vh",
    overflowY: "scroll",
    ...mainTheme.scrollbars.default,

    [mainTheme.breakpoints.down("md")]: {
      maxHeight: "500px",
    },
  },

  errorWrapper: {
    width: "100%",
    rowGap: "10px",
    height: "57vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",

    [mainTheme.breakpoints.down("md")]: {
      height: "100px",
    },
  },

  tabs: {
    width: "100%",
    height: "42px",
    display: "flex",
    padding: "3px 6px",
    borderRadius: "8px",
    alignItems: "center",
    color: "content.secondary",
    backgroundColor: "content.grey-100",

    ".Mui-selected": {
      color: "#04172C !important",
    },

    "& button": {
      padding: "0",
      height: "36px",
      minHeight: "unset",
    },

    ".MuiTabs-indicator": {
      height: "100%",
      borderRadius: "8px",
      backgroundColor: "dominant.white",
    },
  },
};
