import { FC, useMemo } from "react";

import { Box, Divider, useMediaQuery } from "@mui/material";
import { useTranslations } from "next-intl";

import { TTourContentInfo, TConditionalRendering } from "../model/types";
import { AmenitiesComponent } from "./AmenitiesComponent";
import { HeaderDate } from "./HeaderDate";
import { styles } from "./styles";

import { getTransferText } from "@/pagesLayer/polety/[tourId]/lib/helpers";

import { Condition } from "@/shared/lib/condition";
import { EAmenityTypes } from "@/shared/lib/constants";
import { getFlightTypeText } from "@/shared/lib/helpers";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { SingleAmenity } from "@/shared/ui";

export const TourContentInfo: FC<TTourContentInfo & TConditionalRendering> = ({
  dateTo,
  adults,
  nights,
  dateFrom,
  mealName,
  roomName,
  flightType,
  nightsTotal,
  childrenAmount,
  isDatesHidden = false,
  isHeaderHidden = false,
  areAmenitiesHidden = false,
}) => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const transferText = useMemo(() => getTransferText(flightType), [flightType]);

  const nightsForFlight = nightsTotal - nights;
  const nightsText = !nightsForFlight
    ? t("NIGHT_DECLINATIONS", { count: nights })
    : `${t("NIGHT_DECLINATIONS", { count: nightsTotal })} (${t("NIGHT_DECLINATIONS", { count: nightsForFlight })} ${t("ENROUTE")})`;

  const tourAmenities = [
    {
      size: 16,
      value: nightsText,
      type: EAmenityTypes.duration,
    },
    {
      size: 20,
      type: EAmenityTypes.passengers,
      value: `${t("ADULT_DECLINATIONS", { count: Number(adults) })} 
      ${
        Number(childrenAmount)
          ? `, ${t("CHILDREN_DECLINATIONS", { count: Number(childrenAmount) })}`
          : ""
      }`,
    },
  ];

  const amenitiesContent = [
    {
      value: roomName,
      type: EAmenityTypes.room,
    },
    {
      value: mealName,
      type: EAmenityTypes.meal,
    },
    {
      value: t(transferText),
      type: EAmenityTypes.transfer,
    },
    {
      value: t("STANDARD_MEDICAL"),
      type: EAmenityTypes.insurance,
    },
    {
      isHiddenOnMobile: true,
      type: EAmenityTypes.flight,
      value: t(getFlightTypeText(flightType)),
    },
  ];

  return (
    <>
      <Box>
        <Condition match={!isHeaderHidden}>
          <Condition match={!isDatesHidden}>
            <Box sx={styles.dates}>
              <HeaderDate date={dateFrom} />
              <Divider sx={styles.dates.hr} orientation="vertical" />
              <HeaderDate date={dateTo} title={t("BACK")} />
            </Box>
          </Condition>

          <Box sx={styles.mainDetails}>
            {tourAmenities.map(({ size, type, value }, index) => (
              <SingleAmenity
                isDesktop
                key={index}
                size={size}
                type={type}
                value={value}
              />
            ))}
          </Box>
        </Condition>

        <Condition match={!isHeaderHidden && !areAmenitiesHidden}>
          <Divider sx={styles.amenities.hr} />
        </Condition>

        <Condition match={!areAmenitiesHidden}>
          <AmenitiesComponent
            isMobile={isMobile}
            amenitiesContent={amenitiesContent}
          />
        </Condition>
      </Box>
    </>
  );
};
