export const styles = {
  default: {
    width: "16px",
  },

  wrapper: {
    display: "flex",
    cursor: "default",
    alignItems: "center",
  },
};
