export const styles = {
  linkIcon: {
    transform: "rotate(-45deg)",
  },

  copyIcon: {
    width: "20px",
    height: "20px",
  },
  copyButton: {
    width: "50%",
    fontSize: "14px",
  },
  mediaLabel: {
    marginTop: "8px",
    color: "content.primary",
  },
  popover: {
    ".MuiPaper-root": {
      borderRadius: "12px",
    },
  },
  mobileWrapper: {
    width: "100%",
    borderRadius: "12px",
    height: "fit-content",
    padding: "24px 24px 24px",
  },
  socialMediaWrapper: {
    display: "flex",
    marginTop: "24px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputWrapper: {
    gap: "16px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  wrapper: {
    /* padding bottom 32px, padding left, top, right 24px */
    height: 192,
    width: "564px",
    borderRadius: "12px",
    padding: "24px 24px 32px",
  },
  linkInput: {
    gap: "12px",
    width: "100%",
    display: "flex",
    borderRadius: "8px",
    border: "1px solid",
    padding: "4px 12px",
    alignItems: "center",
    textDecoration: "none",
    color: "content.grey-400",
    borderColor: "accent.brand.lightest",
  },
  iconWrapper: {
    overflow: "hidden",
    textAlign: "center",
    position: "relative",
    transition: "transform 0.3s ease",
    a: {
      textDecoration: "none",
    },
    "&:hover:before": {
      opacity: 1,
    },
    "&:hover": {
      cursor: "pointer",
      transform: "translateY(-5px)",
    },
    "&:before": {
      top: 0,
      left: 0,
      opacity: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
      transition: "opacity 0.3s ease",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
};
