import { FC } from "react";

import { Place } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { HotelStarsRating } from "./HotelStarsRating";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";

type TProps = {
  name: string;
  address?: string;
  fullWidth?: boolean;
  rating?: null | string | number;
};

export const HotelInfoBlock: FC<TProps> = ({
  name,
  rating,
  address,
  fullWidth = false,
}) => {
  const t = useTranslations();

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.ratingWrapper}>
        <Condition match={!!rating}>
          <HotelStarsRating rating={rating!} />
        </Condition>
      </Box>
      <Typography variant="h2" sx={styles.title}>
        {t(name)}
      </Typography>
      <Box sx={styles.addressBox(fullWidth)}>
        <Place fontSize="small" sx={{ color: "content.grey-200" }} />
        <Typography sx={styles.address(fullWidth)}>
          {address ?? t("ADDRESS_NOT_SPECIFIED")}
        </Typography>
      </Box>
    </Box>
  );
};
