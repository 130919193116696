import { createQuery } from "@farfetched/core";

import {
  getAllPagesFx,
  getToursArrivalsFx,
  getContructorPageFx,
  getHotTourFiltersFx,
  getToursDeparturesFx,
  getPagesCategoriesFx,
  getAllDepartureAndsArrivalsFx,
} from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getConstructorPageQuery = createQuery({
  effect: getContructorPageFx,
  name: "getConstructorPageQuery",
  mapData: ({ result }) => result.data.data,
});

export const getToursDeparturesQuery = createQuery({
  effect: getToursDeparturesFx,
  name: "getToursDeparturesQuery",
  mapData: ({ result }) => result.data.data,
});

export const getToursArrivalsQuery = createQuery({
  effect: getToursArrivalsFx,
  name: "getToursArrivalsQuery",
  mapData: ({ result }) => result.data.data,
});

export const getPagesCategoriesQuery = createQuery({
  name: "getPagesCategories",
  effect: getPagesCategoriesFx,
  mapData: ({ result }) => result.data.data,
});

export const getAllPagesQuery = createQuery({
  name: "getAllPages",
  effect: getAllPagesFx,
  mapData: ({ result }) => result.data.data,
});

export const getPromoPageQuery = createQuery({
  name: "getPromoPage",
  effect: getAllPagesFx,
  mapData: ({ result }) => result.data.data,
});

export const getArticlesPageQuery = createQuery({
  effect: getAllPagesFx,
  name: "getArticlesPage",
  mapData: ({ result }) => result.data.data,
});

export const getAllDeparturesAndArrivalsQuery = createQuery({
  name: "getAllDeparturesAndArrivals",
  effect: getAllDepartureAndsArrivalsFx,
  mapData: ({ result }) => result.data.data,
});

export const getHotTourFiltersQuery = createQuery({
  name: "getHotTourFilters",
  effect: getHotTourFiltersFx,
  mapData: ({ result }) => result.data.data,
});

QueryTools(getConstructorPageQuery).errors.handleAll();
QueryTools(getToursDeparturesQuery).errors.handleAll();
QueryTools(getToursArrivalsQuery).errors.handleAll();
QueryTools(getPagesCategoriesQuery).errors.handleAll();
QueryTools(getAllPagesQuery).errors.handleAll();
QueryTools(getAllDeparturesAndArrivalsQuery).errors.handleAll();
QueryTools(getHotTourFiltersQuery).errors.handleAll();
