import { FC } from "react";

import { useTranslations } from "next-intl";

import {
  ERROR_COLORS,
  SUCCESS_COLORS,
  PENDING_COLORS,
  ERROR_FINISH_COLORS,
} from "@/shared/lib/constants";
import { EOrderStatus, EOrderPayment } from "@/shared/lib/constants/orders";
import { Badge } from "@/shared/ui";

const getStatusColor = (status?: EOrderStatus) => {
  switch (status) {
    case EOrderStatus.Ready:
    case EOrderStatus.Created:
    case EOrderStatus.Confirmed:
      return SUCCESS_COLORS;
    case EOrderStatus.ConfirmationPending:
      return PENDING_COLORS;
    default:
      return ERROR_COLORS;
  }
};

const getPaymentColor = (status?: EOrderPayment) => {
  switch (status) {
    case EOrderPayment.Paid:
      return SUCCESS_COLORS;
    case EOrderPayment.Refunded:
      return ERROR_FINISH_COLORS;
    case EOrderPayment.RefundPending:
      return ERROR_COLORS;
    case EOrderPayment.Unpaid:
      return PENDING_COLORS;
    default:
      return ERROR_COLORS;
  }
};

type TProps<T> = {
  status?: T;
  label?: string;
};

type TBookingProps = TProps<EOrderStatus>;
type TPaymentProps = TProps<EOrderPayment>;

export const BookingStatusBadge: FC<TBookingProps> = ({ label, status }) => (
  <Badge sx={getStatusColor(status)}>{label}</Badge>
);

export const PaymentStatusBadge: FC<TPaymentProps> = ({ label, status }) => (
  <Badge sx={getPaymentColor(status)}>{label}</Badge>
);

export const DocumentsStatusBadge: FC<{
  status?: EOrderStatus;
}> = ({ status }) => {
  const t = useTranslations();
  const isStatusAbsentOrInvalid =
    !status || ![EOrderStatus.Ready, EOrderStatus.Confirmed].includes(status);

  if (isStatusAbsentOrInvalid) {
    return null;
  }

  const badgeProps =
    status === EOrderStatus.Confirmed
      ? { sx: PENDING_COLORS, label: "IN_PROGRESS" }
      : { label: "COMPLETED", sx: SUCCESS_COLORS };

  return <Badge sx={badgeProps.sx}>{t(badgeProps.label)}</Badge>;
};
