import { COLORS } from "shared/ui/styles";

import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  number: {
    fontWeight: 500,
    fontSize: "20px",
    marginLeft: "8px",
    lineHeight: "24px",
    color: COLORS.DARK_GRAY,
  },

  label: {
    fontSize: "18px",
    lineHeight: "22px",
    color: COLORS.GRAY,
    whiteSpace: "nowrap",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "12px",
      lineHeight: "16px",
      textTransform: "uppercase",
    },
  },

  wrapper: {
    display: "flex",
    borderRadius: "8px",
    padding: "9px 12px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: COLORS.WHITE_BLUE,

    [mainTheme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "flex-end",
    },
  },
};
