import { useSearchParams } from "next/navigation";

import { useLocale } from "next-intl";

import { useAppPathname } from "./useAppPathname";

const RU_LOCALE = "ru";
const KAZ_LOCALE = "kz";

export const useSwitchLocale = () => {
  const locale = useLocale();

  const pathname = useAppPathname();
  const searchParams = useSearchParams();
  const code = locale === RU_LOCALE ? `/${KAZ_LOCALE}` : `/${RU_LOCALE}`;

  const onLangChange = () => {
    const current = new URLSearchParams(Array.from(searchParams.entries()));
    const search = current.toString();
    const query = search ? `?${search}` : "";

    window.location.replace(
      `${window.location.origin}${code}${pathname}${query}`,
    );
  };

  return { onLangChange };
};
