import { ReadonlyURLSearchParams } from "next/navigation";

export const getSearchParamsString = (
  query: Record<
    string,
    | null
    | number
    | string
    | boolean
    | undefined
    | (null | number | string | boolean | undefined)[]
  >,
  previousParams?: null | ReadonlyURLSearchParams,
) => {
  const initialParams = previousParams ? previousParams.toString() : undefined;
  const newParams = new URLSearchParams(initialParams);

  Object.entries(query).forEach(([key, value]) => {
    if (!value) {
      newParams.delete(key);
    } else if (Array.isArray(value)) {
      newParams.delete(key);

      value.forEach((v) => {
        if (
          typeof v === "boolean" ||
          typeof v === "number" ||
          typeof v === "string"
        ) {
          newParams.append(key, v.toString());
        }
      });
    } else if (
      typeof value === "boolean" ||
      typeof value === "number" ||
      typeof value === "string"
    ) {
      newParams.set(key, value.toString());
    }
  });

  return newParams.toString();
};
