import Link from "next/link";
import { FC, useMemo } from "react";

import { COLORS } from "shared/ui/styles";

import KaztourLogo from "@/public/img/logos/KaztourLogo.svg";

type TProps = {
  isHomePage?: boolean;
  color?: "blue" | "white";
};

export const KazTourLogoLink: FC<TProps> = ({ color, isHomePage }) => {
  const logoFill = useMemo(() => {
    switch (color) {
      case "blue":
        return COLORS.BLUE;
      case undefined:
        return isHomePage ? COLORS.WHITE : COLORS.BLUE;
      default:
        return COLORS.WHITE;
    }
  }, [color, isHomePage]);

  return (
    <Link
      href={isHomePage ? {} : "/"}
      onClick={(event) => (isHomePage ? event.preventDefault() : null)}
      className={`centered-logo ${isHomePage ? "cursor-default" : "default-link"}`}
    >
      <KaztourLogo width="100%" fill={logoFill} />
    </Link>
  );
};
