import { FC, useMemo } from "react";

import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { PROFILE_LINKS, MY_ORDERS_LINK } from "@/entities/profile";

import { useNextIntlPathname } from "@/shared/config/locales/navigation";
import {
  BONUSES_LINK,
  MAIN_PAGE_LINK,
  TRANSACTION_HISTORY_LINK,
} from "@/shared/lib/constants";
import { useGetOrderNumber } from "@/shared/lib/hooks";
import { Breadcrumbs } from "@/shared/ui";

export const ProfileBreadcrumbs: FC = () => {
  const t = useTranslations();
  const { orderNumber } = useGetOrderNumber();
  const pathname = useNextIntlPathname();

  const currentRoute = PROFILE_LINKS.find(({ link }) => link === pathname);

  const breadcrumbs = useMemo(() => {
    if (pathname?.includes(`/my-orders/`)) {
      const myOrders = PROFILE_LINKS.find(
        ({ link }) => link === MY_ORDERS_LINK,
      );

      return [
        MAIN_PAGE_LINK,
        {
          href: myOrders?.link ?? "",
          title: t(myOrders?.name) ?? "",
        },
        {
          title: `${t("ORDER_DETAILS")} №${orderNumber}`,
          href: `${myOrders?.link ?? ""}/${orderNumber}`,
        },
      ];
    }

    if (pathname?.includes(TRANSACTION_HISTORY_LINK)) {
      return [
        MAIN_PAGE_LINK,
        {
          href: BONUSES_LINK,
          title: t("BONUSES"),
        },
        {
          href: TRANSACTION_HISTORY_LINK,
          title: t("TRANSACTION_HISTORY"),
        },
      ];
    }

    return [
      {
        ...MAIN_PAGE_LINK,
        title: t("HOME"),
      },
      {
        href: currentRoute?.link ?? "",
        title: t(currentRoute?.name) ?? "",
      },
    ];
  }, [currentRoute?.link, currentRoute?.name, orderNumber, pathname, t]);

  return <Breadcrumbs breadcrumbs={breadcrumbs} wrapperSx={styles.wrapper} />;
};
