import { useRouter, usePathname } from "next/navigation";
import { FC } from "react";

import { Button } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { logoutMutation } from "@/shared/model/api/auth";

import ExitIcon from "@/public/icons/common/exit.svg";

export const LogoutButton: FC = () => {
  const t = useTranslations();
  const router = useRouter();
  const pathname = usePathname();

  const { start: logout } = useUnit(logoutMutation);

  const handleLogout = () => {
    logout();

    if (pathname?.includes("/profile/")) {
      void router.push("/");
    }
  };

  return (
    <Button sx={styles.button} onClick={handleLogout}>
      <ExitIcon />
      {t("LOG_OUT")}
    </Button>
  );
};
