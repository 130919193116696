import { mainTheme } from "../styles";

export const styles = {
  button: {
    width: "100%",
    minWidth: "unset",
    maxWidth: "unset",
    whiteSpace: "nowrap",
  },

  pageWrapper: {
    margin: 0,
    rowGap: "10px",
    display: "flex",
    flexDirection: "column",

    [mainTheme.breakpoints.down("md")]: {
      paddaing: "8px",
      display: "flex",
      columnGap: "10px",
      overflowX: "auto",
      maxWidth: "unset",
      flexWrap: "nowrap",
      flexDirection: "row",
      alignItems: "center",

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
};
