export const styles = {
  progress: {
    marginRight: "10px",
  },

  collapseText: {
    marginRight: "10px",
  },

  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  downIcon: {
    transition: "transform 0.2s",

    rotateIcon: {
      transform: "rotate(180deg)",
      transition: "transform 0.2s",
    },
  },

  arrow: {
    padding: 0,
    width: "40px",
    height: "40px",
    display: "flex",
    minWidth: "unset",
    transition: "0.2s",
    alignItems: "center",
    color: "primary.main",
    justifyContent: "center",

    "&:hover": {
      opacity: "0.7",
    },
  },
};
