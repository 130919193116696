import { FC } from "react";

import {
  Box,
  Button,
  Skeleton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { formatFileSize } from "@/shared/lib/helpers/valueFormatters";

import FileDownloadIcon from "@/public/icons/common/download.svg";

type TProps = {
  size?: number;
  name?: string;
  format?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

export const DownloadFileButton: FC<TProps> = ({
  size = 0,
  format = "n/a",
  isLoading = false,
  onClick = () => null,
  name = "TOURISM_SERVICE_AGREEMENT",
}) => {
  const t = useTranslations();

  if (isLoading) {
    return (
      <Button fullWidth sx={styles.downloadButton}>
        <Box sx={styles.contentWrapper}>
          <Typography sx={styles.format}>
            <CircularProgress size="32px" color="inherit" />
          </Typography>
          <Box sx={styles.textWrapper}>
            <Typography sx={styles.documentName}>
              <Skeleton width={"150px"} />
            </Typography>
            <Typography sx={styles.documentSize}>
              <Skeleton width={"50px"} />
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.iconWrapper}>
          <CircularProgress size={"24px"} />
        </Box>
      </Button>
    );
  }

  return (
    <Button fullWidth onClick={onClick} sx={styles.downloadButton}>
      <Box sx={styles.contentWrapper}>
        <Typography sx={styles.format}>{format.slice(0, 3)}</Typography>
        <Box sx={styles.textWrapper}>
          <Typography sx={styles.documentName}>{t(name)}</Typography>
          <Typography sx={styles.documentSize}>
            {formatFileSize(size)}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.iconWrapper}>
        <FileDownloadIcon width={"24px"} height={"24px"} />
      </Box>
    </Button>
  );
};
