import { FC } from "react";

import { Box, Skeleton } from "@mui/material";

import { styles } from "./styles";

type TProps = {
  isLoading: boolean;
  type: "cities" | "address";
};

export const ContactsSkeleton: FC<TProps> = ({ type, isLoading = false }) => {
  if (!isLoading) {
    return null;
  }

  switch (type) {
    case "address":
      return (
        <Box sx={styles.skeletonWrapper}>
          {Array.from({ length: 2 }, (_, index) => (
            <Skeleton key={index} sx={styles.addressSkeleton} />
          ))}
        </Box>
      );
    default:
      return (
        <>
          {Array.from({ length: 6 }, (_, index) => (
            <Skeleton key={index} sx={styles.citiesSkeleton} />
          ))}
        </>
      );
  }
};
