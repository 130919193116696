/* eslint-disable no-prototype-builtins */
import { MY_ORDERS_LINK } from "@/entities/profile";

import {
  TSearchData,
  BACKEND_KEYS,
  QUERY_OPTIONS,
} from "@/shared/model/types/common";

export const transformDataToQuery = (
  dataForRequest: TSearchData,
): Partial<TSearchData> => {
  const query = {
    [QUERY_OPTIONS.adults]: dataForRequest[BACKEND_KEYS.adults],
    [QUERY_OPTIONS.arrival]: dataForRequest[BACKEND_KEYS.arrival],
    [QUERY_OPTIONS.children]: dataForRequest[BACKEND_KEYS.children],
    [QUERY_OPTIONS.departure]: dataForRequest[BACKEND_KEYS.departure],
    [QUERY_OPTIONS.selectedNight]: dataForRequest[BACKEND_KEYS.nights],
    [QUERY_OPTIONS.rangeEndDay]: dataForRequest[BACKEND_KEYS.rangeEndDay],
    [QUERY_OPTIONS.childrenAges]: dataForRequest[BACKEND_KEYS.childrenAges],
    [QUERY_OPTIONS.rangeStartDay]: dataForRequest[BACKEND_KEYS.rangeStartDay],
    [QUERY_OPTIONS.selectedRegionIds]:
      dataForRequest[BACKEND_KEYS.selectedRegionIds],
  };

  return Object.entries(query).reduce((acc, [key, value]) => {
    if (
      typeof value === "string" ||
      typeof value === "number" ||
      (Array.isArray(value) && value.length > 0)
    ) {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});
};

export const generateLinkAccordingToOrderStatus = ({
  isExpired,
  orderNumber,
  isOrderPaid,
  areAllPassengersFilled,
}: {
  isExpired: boolean;
  isOrderPaid: boolean;
  orderNumber: string | number;
  areAllPassengersFilled: boolean;
}) => {
  if (isExpired) {
    return null;
  }

  if (isOrderPaid) {
    return {
      text: "ORDER_DETAILS",
      href: `${MY_ORDERS_LINK}/${orderNumber}`,
    };
  }

  if (areAllPassengersFilled) {
    return {
      text: "PROCEED_TO_PAYMENT",
      href: `/payment/${orderNumber}`,
    };
  }

  return {
    text: "PROVIDE_DETAILS",
    href: `/order/${orderNumber}`,
  };
};
