import { mainTheme } from "shared/ui/styles";

export const styles = {
  sumsValue: {
    color: "content.primary",
  },

  iconWrapper: {
    padding: "4px",
    color: "accent.orange.attention",
  },

  contentWrapper: {
    rowGap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  infoText: {
    fontSize: "16px",
    lineHeight: "18px",
    marginBottom: "8px",
    color: "content.primary",
  },

  redirectButton: {
    width: "fit-content",
    [mainTheme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sumRowWrapper: {
    display: "flex",
    fontSize: "16px",
    columnGap: "4px",
    lineHeight: "18px",
    alignItems: "center",
    justifyContent: "space-between",
  },

  infoBlock: {
    margin: 0,
    border: 0,
    display: "flex",
    padding: "12px",
    columnGap: "8px",
    borderRadius: "12px",
    backgroundColor: "accent.yellow.low",
  },

  sumsBlockWrapper: {
    border: 0,
    rowGap: "8px",
    padding: "12px",
    display: "flex",
    margin: "12px 0 0 0",
    borderRadius: "12px",
    flexDirection: "column",
    color: "content.grey-400",
    backgroundColor: "dominant.main",
    borderColor: "accent.brand.lightest",
  },
};
