import { FC, memo, useMemo, ReactNode, ElementType } from "react";

import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import * as CSS from "csstype";

import { styles } from "./styles";

type TProps = {
  icon?: ElementType;
  sx?: CSS.Properties;
  size?: "small" | "large";
  iconSize?: string | number;
  children?: string | ReactNode | JSX.Element | JSX.Element[];
};

const getStylesBySize = (size?: string) => {
  switch (size) {
    case "small":
      return {
        fontSize: "10px",
        lineHeight: "10px",
        letterSpacing: "0.2px",
      };
    case "large":
      return {
        fontSize: "14px",
        letterSpacing: "",
        lineHeight: "16px",
      };
    default:
      return {
        fontSize: "12px",
        letterSpacing: "",
        lineHeight: "14px",
      };
  }
};

export const Badge: FC<TProps> = memo(
  ({ icon, size, sx = {}, children, iconSize = "16px" }: TProps) => {
    const computedStyles = useMemo(
      () => ({
        ...getStylesBySize(size),
        ...styles,
        ...sx,
      }),
      [size, sx],
    );

    const computedIconStyles = useMemo(
      () => ({ fontSize: iconSize }),
      [iconSize],
    );
    return (
      <Box sx={computedStyles}>
        <span>{children}</span>
        {icon && (
          <SvgIcon inheritViewBox component={icon} sx={computedIconStyles} />
        )}
      </Box>
    );
  },
);
