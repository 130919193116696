import { createEvent, createStore } from "effector";

import { TNotice } from "../../types/common/entities/common";

export const $notification = createStore<null | TNotice>(null);
export const $isNotificationVisible = createStore<boolean>(false);

export const openNotification = createEvent<TNotice>();
export const clearNotification = createEvent();

$notification.on(openNotification, (_, v) => v);
$notification.on(clearNotification, () => null);
