import { mainTheme } from "shared/ui/styles";

export const styles = {
  documentName: {
    color: "content.primary",
  },

  documentSize: {
    color: "content.grey-400",
  },

  contentWrapper: {
    display: "flex",
    columnGap: "12px",
    alignItems: "center",
  },

  iconWrapper: {
    padding: "0 12px",

    [mainTheme.breakpoints.down("md")]: {
      padding: "0 6px",
    },
  },

  textWrapper: {
    width: "70%",
    fontSize: "14px",
    textAlign: "left",
    lineHeight: "16px",

    [mainTheme.breakpoints.down("md")]: {
      maxWidth: "70%",
    },
  },

  downloadButton: {
    width: "100%",
    height: "100%",
    padding: "12px",
    display: "flex",
    transition: "0.2s",
    borderRadius: "12px",
    alignItems: "center",
    backgroundColor: "dominant.dop",
    justifyContent: "space-between",

    "&:hover": {
      opacity: "0.7",
      backgroundColor: "dominant.dop",
    },
  },

  format: {
    width: "56px",
    height: "56px",
    fontWeight: 500,
    display: "flex",
    fontSize: "20px",
    minWidth: "56px",
    lineHeight: "24px",
    textAlign: "center",
    borderRadius: "8px",
    alignItems: "center",
    color: "dominant.white",
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: "content.grey-200",
  },
};
