import { attach } from "effector";

import {
  TSendOTP,
  TConfirmOTP,
  TSendOTPPayload,
  TConfirmOTPPayload,
  TSignUpByOTPPayload,
} from "../../types/auth";
import { TFFRequest } from "../../types/common";

import { requestFx } from "@/shared/config";

export type TSendOTPResponse = TFFRequest<TSendOTP>;
export type TConfirmOTPResponse = TFFRequest<TConfirmOTP>;

export const postAuthOTPFx = attach<TSendOTPPayload, TSendOTPResponse>({
  effect: requestFx,
  mapParams: (payload) => ({
    data: payload,
    method: "POST",
    url: `auth/otp/phone`,
  }),
});

export const postAuthOTPConfirmationFx = attach<
  TConfirmOTPPayload,
  TConfirmOTPResponse
>({
  effect: requestFx,
  mapParams: (payload) => ({
    data: payload,
    method: "POST",
    url: "auth/otp/phone/confirm",
  }),
});

export const postSignUpCompleteFx = attach<
  TSignUpByOTPPayload,
  TConfirmOTPResponse
>({
  effect: requestFx,
  mapParams: (payload) => ({
    data: payload,
    method: "POST",
    url: "auth/otp/phone/complete",
  }),
});

export const postLogoutFx = attach({
  effect: requestFx,
  mapParams: () => ({
    url: "logout",
    method: "POST",
  }),
});
