import { mainTheme } from "shared/ui/styles";

export const styles = {
  containerPadding: {
    padding: "8px 20px",
  },

  flexBox: {
    display: "flex",
    alignItems: "center",
  },

  button: {
    padding: 0,
    height: "unset",

    "&:hover": {
      backgroundColor: "unset",
    },
  },

  arrowWrapper: {
    width: "34px",
    display: "flex",
    transition: "0.2s",
    alignItems: "center",
    paddingRight: "14px",
    justifyContent: "flex-end",
  },

  text: (isIcon: boolean) => ({
    fontSize: "16px",
    marginRight: "6px",
    lineHeight: "16px",
    color: "accent.brand.primary",
    marginLeft: isIcon ? "15px" : 0,
  }),

  actionCard: {
    width: "100%",
    height: "40px",
    display: "flex",
    cursor: "pointer",
    transition: "0.2s",
    alignItems: "center",
    justifyContent: "space-between",

    [mainTheme.breakpoints.down("md")]: {
      paddingRight: 0,
    },

    "&:hover": {
      opacity: "0.7",
      "& .MuiBox-root:last-child": {
        paddingRight: "5px",
      },
    },
  },
};
