import { createQuery } from "@farfetched/core";

import {
  getBonusesAmountFx,
  getBonusesTransactionHistoryFx,
} from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getBonusesAmountQuery = createQuery({
  effect: getBonusesAmountFx,
  name: "getBonusesAmountQuery",
  mapData: ({ result }) => result.data.data,
});

export const getBonusesHistoryQuery = createQuery({
  name: "getBonusesHistoryQuery",
  effect: getBonusesTransactionHistoryFx,
  mapData: ({ result }) => result.data.data,
});

QueryTools(getBonusesAmountQuery).errors.handleAll();
QueryTools(getBonusesHistoryQuery).errors.handleAll();
QueryTools(getBonusesAmountQuery).clearPrivateData();
QueryTools(getBonusesHistoryQuery).clearPrivateData();
