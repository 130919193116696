import * as CSS from "csstype";

// TODO: solve crash issue
import { createGradient } from "@/shared/lib/helpers/helpers";

const gradient: CSS.Properties = createGradient();

export const styles = {
  imageLoader: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",

    justifyContent: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",

    ...gradient,
  },
};

export const inlineImageStyles: CSS.Properties = {
  width: "100%",
  height: "100%",
  transition: "0.2s",
  objectFit: "cover",
};
