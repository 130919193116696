export const styles = {
  citiesSkeleton: {
    width: "83px",
    height: "30px",
  },

  addressSkeleton: {
    width: "100%",
    height: "92px",
    transform: "none",
  },

  skeletonWrapper: {
    rowGap: "36px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};
