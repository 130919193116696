import { EOrderStatus, EOrderPayment } from "@/shared/lib/constants/orders";

export enum EPaymentTypes {
  Loan = "loan",
  Card = "card",
  Kaspi = "kaspi",
  Office = "office",
}

export enum ECreditApprovalStatus {
  Error = "ERROR",
  Loading = "LOADING",
  Rejected = "REJECTED",
  Approved = "APPROVED",
}

export enum ECreditTypes {
  Loan = "loan",
  Installment = "installment",
}

export enum AVAILABLE_SCORING_CHANNELS {
  Web = "KAZ_TUR_WEB",
}

export enum EService {
  Transfer = "transfer",
  Insurance = "insurance",
}

export enum EOrderCategory {
  Active = "active",
  Archive = "archive",
  Completed = "completed",
}

export type TPaymentType = {
  id: string;
  name: string;
  code: EOrderPayment;
};

export type TOrderStatus = {
  id: string;
  name: string;
  code: EOrderStatus;
};
