"use client";
import { FC, useState } from "react";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { PaymentsDetailsModal } from "../PaymentsDetailsModal";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks";
import { getOrderPaymentsDetailsQuery } from "@/shared/model/api/orders";

export const PaymentsDetailsButton: FC = () => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: paymentsDetails } = useUnit(getOrderPaymentsDetailsQuery);

  return (
    <>
      <Box sx={styles.buttonWrapper}>
        <Typography sx={styles.infoText}>
          {t("PAYMENTS")}:{" "}
          <Typography component="span" sx={styles.infoSpan}>
            {paymentsDetails?.length}
          </Typography>
        </Typography>
        <Condition match={!!paymentsDetails?.length}>
          <Button sx={styles.infoButton} onClick={() => setIsModalOpen(true)}>
            {t("PAYMENT_DETAILS")}
          </Button>
        </Condition>
      </Box>

      <Condition match={!!paymentsDetails?.length}>
        <PaymentsDetailsModal
          isPhone={isPhone}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </Condition>
    </>
  );
};
