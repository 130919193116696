export const styles = {
  departuresList: {
    padding: "0",
  },

  listItem: {
    display: "flex",
    alignItems: "center",
  },

  tooltip: {
    width: "30px",
    height: "30px",
    display: "flex",
    paddingLeft: "10px",
    alignItems: "center",
    color: "grey.extralight",
  },

  headerGeolocation: {
    display: "flex",
    cursor: "pointer",
    transition: "0.2s",
    alignItems: "center",

    "&:hover": {
      opacity: "0.7",
    },
  },
};
