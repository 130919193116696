import { memo } from "react";

import { Box, useMediaQuery } from "@mui/material";
import * as CSS from "csstype";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { ProgressBar } from "@/shared/ui";

type TourSearchProgressBarProps = {
  sx?: CSS.Properties;
};

type TTheme = {
  breakpoints: {
    up: (arg: string) => string;
  };
};

export const TourSearchProgressBar: React.FC<TourSearchProgressBarProps> = memo(
  ({ sx }) => {
    const t = useTranslations();
    const theme = useCustomTheme();
    const isDesktop = useMediaQuery((theme as TTheme).breakpoints.up("md"));

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      <Box
        xs={12}
        sx={{
          ...sx,
          ...(styles.progressBar.wrapper as CSS.Properties),
        }}
      >
        <ProgressBar
          hasStripe
          value={100}
          color="primary"
          variant="determinate"
        />{" "}
        <Box
          sx={
            isDesktop
              ? styles.progressBar.textBox
              : styles.progressBar.textBoxMobile
          }
        >
          {t("FINDING_BEST_OFFERS")}
        </Box>
      </Box>
    );
  },
);
