import { FC, useRef, useMemo, useState, useEffect } from "react";

import { Box } from "@mui/material";

import { LoaderButton } from "../Buttons";
import { styles } from "./styles";

type TProps = {
  buttonText: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  pageYOffset?: number;
  Header?: string | JSX.Element;
};

export const StickyMobileButton: FC<TProps> = ({
  onClick,
  buttonText = "",
  disabled = false,
  isLoading = false,
  Header = undefined,
}) => {
  const [isVisible, setVisible] = useState(true);
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const boundingClientRect: DOMRect = ref.current.getBoundingClientRect();

        if (window.innerHeight >= boundingClientRect.top + 101) {
          setVisible(false);
        } else {
          setVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const wrapperStyles = useMemo(() => {
    const animation = isVisible
      ? styles.wrapperAnimation.visible
      : styles.wrapperAnimation.hidden;

    return {
      ...styles.wrapperAnimation,
      ...styles.mobileButtonWrapper,
      ...animation,
    };
  }, [isVisible]);

  const CommonButton = (
    <LoaderButton
      fullWidth
      size="large"
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      loading={isLoading}
    >
      {buttonText}
    </LoaderButton>
  );

  return (
    <>
      <Box sx={wrapperStyles}>
        <Box sx={styles.mobileContainer}>
          {Header}
          {CommonButton}
        </Box>
      </Box>

      <Box ref={ref} sx={styles.mobileBaseContainer}>
        {Header}
        {CommonButton}
      </Box>
    </>
  );
};
