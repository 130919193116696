/* eslint-disable perfectionist/sort-objects */

import { SOCIAL_LINK_OPTIONS } from "../lib";

import { getContactsQuery } from "@/shared/model/api/contacts";
import { EContactTypes } from "@/shared/model/types/contacts";

export const $contactsData = getContactsQuery.$data.map((v) => v?.data ?? []);

export const $contactPhones = getContactsQuery.$data.map(
  (v) => v?.data.filter((c) => c.type === EContactTypes.Phone) || [],
);

export const $contactSocials = getContactsQuery.$data.map(
  (v) => v?.data.filter((c) => SOCIAL_LINK_OPTIONS.includes(c.type)),
  {
    skipVoid: false,
  },
);
