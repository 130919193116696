export const styles = {
  inclusionsIcons: {
    gap: "15px",
    display: "flex",
    alignItems: "center",
  },
  mobileInclusionsIcons: {
    gap: "10px",
    display: "flex",
    marginTop: "12px",
    alignItems: "center",
    justifyContent: "center",
  },
};
