import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { priceFormatter } from "@/shared/lib/helpers";
import { TCanceling } from "@/shared/model/types/orders";
import { RoundedBorderContainer } from "@/shared/ui";

type TProps = {
  tourPrice?: number;
  isRefundFinished?: boolean;
  cancellationDetails?: TCanceling;
};

export const CancellationStep: FC<TProps> = ({
  tourPrice,
  cancellationDetails,
  isRefundFinished = false,
}) => {
  const t = useTranslations();

  const sumsArray = [
    {
      value: tourPrice,
      label: "TOUR_PRICE",
    },
    {
      label: "Cумма штрафа",
      value: cancellationDetails?.penalty,
    },
    {
      isTotal: true,
      label: "REFUND_AMOUNT",
      value: cancellationDetails?.refund,
    },
  ];

  return (
    <Box>
      <Typography sx={styles.infoText}>
        {t(
          isRefundFinished
            ? "REFUND_AMOUNT_IS"
            : "TOUR_FUNDS_WILL_BE_RETURNED_TO_YOUR_CARD_WITHIN_3_BUSINESS_DAYS",
        )}
      </Typography>
      <RoundedBorderContainer sx={styles.wrapper}>
        {sumsArray.map(({ label, value, isTotal = false }, index) => (
          <Box key={index} sx={styles.singleLine(isTotal)}>
            <Typography sx={styles.label}>{t(label)}</Typography>
            <Typography sx={styles.dots} component="span" />
            {cancellationDetails || !index ? (
              <Typography sx={styles.value}>{priceFormatter(value)}</Typography>
            ) : (
              <Typography>{t("WE_CALCULATE")}</Typography>
            )}
          </Box>
        ))}
      </RoundedBorderContainer>
    </Box>
  );
};
