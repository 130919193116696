export const styles = {
  equalizerIcon: {
    fontSize: "100px",
    color: "grey.light",
  },
  placeholderText: {
    fontWeight: 400,
    fontSize: "24px",
    textAlign: "center",
    color: "grey.light",
  },
  placeHolder: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  placeholderCard: {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "24px",
    border: "1px solid",
    background: "white",
    alignItems: "center",
    borderRadius: "12px",
    position: "relative",
    justifyContent: "center",
    borderColor: "accent.brand.lightest",
  },
};
