export const styles = {
  ratingWrapper: {
    marginBottom: "8px",
  },

  rating: {
    marginRight: "6px",
    verticalAlign: "bottom",
  },

  categoryTag: {
    width: "fit-content",
    color: "accent.brand.primary",
    backgroundColor: "accent.brand.lightest",
  },

  wrapper: {
    display: "flex",
    maxWidth: "100%",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  addressBox: (fullWidth: boolean) => ({
    display: "flex",
    columnGap: "5px",
    alignItems: "center",
    maxWidth: fullWidth ? "100%" : "300px",
  }),

  title: {
    fontWeight: 500,
    fontSize: "18px",
    overflow: "hidden",
    lineHeight: "22px",
    marginBottom: "8px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "content.primary",
  },

  address: (fullWidth: boolean) => ({
    width: "100%",
    fontSize: "14px",
    overflow: "hidden",
    lineHeight: "16px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "content.grey-400",
    maxWidth: fullWidth ? "unset" : "250px",
  }),
};
