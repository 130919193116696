import { FC } from "react";

import { Box, SvgIcon, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { EAmenityTypes } from "@/shared/lib/constants";
import { sideBarIconsRenderer } from "@/shared/lib/helpers";

type TProps = {
  isDesktop?: boolean;
  type: EAmenityTypes;
  size?: number | string;
  value: string | number;
};

export const SingleAmenity: FC<TProps> = ({
  type,
  value,
  size = 24,
  isDesktop = false,
}) => {
  const t = useTranslations();
  const { text, icon } = sideBarIconsRenderer(type);

  if (isDesktop) {
    return (
      <Box sx={styles.desktop.wrapper}>
        <Box sx={styles.desktop.textWrapper}>
          <SvgIcon width={size} height={size} component={icon} />
          <Typography sx={styles.desktop.title}>{t(text)}</Typography>
        </Box>
        <Typography sx={styles.desktop.value}>{value}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={styles.mobile.wrapper}>
      <SvgIcon width={size} height={size} component={icon} />
      <Box sx={styles.mobile.textWrapper}>
        <Typography sx={styles.mobile.title}>{t(text)}</Typography>
        <Typography sx={styles.mobile.value}>{value}</Typography>
      </Box>
    </Box>
  );
};
