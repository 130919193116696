import { FC, useMemo } from "react";

import { Grid, Stack, SxProps, GridDirection } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";

import { styles } from "./styles";

type TDirection = ResponsiveStyleValue<GridDirection>;

export type TCommonPageLayout = {
  leftSx?: SxProps;
  isDesktop: boolean;
  customDirection?: TDirection;
  sideBar: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
};

const MOBILE_OPTIONS = {
  spacing: 3,
  leftGrid: undefined,
  direction: "column",
  rightGrid: undefined,
};

const DESKTOP_OPTIONS = {
  spacing: 3,
  leftGrid: 8,
  rightGrid: 4,
  direction: "row",
};

export const CommonPageLayout: FC<TCommonPageLayout> = ({
  leftSx,
  sideBar,
  children,
  isDesktop,
  customDirection,
}) => {
  const options = useMemo(
    () => (isDesktop ? DESKTOP_OPTIONS : MOBILE_OPTIONS),
    [isDesktop],
  );

  const { spacing, leftGrid, direction, rightGrid } = options;

  return (
    <Grid
      container
      spacing={spacing}
      direction={customDirection ?? (direction as TDirection)}
    >
      <Grid item sx={leftSx} xs={leftGrid}>
        <Stack spacing={3} sx={styles.leftGrid}>
          {children}
        </Stack>
      </Grid>

      <Grid item xs={rightGrid} sx={styles.rightGrid}>
        {sideBar}
      </Grid>
    </Grid>
  );
};
