import { FC, Fragment, ReactNode, createElement } from "react";

type ConditionProps = {
  match: boolean;
  children: ReactNode | HTMLElement;
};

export const Condition: FC<ConditionProps> = ({ match, children }) => {
  if (match) {
    return createElement(Fragment, {}, children as ReactNode);
  }

  return null;
};
