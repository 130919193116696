import { TSearchData, BACKEND_KEYS } from "../../common";

export type TSearchSlice = TSearchData;

export const initialState: TSearchSlice = {
  [BACKEND_KEYS.adults]: 2,
  [BACKEND_KEYS.nights]: 4,
  [BACKEND_KEYS.children]: 0,
  [BACKEND_KEYS.arrival]: "",
  [BACKEND_KEYS.departure]: "",
  [BACKEND_KEYS.rangeEndDay]: "",
  [BACKEND_KEYS.rangeEndNight]: 2,
  [BACKEND_KEYS.childrenAges]: [],
  [BACKEND_KEYS.rangeStartDay]: "",
  [BACKEND_KEYS.rangeStartNight]: 2,
  [BACKEND_KEYS.selectedRegionIds]: [],
};

export enum ESearchType {
  Home = "home",
  Main = "main",
  Hotel = "hotel",
  Constructor = "constructor",
}

export type TReview = {
  id: number;
  text: string;
  title: string;
};

export type TSurveyCountry = {
  id: string;
  name: string;
  cover: string;
};

export type TSurveyTouristOption = {
  id: string;
  name: string;
  adults: string;
  children: string;
};

type TSurveyHotelCategory = {
  id: string;
  name: string;
  value: string;
};

type TSurveyTourType = {
  id: string;
  name: string;
};

export type TSurveyData =
  | TSurveyCountry[]
  | TSurveyTourType[]
  | TSurveyHotelCategory[]
  | TSurveyTouristOption[];
