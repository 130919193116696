import { FC } from "react";

import { Error } from "@mui/icons-material";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { DEFAULT_ERROR } from "@/shared/lib/constants";
import { Link } from "@/shared/ui";

type TProps = {
  isLoading?: boolean;
  errorMessage?: string;
  linkProps?: {
    text: string;
    href: string;
  };
};

export const BlockedLayoutOverlay: FC<TProps> = ({
  isLoading,
  errorMessage,
  linkProps = {
    href: "/",
    text: "TO_HOME",
  },
}) => {
  const t = useTranslations();
  const { href, text } = linkProps;

  if (isLoading) {
    return (
      <Box sx={styles.overlay}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={styles.overlay}>
      <Box sx={styles.errorWrapper}>
        <Error color="warning" sx={styles.icon} />
        <Typography sx={styles.errorMessage}>
          {errorMessage ?? t(DEFAULT_ERROR)}
        </Typography>
        <Link href={href}>{t(text)}</Link>
      </Box>
    </Box>
  );
};
