"use client";
import { FC } from "react";

import { Box, List, Skeleton } from "@mui/material";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";

type TProps = {
  listLength: number;
  isLoading: boolean;
  children: JSX.Element[];
};

const SLICE_AMOUNT = 4;

export const ConstructorCardList: FC<TProps> = ({
  children,
  isLoading,
  listLength,
}) => {
  const isSingleCard = listLength === 1;

  return (
    <Box sx={styles.wrapper(isSingleCard)}>
      <List sx={isSingleCard ? styles.singleCardList : styles.list}>
        <Condition match={isLoading}>
          {Array.from({ length: SLICE_AMOUNT }, (_, index) => (
            <Skeleton key={index} variant="rounded" sx={styles.skeleton} />
          ))}
        </Condition>
        <Condition match={!isLoading}>{children}</Condition>
      </List>
    </Box>
  );
};
