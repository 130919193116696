import { FC } from "react";

import { Box, SxProps, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks";

import Building from "@/public/icons/amenities/badge/building.svg";
import Bus from "@/public/icons/amenities/badge/bus.svg";
import Plane from "@/public/icons/amenities/badge/plane.svg";
import ShieldIcon from "@/public/icons/amenities/badge/shield.svg";

export const TourInclusions: FC<{ wrapperSx?: SxProps }> = ({ wrapperSx }) => {
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const inclusionsIconsStyles = isDesktop
    ? styles.inclusionsIcons
    : styles.mobileInclusionsIcons;

  const wrapperStyles = {
    ...inclusionsIconsStyles,
    ...wrapperSx,
  };

  const iconDimentions = {
    width: "24",
    height: "24px",
  };

  return (
    <Box sx={wrapperStyles}>
      <Building {...iconDimentions} />+
      <Plane {...iconDimentions} />+
      <Bus {...iconDimentions} />+
      <ShieldIcon {...iconDimentions} />
    </Box>
  );
};
