import { ASTANAHUB_LINK } from "@/shared/lib/constants";
import { EContactTypes } from "@/shared/model/types/contacts";

import AstanaHubIcon from "@/public/icons/logos/astanahub.svg";

export const ASTANAHUB_DATA = {
  name: "Astana Hub",
  link: ASTANAHUB_LINK,
  icon: <AstanaHubIcon />,
};

export const CUSTOMERS_LINKS = [
  {
    link: "/pravila",
    name: "BOOKING_RULES",
  },
  {
    link: "/platezhi",
    name: "ONLINE_PAYMENT",
  },
  {
    name: "FAQ",
    link: "/voprosy",
  },
];

export const ABOUT_LINKS = [
  {
    link: "/kontakty",
    name: "ADDRESSES_CONTACTS",
  },
  {
    name: "PARTNERS",
    link: "/partnery",
  },
];

export const AGREEMENT_LINKS = {
  userAgreement: {
    name: "USER_AGREEMENT",
    link: "/polzovatelskoe-soglashenie",
  },
  privacyPolicy: {
    name: "PRIVACY_POLICY",
    link: "/politika-konfidenczialnosti",
  },
};

export const PAYMENT_SYSTEMS_IMAGES = [
  {
    width: 44,
    height: 14,
    imageSource: "/img/payment-systems/visa-logo.svg",
  },
  {
    width: 32.5,
    height: 25.3,
    imageSource: "/img/payment-systems/master-card-logo.svg",
  },
];

export const SOCIAL_LINK_OPTIONS = [
  EContactTypes.Instagram,
  EContactTypes.Facebook,
  EContactTypes.Telegram,
  EContactTypes.Youtube,
];
