export const styles = {
  hotelPage_info_list: {
    marginBottom: "12px",

    "@media screen and (max-width: 980px)": {
      marginBottom: "0px",
    },
    ul: {
      gap: "6px",
      display: "flex",
      flexWrap: "wrap",
      li: {
        height: "24px",
        display: "flex",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "16px",
        padding: "3px 6px",
        borderRadius: "4px",
        alignItems: "center",
        letterSpacing: "0.2px",
        color: "accent.brand.dark",
        backgroundColor: "accent.brand.lightest",

        "@media screen and (max-width: 980px)": {
          fontSize: "12px",
          lineHeight: "14px",
        },
      },
    },
  },
};
