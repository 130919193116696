import { FC } from "react";

import { Box, Button } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { AdaptiveDrawer } from "@/shared/ui";

type TProps = {
  okText?: string;
  isOpen: boolean;
  isPhone?: boolean;
  onOpen: () => void;
  cancelText?: string;
  onClose: () => void;
  Footer?: JSX.Element;
  Content: JSX.Element;
  callback: () => void;
};

export const SimpleActionModal: FC<TProps> = ({
  Footer,
  onOpen,
  isOpen,
  Content,
  isPhone,
  onClose,
  callback,
  okText = "YES",
  cancelText = "NO",
}) => {
  const t = useTranslations();

  const handleConfirm = () => {
    callback();
    onClose();
  };

  const CommonFooter = (
    <Box sx={styles.buttonWrapper}>
      <Button fullWidth onClick={onClose} variant="outlined">
        {t(cancelText)}
      </Button>
      <Button fullWidth variant="contained" onClick={handleConfirm}>
        {t(okText)}
      </Button>
    </Box>
  );

  return (
    <AdaptiveDrawer
      title={""}
      onOpen={onOpen}
      isOpen={isOpen}
      isPhone={isPhone}
      Content={Content}
      onClose={onClose}
      desktopWidth={"lg"}
      Footer={Footer ? Footer : CommonFooter}
    />
  );
};
