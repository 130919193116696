import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  amenities: {
    hr: {
      margin: "20px 0",
    },
  },

  mainDetails: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",

    item: {
      gap: "12px",
      display: "flex",
      alignItems: "center",
      color: "content.grey-400",
    },
  },

  tourPackage: {
    details: {
      gap: "12px",
      display: "flex",
      flexDirection: "column",

      item: {
        gap: "12px",
        display: "flex",
        alignItems: "center",
        color: "content.grey-400",
      },
    },

    heading: {
      fontSize: "14px",
      fontWeight: "500",
      marginBottom: "16px",
      textTransform: "uppercase",
      color: "content.secondary",

      [mainTheme.breakpoints.down("md")]: {
        fontSize: "18px",
        textTransform: "none",
        color: "content.primary",
      },
    },
  },

  dates: {
    gap: "12px",
    padding: "12px",
    display: "flex",
    borderRadius: "8px",
    marginBottom: "16px",
    backgroundColor: "dominant.dop",

    weekDay: {
      color: "content.grey-400",
    },

    content: {
      fontSize: 18,
      fontWeight: 500,
    },

    title: {
      fontSize: 16,
      color: "content.secondary",
    },

    date: {
      gap: "8px",
      width: "50%",
      display: "flex",
      flexDirection: "column",
    },

    hr: {
      width: "1px",
      height: "auto",
      alignSelf: "stretch",
      backgroundColor: "primary.whiteblue",
    },
  },
};
