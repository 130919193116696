export const styles = {
  value: {
    fontWeight: 500,
    color: "content.primary",
  },

  label: {
    width: "150px",
    color: "content.grey-400",
  },

  row: {
    display: "flex",
    columnGap: "8px",
    alignItems: "center",
  },

  titleRow: {
    display: "flex",
    columnGap: "12px",
    alignItems: "center",
  },

  title: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: "content.secondary",
  },

  paymentStatusSx: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    padding: "3px 6px",
    borderRadius: "4px",
    letterSpacing: "0.2px",
  },

  paymentTypeSx: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    padding: "3px 6px",
    borderRadius: "4px",
    letterSpacing: "0.2px",
    color: "accent.brand.dark",
    backgroundColor: "accent.brand.lightest",
  },
};
