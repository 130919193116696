import { FC } from "react";

import { Typography } from "@mui/material";

import { styles } from "./styles";

import { Link } from "@/shared/ui";

type TProps = {
  slug: string;
  name: string;
};

export const HotelLink: FC<TProps> = ({ slug, name }) => {
  return (
    <Link href={`/oteli/${slug}`}>
      <Typography variant="h2" sx={styles.title}>
        {name}
      </Typography>
    </Link>
  );
};
