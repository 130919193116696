import { attach } from "effector";

import { TFFRequest } from "../../types/common";
import {
  TOrderPaymentData,
  TOrderResponseData,
  TTouristContractData,
  TOrderPaymentDetails,
  TOrderPaymentTypesData,
  TSendCreditHistoryResponse,
} from "../../types/orders";
import { TOrderData } from "../../types/tours";

import { requestFx } from "@/shared/config";

type TOrderResponse = TFFRequest<TOrderResponseData>;
type TTouristContractResponse = TFFRequest<TTouristContractData>;
type TGetOrderPaymentTypesResponse = TFFRequest<TOrderPaymentTypesData>;
type TGetOrderPaymentDataResonse = TFFRequest<TOrderPaymentData>;
type TGetOrderPaymentDetailsResponse = TFFRequest<TOrderPaymentDetails[]>;

export type TEditOrderPayload = {
  orderDetailsId: string;
  data: Partial<TOrderData>;
};

export type TFixAvailableCashAmountPayload = {
  amount: number;
  orderNumber: string;
};

type TPostBuyerPayload = {
  iin: string;
  orderNumber: string;
  phone_number: string;
};

export const getOrderDetailsFx = attach<string, TOrderResponse>({
  effect: requestFx,
  mapParams: (orderNumber) => ({
    url: `orders/${orderNumber}`,
  }),
});

export const getTouristContractFx = attach<string, TTouristContractResponse>({
  effect: requestFx,
  mapParams: (orderNumber) => ({
    url: `orders/${orderNumber}/travel-contract`,
  }),
});

export const getActualizeOrderFx = attach<string, TOrderResponse>({
  effect: requestFx,
  mapParams: (orderNumber) => ({
    url: `orders/${orderNumber}/actualize`,
  }),
});
export const getOrderPaymentTypesFx = attach<
  string,
  TGetOrderPaymentTypesResponse
>({
  effect: requestFx,
  mapParams: (orderNumber) => ({
    url: `orders/${orderNumber}/payment-types`,
  }),
});

export const editOrderFx = attach<TEditOrderPayload, TOrderResponse>({
  effect: requestFx,
  mapParams: ({ data, orderDetailsId }) => ({
    data,
    method: "post",
    url: `tours/orders/${orderDetailsId}`,
  }),
});

export const gerOrderPaymentDataFx = attach<
  { amount?: number; orderNumber: string },
  TGetOrderPaymentDataResonse
>({
  effect: requestFx,
  mapParams: ({ amount, orderNumber }) => ({
    params: {
      amount,
    },
    url: `orders/${orderNumber}/payment-data`,
  }),
});

export const postAvailableCashAmountFx = attach<
  TFixAvailableCashAmountPayload,
  TOrderResponse
>({
  effect: requestFx,
  mapParams: ({ amount, orderNumber }) => ({
    method: "post",
    data: {
      cash_amount: amount,
    },
    url: `orders/${orderNumber}/set-cash-amount`,
  }),
});

export const bookWithManagerFx = attach<void, TOrderResponse>({
  effect: requestFx,
  mapParams: () => ({
    method: "post",
    url: "tours/book-with-manager",
  }),
});

export const cancelBookingFx = attach<string, TOrderResponse>({
  effect: requestFx,
  mapParams: (orderNumber) => ({
    method: "post",
    url: `orders/${orderNumber}/cancel`,
  }),
});

export const createOrderFx = attach<TOrderData, TOrderResponse>({
  effect: requestFx,
  mapParams: (data) => ({
    data,
    method: "post",
    url: "tours/orders",
  }),
});

export const getOrderPaymentsDetailsFx = attach<
  string,
  TGetOrderPaymentDetailsResponse
>({
  effect: requestFx,
  mapParams: (orderNumber) => ({
    url: `orders/${orderNumber}/payments`,
  }),
});

export const getOrdersFx = attach<string, TOrderResponse>({
  effect: requestFx,
  mapParams: (code) => ({
    url: "orders",
    params: {
      "filter[order_status]": code,
    },
  }),
});

export const postCancelOrderFx = attach<string, TFFRequest<void>>({
  effect: requestFx,
  mapParams: (orderNumber) => ({
    method: "post",
    url: `orders/${orderNumber}/cancel`,
  }),
});

export const postUserCreditHistoryFx = attach<
  FormData,
  TFFRequest<TSendCreditHistoryResponse>
>({
  effect: requestFx,
  mapParams: (data) => ({
    data,
    method: "post",
    url: `banks/statements`,
  }),
});

export const deleteUserCreditHistoryFx = attach<string, TFFRequest<void>>({
  effect: requestFx,
  mapParams: (fileId) => ({
    method: "delete",
    url: `banks/statements/${fileId}`,
  }),
});

export const postPayerInfoFx = attach<TPostBuyerPayload, TFFRequest<void>>({
  effect: requestFx,
  mapParams: ({ iin, orderNumber, phone_number }) => ({
    method: "post",
    url: `orders/${orderNumber}/payer-info`,
    data: {
      iin,
      phone_number,
      third_party_payment: true,
    },
  }),
});

export const postPassengerAsPayerFx = attach<string, TFFRequest<void>>({
  effect: requestFx,
  mapParams: (orderNumber) => ({
    method: "post",
    url: `orders/${orderNumber}/payer-info`,
    data: {
      third_party_payment: false,
    },
  }),
});
