import { useMediaQuery } from "@mui/material";

import { useCustomTheme } from "./useCustomTheme";

import { ESearchType } from "@/shared/model/types/search";

type TProps = {
  type: ESearchType;
};

export const useSearchBreakpoints = ({ type }: TProps) => {
  const theme = useCustomTheme();

  const isPhone = useMediaQuery(
    theme.breakpoints.down(type === ESearchType.Main ? "searchMobile" : "smd"),
  );

  const isDesktop = useMediaQuery(
    theme.breakpoints.up(type === ESearchType.Main ? "searchDesktop" : "smd"),
  );

  const isTablet = useMediaQuery(
    theme.breakpoints.between(
      type === ESearchType.Main ? "searchMobile" : "smd",
      type === ESearchType.Main ? "searchDesktop" : "md",
    ),
  );

  return { isPhone, isTablet, isDesktop };
};
