export const styles = {
  mobilePlayIcon: {
    svg: {
      width: "30px",
      height: "30px",
    },
  },
  frame: {
    border: 0,
    width: "0",
    height: "100%",
    borderRadius: "16px",
  },
};
