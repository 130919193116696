export const styles = {
  baseAvatar: {
    padding: "6px",
  },

  nonClickable: {
    cursor: "default",
  },

  avatarSx: {
    width: "100%",
    height: "100%",
  },

  lettersSx: {
    color: "content.grey-400",
    backgroundColor: "transparent",
  },

  clickable: {
    cursor: "pointer",
    transition: "0.2s",
    "&:hover": {
      opacity: "0.7",
    },
  },

  avatar: {
    display: "flex",
    flex: "0 0 auto",
    cursor: "pointer",
    overflow: "hidden",
    border: "1px solid",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
    color: "content.grey-400",
    borderColor: "primary.whiteblue",
    backgroundColor: "primary.whiteblue",
  },
};
