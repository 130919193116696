export const DATA_LAYER_EVENT_NAMES = {
  headerVisa: "Виза",
  call: "Заказ звонка",
  signInSuccess: "Вход",
  getCode: "Получить код",
  searchTour: "Подбор тура",
  noToursFound: "Нет туров",
  aboutCompany: "О компании",
  roomChoise: "Выбор комнаты",
  choiseFlight: "Выбор рейса",
  signUpSuccess: "Регистрация",
  purchaseTour: "Покупка тура",
  noFlightsFound: "Нет рейсов",
  bookTour: "Забронировать тур",
  onlinePayment: "Онлайн оплата",
  paymentFailed: "Ошибка оплаты",
  relatedDates: "Доступные даты",
  officePayment: "Оплата в офисе",
  specialActions: "Клик на акцию",
  navMenuClick: "Переход по меню",
  partnerClick: "Клик по партнеру",
  paymentLoan: "Оплата в рассрочку",
  loginClick: "Клик по кнопке Войти",
  crossingYoutube: "Переход на ютуб",
  whatsApp: "Клик по номеру WhatsApp",
  signContract: "Подписание договора",
  sortingByCheap: "Сортировка по цене",
  appDownload: "Скачивание приложения",
  promocodeSubmit: "Отправка промокода",
  paymentMethod: "Выбор способа оплаты",
  crossingFacebook: "Переход на фейсбук",
  navTourmenuClick: "Клик по меню туров",
  companyDetailClick: "Клик по компании",
  bannerTourClick: "Клик по баннеру тура",
  bookingSuccess: "Успешное бронирование",
  fillLeadForm: "Подбор тура (лид форма)",
  crossingTelegram: "Переход на телеграм",
  downloadContract: "Скачивание договора",
  filtrFaqClick: "Клик по фильтрам в FAQ",
  startOnboarding: "Онбординг на главной",
  sortingByExpensive: "Сортировка по цене",
  bayersDetailClick: "Клик по покупателям",
  allFeedback: "Клик по кнопке Все отзывы",
  toursByDestination: "Туры по направлению",
  toCorporatePersons: "Корпоративным лицам",
  sortingByRating: "Сортировка по рейтингу",
  crossingInstagram: "Переход на инстаграм",
  findTourClick: "Клик по кнопке Найти тур",
  massmediiaClick: "Переход на страницу СМИ",
  bookManager: "Забронировать тур (менеджер)",
  tourfiltrClick: "Клик по фильтрам в поиске",
  locationClick: "Выбор локации пользователя",
  viewAlltourClick: "Клик по кнопке Все туры",
  headerContactsAndOffices: "Контакты и офисы",
  popupPromocode: "Клик по промокоду в попапе",
  showmoreClick: "Клик по кнопке Показать еще",
  aboutRoomClick: "Клик по информации о номере",
  allServicesClick: "Клик по кнопке Все услуги",
  sortingByPopular: "Сортировка по популярности",
  adressFiltrClick: "Клик по фильтрам в адресах",
  partnerformSubmit: "Отправка формы партнерства",
  paymentMethodClick: "Клик на выбор способа оплаты",
  forClientDropDownMenu: "Клиентам (выпадающее меню)",
  hotToursDropDownMenu: "Горящие туры (выпадающее меню)",
  filtrSelecttourClick: "Клик по фильтрам в выборе тура",
  clickConstructorArticle: "Клик по статье конструктора",
  searchOnboarding: "Онбординг на странице поиска туров",
  clickOnTheHotToursBanner: "Клик по баннеру горящих туров",
  constructorNavigation: "Навигация по странице из конструктора",
} as const;
