import { FC } from "react";

import { styled } from "@mui/material/styles";
import { TooltipProps } from "@mui/material/Tooltip";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import { TCustomTheme } from "@/shared/ui/styles/theme/types";

type TCustomTooltip = TooltipProps & {
  className?: string;
  theme?: TCustomTheme;
};

export const CustomTooltip: FC<TCustomTooltip> = styled(
  ({ className, ...props }: TCustomTooltip) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme }: { theme: TCustomTheme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.content.primary,
    "&:before": {
      borderRadius: "0 0 2px 0",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "12px",
    fontSize: "12px",
    lineHeight: "16px",
    backgroundColor: theme.palette.content.primary,
  },
}));
