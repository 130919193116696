export const styles = {
  progressBar: {
    wrapper: {
      marginBottom: "16px",
    },
    textBox: {
      fontSize: "22px",
      marginTop: "16px",
      textAlign: "center",
      marginBottom: "16px",
      color: "content.secondary",
    },
    textBoxMobile: {
      marginTop: "4px",
      fontSize: "12px",
      textAlign: "center",
      marginBottom: "16px",
      color: "content.secondary",
    },
  },
};
