import { FC } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

import {
  EPreviewType,
  getCroppedPreview,
} from "@/shared/lib/helpers/getCroppedPreview";
import { useCustomTheme } from "@/shared/lib/hooks";
import { TContructorPageResponse } from "@/shared/model/types/constructor-parser";
import { Link, Image } from "@/shared/ui";

type TProps = {
  onClick?: () => void;
  page: TContructorPageResponse;
};

export const ConstructorCard: FC<TProps> = ({ page, onClick }) => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  const image = getCroppedPreview({
    isPhone,
    preview: page.preview,
    type: EPreviewType.MainPreview,
    croppedPreviews: page.previews,
  });

  return (
    <Link href={`/s/${page.url}`} style={styles.linkWrapper}>
      <Box onClick={onClick} sx={styles.wrapper}>
        <Box sx={styles.imageWrapper}>
          <Image
            src={image}
            rounded="lg"
            alt={page.name}
            height={isPhone ? 164 : 310}
            imageWrapperSx={styles.image}
          />

          <Box sx={styles.textWrapper}>
            <Typography sx={styles.title}>{page.name}</Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
