export enum EBackendTourTypes {
  AllTours = "all_tours",
  HotTours = "hot_tours",
}

export enum EMenuTab {
  Main = "main",
  Links = "links",
  HotTours = "hotTours",
  AllTours = "allTours",
  Departures = "departures",
}
