import { FC } from "react";

import * as CSS from "csstype";

import { styles } from "./styles";

import AboutIcon from "@/public/icons/amenities/card/about.svg";
import BeachIcon from "@/public/icons/amenities/card/beach.svg";
import ChildrenIcon from "@/public/icons/amenities/card/children.svg";
import CheckIcon from "@/public/icons/amenities/card/default.svg";
import FoodIcon from "@/public/icons/amenities/card/food.svg";
import HealthIcon from "@/public/icons/amenities/card/health.svg";
import LocationIcon from "@/public/icons/amenities/card/location.svg";
import SiteIcon from "@/public/icons/amenities/card/site.svg";

type TAmenityIcon = {
  code?: string;
  iconStyles?: CSS.Properties;
};

export const AmenityIcon: FC<TAmenityIcon> = ({ code, iconStyles = {} }) => {
  const iconSettings = {
    fontSize: "small",
    style: { ...styles.icon, ...iconStyles },
  };

  switch (code) {
    case "about":
      return <AboutIcon {...iconSettings} />;
    case "location":
      return <LocationIcon {...iconSettings} />;
    case "food":
      return <FoodIcon {...iconSettings} />;
    case "children":
      return <ChildrenIcon {...iconSettings} />;
    case "beach":
      return <BeachIcon {...iconSettings} />;
    case "site":
      return <SiteIcon {...iconSettings} />;
    case "health":
      return <HealthIcon {...iconSettings} />;
    default:
      return <CheckIcon {...iconSettings} />;
  }
};
