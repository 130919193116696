import { styled } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";

import { mainTheme } from "../styles";

export const MuiOtpInputStyled = styled(MuiOtpInput)(() => ({
  gap: "14px",
  display: "flex",
  justifyContent: "space-between",

  ".MuiOtpInput-TextField": {
    border: "none",
    outline: "none",
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "38px",

    ".MuiInputBase-root": {
      maxWidth: "75px",
      maxHeight: "66px",
      borderRadius: "12px",
      fieldset: {
        display: "none",
      },

      backgroundColor: mainTheme.palette.dominant.dop,
    },
  },
}));
