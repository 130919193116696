import { memo } from "react";

import { Button, styled } from "@mui/material";
import { COLORS } from "shared/ui/styles";

import type { TCustomTheme } from "@/shared/ui/styles/theme/types";

type TListItem = {
  selected?: boolean;
  disabled?: boolean;
  theme?: TCustomTheme;
};

type TArrivalItem = {
  selected: boolean;
  theme: TCustomTheme;
};

export const List = memo(
  styled("ul")(() => ({
    margin: 0,
    rowGap: "8px",
    display: "flex",
    padding: "16px",
    overflow: "hidden",
    listStyleType: "none",
    flexDirection: "column",
    backgroundColor: COLORS.WHITE,
  })),
);

export const ListLabel = memo(
  styled("p")(({ theme }: { theme: TCustomTheme }) => {
    return {
      margin: 0,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      color: theme.palette.grey.light,
    };
  }),
);

export const ListItem = styled("li")(({
  theme,
  disabled,
  selected,
}: TListItem) => {
  return {
    cursor: "pointer",
    borderRadius: "4px",
    padding: "6.5px 13px",
    pointerEvents: disabled ? "none" : "auto",

    transition: "color .3s ease, background-color .3s ease",

    backgroundColor: selected ? theme?.palette.primary.whiteblue : "unset",
    "&:hover": {
      backgroundColor: theme?.palette.primary.whiteblue,
    },

    "&:focus-visible": {
      outline: "1px solid",
      outlineColor: "primary.main",
    },

    color: disabled
      ? theme?.palette.grey.extralight
      : selected
        ? theme?.palette.primary.main
        : theme?.palette.grey.dark,
  };
});

export const ArrivalItem = styled(Button)(({
  theme,
  selected,
}: TArrivalItem) => {
  const { accent, content, dominant } = theme.palette;

  return {
    width: "100%",
    padding: "4px",
    display: "flex",
    height: "unset",
    cursor: "pointer",
    columnGap: "12px",
    textAlign: "start",
    border: "1px solid",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: dominant.white,
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
      opacity: "100",
    },
    color: selected ? accent.brand.primary : content.primary,
    borderColor: selected ? accent.brand.primary : "transparent",

    transition: "border-color .150s ease, background-color .3s ease",

    "&:not(:disabled):hover": {
      backgroundColor: "background.default",
    },

    "&:disabled": {
      cursor: "default",
      "& p": {
        color: content["grey-200"],
      },
      "& img": {
        filter: "grayscale(0.9) opacity(0.95)",
      },
    },
  };
});
