export const CORRECT_DATE_ERROR_TEXT = "ENTER_CORRECT_DATE";

export const CORRECT_EMAIL_ERROR_TEXT = "ENTER_CORRECT_EMAIL";

export const CORRECT_PHONE_ERROR_TEXT = "INCORRECT_NUMBER_ENTERED";

export const LATIN_ALPHABET_ERROR_TEXT = "USE_LATIN_ALPHABET_CHARACTERS";

export const DEFAULT_ERROR = "AN_ERROR_OCCURRED";

export const REQUIRED_FIELD_ERROR = "FILL_REQUIRED_FIELD";

export const STATIC_ERROR_TEXTS = {
  kz: {
    title: "Қате 500",
    linkText: "Басты бетке өту",
    subtitle: "Ішкі сервер қатесі",
    text: "Өкінішке орай, серверде күтпеген қате пайда болды, ол жақын арада түзетіледі",
  },
  ru: {
    title: "Ошибка 500",
    linkText: "Перейти на главную",
    subtitle: "Внутренняя ошибка сервера",
    text: "К сожалению, на сервере произошла непредвиденная ошибка, она скоро будет исправлена. Пожалуйста, подождите и попробуйте перейти на главную страницу",
  },
};
