import { Stepper, StepIcon, StepLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLORS } from "shared/ui/styles";

import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const ProgressStepperWrapper = styled(Stepper)(() => ({
  width: "100%",
  margin: "24px 0 36px",
  [mainTheme.breakpoints.down("md")]: {
    padding: "12px 16px",
    borderStyle: "solid",
    margin: "0 -16px 24px",
    borderWidth: "1px 0 1px 0",
    width: "calc(100% + 32px)",
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.WHITE_GRAY,
  },
}));

export const StyledStepper = styled(Stepper)(() => ({
  width: "100%",

  "& .Mui-active": {
    borderColor: COLORS.BLUE,

    "& span": {
      borderColor: `${COLORS.BLUE} !important`,
    },
  },

  "& .MuiStep-root": {
    padding: "0 16px",
    "&:last-of-type": {
      paddingRight: 0,
    },
    "&:first-of-type": {
      paddingLeft: 0,
    },
    [mainTheme.breakpoints.down("md")]: {
      padding: "0 4px",
      "& .MuiStepLabel-iconContainer": {
        padding: 0,
      },
    },
  },

  "& .MuiStepConnector-root": {
    "& .MuiStepConnector-line": {
      borderTopWidth: 2,
      borderColor: COLORS.WHITE_GRAY,
    },
    "& .Mui-active": {
      "& .MuiStepConnector-line": {
        borderColor: COLORS.BLUE,
      },
    },
    "&.Mui-completed": {
      "& .MuiStepConnector-line": {
        borderColor: COLORS.BLUE,
      },
    },
  },
}));

export const StyledStepLabel = styled(StepLabel)(() => ({
  "& .MuiStepLabel-label": {
    padding: "0 8px",
    "&.Mui-completed": {
      fontWeight: 400,
    },
    "&.Mui-disabled": {
      color: COLORS.LIGHT_GRAY,
    },
    [mainTheme.breakpoints.down("md")]: {
      display: "none",
    },
    "&.Mui-active": {
      [mainTheme.breakpoints.down("md")]: {
        display: "block",
      },
    },
  },
}));

export const StyledStepIcon = styled(StepIcon)(() => ({
  width: 32,
  height: 32,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  color: "transparent",
  border: `1px solid ${COLORS.LIGHT_GRAY}`,

  "& .MuiStepIcon-text": {
    fill: COLORS.LIGHT_GRAY,
  },

  [mainTheme.breakpoints.down("md")]: {
    width: 28,
    height: 28,
  },
  "&.Mui-completed": {
    border: 0,
    color: COLORS.BLUE,
    "& .MuiStepIcon-text": {
      fill: COLORS.WHITE,
    },
  },
  "&.Mui-active": {
    border: 0,

    marginLeft: 4,
    color: COLORS.BLUE,
    borderRadius: "50%",
    boxShadow: "0px 0px 0px 4px rgba(29, 133, 255, 0.2)",
    "& .MuiStepIcon-text": {
      fill: COLORS.WHITE,
    },
  },
}));
