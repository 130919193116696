export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum SortBy {
  PRICE = "price",
  POPULARITY = "popularity",
  HOTEL_RATING = "hotel_rating",
}

export type TSelectedSort = {
  sort_by: SortBy;
  sort_order: SortOrder;
};
