import { mainTheme } from "shared/ui/styles";

const pxToRem = mainTheme.typography.pxToRem;

export const styles = {
  counter: {
    fontWeight: 400,
    display: "flex",
    width: pxToRem(24),
    height: pxToRem(24),
    alignItems: "center",
    fontSize: pxToRem(12),
    color: "dominant.white",
    marginRight: pxToRem(8),
    borderRadius: pxToRem(6),
    justifyContent: "center",
    backgroundColor: "accent.brand.primary",
  },
};
