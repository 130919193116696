import { attach } from "effector";

import {
  TBonusesAmount,
  TTransactionBonusItemResponse,
} from "../../types/bonunes";
import { TFFRequest } from "../../types/common";

import { requestFx } from "@/shared/config";

type TBonusesAmountResponse = TFFRequest<TBonusesAmount>;
type TBonusesTransactionHistoryResponse = TFFRequest<
  TTransactionBonusItemResponse[]
>;

export const getBonusesAmountFx = attach<undefined, TBonusesAmountResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: `bonuses/current-amount`,
  }),
});

export const getBonusesTransactionHistoryFx = attach<
  undefined,
  TBonusesTransactionHistoryResponse
>({
  effect: requestFx,
  mapParams: () => ({
    url: `bonuses/transaction-history`,
  }),
});
