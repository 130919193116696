import { FC, memo, useMemo, ElementType } from "react";

import { Box, SxProps, Typography } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

type TProps = {
  sx?: SxProps;
  textSx?: SxProps;
  icon?: ElementType;
  iconSize?: string | number;
  children?: string | JSX.Element | JSX.Element[];
};

export const AmenityBadge: FC<TProps> = memo(
  ({ icon, sx = {}, children, textSx = {}, iconSize = "16px" }: TProps) => {
    const theme = useCustomTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const computedIconStyles = useMemo(
      () => ({
        display: "flex",
        fill: "transparent",
        fontSize: isDesktop ? iconSize : "14px",

        "& svg": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      }),
      [iconSize, isDesktop],
    );

    return (
      <Box sx={sx} columnGap="2px" alignItems="center" display="inline-flex">
        {icon && (
          <SvgIcon inheritViewBox component={icon} sx={computedIconStyles} />
        )}
        <Typography
          sx={textSx}
          variant="caption"
          fontSize={isDesktop ? 14 : 12}
          display={!isDesktop ? "ruby" : ""}
        >
          {children}
        </Typography>
      </Box>
    );
  },
);
