import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { $contactPhones } from "@/entities/footer/model";

type TProps = {
  isError: boolean;
  isPending: boolean;
};

export const BookingStep: FC<TProps> = ({ isError, isPending }) => {
  const t = useTranslations();
  const phoneData = useUnit($contactPhones)?.[0];

  if (isPending) {
    return (
      <Box sx={styles.wrapper}>
        <Typography sx={styles.textMargin}>
          {t("WE_WILL_CONFIRM_YOUR_ORDER_WITHIN_4_HOURS")}
        </Typography>
        <Typography>
          {t("IF_THERE_ARE_NO_AVAILABLE_TOURS_FOR_YOUR_ORDER")}
        </Typography>
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={styles.wrapper}>
        <Typography sx={styles.textMargin}>
          {t("FAILED_TO_CONFIRM_YOUR_ORDER")}{" "}
          {t("OUR_MANAGER_WILL_CONTACT_YOU_SOON_AND_OFFER_ALTERNATIVE_OPTIONS")}
        </Typography>
        <Typography sx={styles.textMargin}>{t("MANAGER")}:</Typography>
        <Typography sx={styles.phoneLink}>
          <a href={`tel:${phoneData?.value ?? ""}`}>{phoneData?.value}</a>
        </Typography>
      </Box>
    );
  }

  return null;
};
