import { ElementType } from "react";

import { EAmenityTypes } from "../constants";

import Aquapark from "@/public/icons/amenities/badge/aquapark.svg";
import Beach from "@/public/icons/amenities/badge/beach.svg";
import Bed from "@/public/icons/amenities/badge/bed.svg";
import Bus from "@/public/icons/amenities/badge/bus.svg";
import Family from "@/public/icons/amenities/badge/family.svg";
import HeatingPool from "@/public/icons/amenities/badge/heated_pool.svg";
import Nightlife from "@/public/icons/amenities/badge/night_life.svg";
import Plane from "@/public/icons/amenities/badge/plane.svg";
import Restaurant from "@/public/icons/amenities/badge/restaurant.svg";
import Shield from "@/public/icons/amenities/badge/shield.svg";
import Sleep from "@/public/icons/amenities/badge/sleep.svg";
import SportGround from "@/public/icons/amenities/badge/sport.svg";
import SwimmingPool from "@/public/icons/amenities/badge/swimming_pool.svg";
import UltraAllInclusive from "@/public/icons/amenities/badge/ultra.svg";
import Villa from "@/public/icons/amenities/badge/villa.svg";
import Waterslides from "@/public/icons/amenities/badge/waterslides.svg";
import Wifi from "@/public/icons/amenities/badge/wifi.svg";
import Young from "@/public/icons/amenities/badge/young.svg";
import Check from "@/public/icons/amenities/card/default.svg";

export const amenitiesRenderer = (code: string) => {
  switch (code) {
    case "young":
      return Young as ElementType;
    case "villa":
      return Villa as ElementType;
    case "wi-fi":
      return Wifi as ElementType;
    case "family":
      return Family as ElementType;
    case "aquapark":
      return Aquapark as ElementType;
    case "nightlife":
      return Nightlife as ElementType;
    case "uai":
      return UltraAllInclusive as ElementType;
    case "heated_pool":
      return HeatingPool as ElementType;
    case "waterslides":
      return Waterslides as ElementType;
    case "restoran":
      return Restaurant as ElementType;
    case "sport_ground":
      return SportGround as ElementType;
    case "dayving":
      return SwimmingPool as ElementType;
    case "chastnyy-plyazh":
    case "peschanyiy-plyazh":
      return Beach as ElementType;
    default:
      return Check as ElementType;
  }
};

export const sideBarIconsRenderer = (code: EAmenityTypes) => {
  switch (code) {
    case EAmenityTypes.duration:
      return {
        text: "DURATION",
        icon: Sleep as ElementType,
      };
    case EAmenityTypes.passengers:
      return {
        text: "TOURISTS",
        icon: Family as ElementType,
      };
    case EAmenityTypes.room:
      return {
        text: "NUMBER",
        icon: Bed as ElementType,
      };
    case EAmenityTypes.meal:
      return {
        text: "MEALS",
        icon: Restaurant as ElementType,
      };
    case EAmenityTypes.transfer:
      return {
        text: "TRANSFER",
        icon: Bus as ElementType,
      };
    case EAmenityTypes.insurance:
      return {
        text: "INSURANCE",
        icon: Shield as ElementType,
      };
    default:
      return {
        text: "FLIGHT",
        icon: Plane as ElementType,
      };
  }
};
