export const styles = {
  arrowRight: {
    margin: "0 4px",
  },

  link: {
    textDecoration: "none",
  },

  backIcon: {
    marginRight: "11px",
  },

  defaultWrapperSx: {
    margin: "16px 0",
  },

  breadcrumb: {
    color: "grey.dark",
    alignItems: "center",
    textDecoration: "none",
    display: "inline-flex",
  },

  clickableBreadcrumb: {
    cursor: "pointer",
    transition: "0.2s",
    alignItems: "center",

    color: "grey.content",
    display: "inline-flex",
    textDecoration: "none",

    "&:hover": {
      color: "primary.main",
      textDecoration: "underline",
    },
  },
};
