import { memo, useRef, useMemo, forwardRef, ChangeEvent } from "react";

import { Box, SxProps, InputBase } from "@mui/material";
import cm from "classnames";

import { styles } from "./styles";
import type { TInput } from "./types";

import { Popper } from "@/shared/ui";

const InputComponent = forwardRef<HTMLInputElement, TInput>(
  function Input(props, ref) {
    const {
      label = "",
      value = "",
      endAdornment,
      sxInput = {},
      type = "text",
      transformValue,
      startAdornment,
      sxWrapper = {},
      wrapperId = "",
      inputProps = {},
      isError = false,
      focused = false,
      disabled = false,
      popperStyles = {},
      inputWrapper = {},
      popperOptions = {},
      showPopper = false,
      showFocusRing = true,
      onChange = () => null,
      sxPopperInsideBox = {},
      popperContent = () => null,
      ...otherProps
    } = props;

    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleInputChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      if (transformValue) {
        onChange(transformValue(e.target.value));
        return;
      }

      onChange(e.target.value);
    };

    const wrapperStyle = useMemo(
      () => ({ ...styles.inputWrapper, ...inputWrapper }),
      [inputWrapper],
    );
    const inputWrapperSx = useMemo(
      () => ({
        ...(styles.wrapper as SxProps),
        // TODO: rewrite with TS
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        ...(value?.length === 0 ? styles.inputEmpty : {}),
        ...sxWrapper,
      }),
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      [sxWrapper, value?.length],
    );

    const inputPropsWithStyles = useMemo(
      () => ({
        spellCheck: false,
        sx: {
          ...styles.input,
          ...sxInput,
        },
        ...inputProps,
      }),
      [inputProps, sxInput],
    );
    const computedClasses = useMemo(
      () => cm({ error: isError, active: focused, ring: showFocusRing }),
      [focused, isError, showFocusRing],
    );

    return (
      <Box id={wrapperId} ref={wrapperRef} sx={wrapperStyle as SxProps}>
        {label && <label>{label}</label>}
        <InputBase
          type={type}
          value={value}
          inputRef={ref}
          disabled={disabled}
          endAdornment={endAdornment}
          className={computedClasses}
          sx={inputWrapperSx as SxProps}
          startAdornment={startAdornment}
          inputProps={inputPropsWithStyles}
          {...otherProps}
          onChange={handleInputChange}
        />
        <Popper
          placement="top"
          open={showPopper}
          anchorEl={wrapperRef.current}
          style={{ width: wrapperRef.current?.clientWidth, ...popperStyles }}
          {...popperOptions}
          sx={sxPopperInsideBox}
        >
          {popperContent}
        </Popper>
      </Box>
    );
  },
);

export const Input = memo(InputComponent);
