import { EOrderStatus, EOrderPayment } from "@/shared/lib/constants/orders";

export enum EDetailsSteps {
  PAYMENT = 0,
  BOOKING = 1,
  CANCELLATION = 2,
}

export const PASSENGERS_TEXTS = {
  linkText: "PROVIDE_TOURIST_DETAILS",
  hint: "PLEASE_SPECIFY_TOURIST_DETAILS_TO_CONTINUE_BOOKING",
};

export const PAYMENT_TEXTS = {
  linkText: "PROCEED_TO_PAYMENT",
  hint: "PAY_FOR_YOUR_ORDER_WITHIN_HOURS_OR_CONTACT_A_MANAGER_FOR_HELP",
};

export const PAYMENT_CANCEL_STATUSES = [
  EOrderPayment.Refunded,
  EOrderPayment.RefundPending,
];

export const ORDER_STATUSES_FOR_PAYMENT_STEP = [
  EOrderStatus.Created,
  EOrderStatus.Confirmed,
  EOrderStatus.ConfirmationPending,
];
