import { useRouter } from "next/navigation";
import { FC } from "react";

import { ArrowBack, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  Link as MaterialLink,
} from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

type TProps = {
  wrapperSx?: SxProps;
  isBackLink?: boolean;
  breadcrumbs: {
    href: string;
    title: string;
  }[];
};

export const Breadcrumbs: FC<TProps> = ({
  breadcrumbs,
  isBackLink = true,
  wrapperSx = styles.defaultWrapperSx,
}) => {
  const t = useTranslations();
  const router = useRouter();
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleRouteChange = (href: string) => {
    router.push(href);
  };

  if (isDesktop) {
    return (
      <Box sx={wrapperSx}>
        {breadcrumbs.map((item, index) => {
          const isCurrentRoute = index === breadcrumbs.length - 1;

          if (isCurrentRoute) {
            return (
              <Typography key={index} sx={styles.breadcrumb}>
                {t(item.title)}
              </Typography>
            );
          }

          return (
            <Typography
              key={index}
              sx={styles.clickableBreadcrumb}
              onClick={() => handleRouteChange(item.href)}
            >
              {t(item.title)}
              <KeyboardArrowRight fontSize="small" sx={styles.arrowRight} />
            </Typography>
          );
        })}
      </Box>
    );
  }

  if (isBackLink) {
    return (
      <MaterialLink sx={styles.link} onClick={() => router.back()}>
        <p>
          <ArrowBack style={styles.backIcon} />
          {t("BACK_2")}
        </p>
      </MaterialLink>
    );
  }

  return null;
};
