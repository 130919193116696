import { FC } from "react";

import { Error } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { styles } from "./styles";

type TProps = {
  text: string;
};

export const SimpleActionContent: FC<TProps> = ({ text }) => {
  return (
    <Box sx={styles.confirmationContent.wrapper}>
      <Error color="warning" sx={{ fontSize: "64px" }} />
      <Typography sx={styles.confirmationContent.text}>{text}</Typography>
    </Box>
  );
};
