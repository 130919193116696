import { mainTheme } from "shared/ui/styles";

export const styles = {
  redirectButton: {
    opacity: "1",
    display: "flex",
    transition: "0.2s",
    borderRadius: "8px",
    width: "fit-content",
    padding: "12px 16px",
    justifyContent: "center",
    backgroundColor: "accent.brand.primary",
    "&:hover": {
      opacity: "0.7",
      cursor: "pointer",
    },
    "& a": {
      textDecoration: "none",
      color: "dominant.white",
    },
    [mainTheme.breakpoints.down("sm")]: {
      width: "100%",
      "&:hover": {
        opacity: "1",
      },
    },
  },
};
