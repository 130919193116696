import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";
import type { TDeferredPayment } from "./types";

import { getPriceWithCurrency } from "@/shared/lib/helpers";
import { Badge, CustomTooltip } from "@/shared/ui";

import InfoIcon from "@/public/icons/common/info.svg";

export const DeferredPayment: FC<TDeferredPayment> = ({
  onClick,
  currency,
  onMouseUp,
  title = "",
  onMouseDown,
  onMouseLeave,
  onMouseEnter,
  open = false,
  paymentPerMonth,
  placement = "top",
}) => {
  const t = useTranslations();

  return (
    <Box sx={styles.deferredPaymentBox}>
      <Badge size="large" sx={styles.deferredPaymentBox.deferredPaymentBadge}>
        {t("PRICE_FROM_MONTHS_SHORT", {
          amount: getPriceWithCurrency(paymentPerMonth, currency ?? "KZT"),
        })}
      </Badge>
      {/* <Typography sx={styles.deferredPayment} component="p">
        х {month}
      </Typography> */}
      <CustomTooltip
        arrow
        open={open}
        enterTouchDelay={0}
        placement={placement}
        sx={styles.deferredPayment.tooltip}
        onClick={(e) => e.stopPropagation()}
        title={
          title || (
            <>
              <Typography sx={styles.tooltipTitle}>
                {t("IN_INSTALLMENTS_OR_CREDIT")}
              </Typography>
              <Typography sx={styles.tooltipText}>
                {t("FINAL_PRICE_MAY_VARY_DEPENDING_ON_SELECTION_PARAMETERS")}
              </Typography>
            </>
          )
        }
      >
        <Box
          sx={styles.tooltip}
          onMouseUp={onMouseUp}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
          onClick={(e) => {
            e.stopPropagation();
            onClick?.();
          }}
        >
          <InfoIcon />
        </Box>
      </CustomTooltip>
    </Box>
  );
};
