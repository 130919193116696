"use client";
import { FC, ReactNode } from "react";

import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";

import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

import LoaderIcon from "@/public/icons/common/loader.svg";

type TProps = {
  children: ReactNode | JSX.Element;
};

const StyledLoaderIcon = styled(LoaderIcon)(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  () => styles.mainLoaderWrapper.loader,
);

export const MainLoader: FC<TProps> = ({ children }) => {
  const theme = useCustomTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <Box sx={styles.mainLoaderWrapper}>
        <StyledLoaderIcon />
      </Box>
    );
  }

  return children;
};
