import { FC, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Collapse, Typography, IconButton } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { CREDIT_BANNER_KEY } from "@/shared/lib/constants";

type TProps = {
  isOpen: boolean;
};

export const NotificationBar: FC<TProps> = ({ isOpen = false }) => {
  const t = useTranslations();
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    sessionStorage.setItem(CREDIT_BANNER_KEY, "true");
    setOpen(false);
  };

  return (
    <Box sx={styles.container}>
      <Collapse in={open}>
        <Box sx={styles.bar}>
          <Typography sx={styles.text}>{t("TRAVEL_NOW_PAY_LATER")}</Typography>

          <IconButton color="inherit" aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Collapse>
    </Box>
  );
};
