import * as React from "react";

import { Step, Stack, StepLabel } from "@mui/material";

import { StyledStepper, QontoConnector, QontoStepIconRoot } from "./styles";

type TCustomStepper = {
  stepsCount: number;
};

const QontoStepIcon = () => {
  return (
    <QontoStepIconRoot>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
};

export const CustomStepper: React.FC<TCustomStepper> = ({ stepsCount = 1 }) => {
  const steps = [];

  for (let i = 1; i <= stepsCount + 1; i++) {
    steps.push(React.createElement("div", null, i));
  }

  return (
    <Stack sx={{ width: "100%" }}>
      <StyledStepper alternativeLabel connector={<QontoConnector />}>
        {steps.map((_, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={QontoStepIcon} />
          </Step>
        ))}
      </StyledStepper>
    </Stack>
  );
};
