import { EDetailsSteps } from "./constants";

import { DEV_BASE_URL } from "@/shared/config";
import {
  ERROR_COLORS,
  SUCCESS_COLORS,
  ERROR_FINISH_COLORS,
} from "@/shared/lib/constants";
import { EOrderStatus, EOrderPayment } from "@/shared/lib/constants/orders";
import { priceFormatter } from "@/shared/lib/helpers";
import { TOrderResponseData } from "@/shared/model/types/orders";

export const getActiveStep = (
  orderStatus?: EOrderStatus,
  paymentStatus?: EOrderPayment,
) => {
  if (
    orderStatus === EOrderStatus.Canceled ||
    orderStatus === EOrderStatus.CancelPending ||
    paymentStatus === EOrderPayment.RefundPending ||
    paymentStatus === EOrderPayment.Refunded
  ) {
    return EDetailsSteps.CANCELLATION;
  }

  if (
    orderStatus === EOrderStatus.Created &&
    paymentStatus === EOrderPayment.Unpaid
  ) {
    return EDetailsSteps.PAYMENT;
  }

  if (paymentStatus === EOrderPayment.Paid) {
    return EDetailsSteps.BOOKING;
  }

  return EDetailsSteps.PAYMENT;
};

export const getPaymentBadgeColor = (status?: EOrderPayment) => {
  switch (status) {
    case EOrderPayment.Paid:
      return SUCCESS_COLORS;
    case EOrderPayment.Refunded:
      return ERROR_FINISH_COLORS;
    case EOrderPayment.RefundPending:
    case EOrderPayment.Unpaid:
      return ERROR_COLORS;
    default:
      return ERROR_COLORS;
  }
};

export const getCommonPaymentInfo = (orderData: null | TOrderResponseData) => {
  if (!orderData) {
    return [];
  }

  return [
    { label: "TOUR_PRICE", value: orderData.amount },
    { label: "PAID", value: orderData.details.payment_info.paid_amount },
    { label: "REMAINING", value: orderData.details.payment_info.left_amount },
  ];
};

export const getPaymentInfo = (
  amount?: number,
  rateDifference?: string,
  paidAt?: string,
) => [
  {
    label: "PAYMENT_AMOUNT",
    value: priceFormatter(amount),
  },
  {
    value: rateDifference,
    label: "EXCHANGE_RATE_AT_THE_TIME_OF_PAYMENT",
  },
  {
    value: paidAt,
    label: "PAYMENT_DATE",
  },
];

export const getOrderFile = (id: string) => {
  window.open(`${DEV_BASE_URL}/api/web/v2/order-file/${id}`);
};
