import { invoke } from "@withease/factories";
/* eslint-disable perfectionist/sort-objects */
import {
  sample,
  createApi,
  createEvent,
  createStore,
  createEffect,
} from "effector";

import { TokenService } from "../../api/token";
import {
  TConfirmOTP,
  TFinallyEvent,
  TAuthCallbacks,
  TCommonUserData,
} from "../../types/auth";
import { TNotice } from "../../types/common/entities/common";
import { closeProfileAndLinksDrawer } from "../navbar";
import { openNotification } from "../notifications";

import { PAGE_SIZE_KEY, CURRENT_PAGE_KEY } from "@/shared/lib/constants";
import { handleReset, resetOnLogout } from "@/shared/lib/factories/reset";
import { phoneNumberFormatter } from "@/shared/lib/helpers/helpers";
import {
  signUpMutation,
  logoutMutation,
  sendOTPMutation,
  confirmOTPMutation,
} from "@/shared/model/api/auth";

enum EMutationStatuses {
  Fail = "fail",
  Done = "done",
}

export const $isAuthModalOpen = createStore<boolean>(false);
export const $userStore = createStore<null | TCommonUserData>(
  TokenService.getUser() ?? null,
);

export const $userLeadFormData = $userStore.map((userData) =>
  userData
    ? {
        name: `${userData.first_name} ${userData.last_name}`,
        phone: phoneNumberFormatter({
          withDash: true,
          phone: userData.phone,
        }),
      }
    : null,
);

export const $isLoggedIn = createStore<boolean>(
  !!TokenService.getCookiesAccessToken() ?? false,
);
export const updateCallbacks = createEvent<TAuthCallbacks>();
export const $callbacksStore = createStore<TAuthCallbacks>({
  onSuccess: undefined,
  onNextStep: undefined,
}).on(updateCallbacks, (callbacks, newCallbacks) => ({
  ...callbacks,
  ...newCallbacks,
}));

export const confirmOTPOnSuccessCallbackEvent = createEvent<() => void>();

export const { openAuthModal, closeAuthModal } = createApi($isAuthModalOpen, {
  openAuthModal: () => true,
  closeAuthModal: () => false,
});

export const { setUserData, clearUserData } = createApi($userStore, {
  setUserData: (_state, user: TCommonUserData) => user,
  clearUserData: () => null,
});

export const { setIsLoggedIn, setIsLoggedOut } = createApi($isLoggedIn, {
  setIsLoggedIn: () => true,
  setIsLoggedOut: () => false,
});

const tokenServiceFx = createEffect<TConfirmOTP, void>(({ user, token }) => {
  TokenService.setCookiesAccessToken(token);
  TokenService.setUser(user);
});

const setUserDataFx = createEffect<TConfirmOTP, void>(({ user }) => {
  setUserData(user);
  setIsLoggedIn();
});

const fireCallbacksFx = createEffect<TFinallyEvent, void>(
  ({ error, result, status }) => {
    // TODO: refactor
    // eslint-disable-next-line effector/no-getState
    const { onSuccess, onNextStep } = $callbacksStore.getState();
    const finished = result.data.finished;

    if (status === EMutationStatuses.Done && finished) {
      onSuccess?.();
    } else if (!finished && !error) {
      onNextStep?.();
    } else {
      console.info("Mutation ended with status:", status);
    }
  },
);

sample({
  clock: [confirmOTPMutation.finished.success, signUpMutation.finished.success],
  // TODO: refactor
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  filter: ({ result }) => result.data.data.finished,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  fn: ({ result }) => result.data.data,
  target: [tokenServiceFx, setUserDataFx],
});

sample({
  clock: [
    confirmOTPMutation.finished.finally,
    sendOTPMutation.finished.finally,
    signUpMutation.finished.finally,
  ],
  fn: (data) => {
    // TODO: fix types (Murat)
    return {
      status: data.status,
      params: data.params,
      error: data.status === EMutationStatuses.Fail ? data.error : "",
      result: data.status === EMutationStatuses.Done ? data.result.data : null,
    } as unknown as TFinallyEvent;
  },
  target: fireCallbacksFx,
});

sample({
  clock: confirmOTPMutation.finished.success,
  fn: () =>
    ({
      severity: "success",
      message: "CODE_SUCCESSFULLY_CONFIRMED",
    }) as TNotice,
  target: [openNotification, confirmOTPOnSuccessCallbackEvent],
});

export const removeUserOnClientFx = createEffect(() => {
  localStorage.removeItem(PAGE_SIZE_KEY);
  localStorage.removeItem(CURRENT_PAGE_KEY);
  TokenService.removeToken();
  TokenService.removeUser();
});

sample({
  clock: logoutMutation.finished.finally,
  target: [
    clearUserData,
    setIsLoggedOut,
    removeUserOnClientFx,
    closeProfileAndLinksDrawer,
    resetOnLogout,
  ],
});

invoke(handleReset, { store: $userStore });
