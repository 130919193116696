import { mainTheme } from "shared/ui/styles";

export const styles = {
  hr: {
    maxHeight: "44px",
  },

  inputEmpty: {
    color: "content.grey-200",
  },

  error: {
    fontSize: "12px",
    color: "accent.red.error-dark",
  },

  inputWrapper: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },

  inputError: {
    borderColor: "accent.red.error",
    boxShadow: "0px 0px 0px 4px rgba(237, 75, 75, 0.2)",
  },

  doubleInputWrapper: {
    width: "100%",
    display: "grid",
    marginTop: "8px",
    columnGap: "4px",
    borderRadius: "8px",
    gridTemplateColumns: "1.1fr 1px 9.9fr",

    [mainTheme.breakpoints.down("md")]: {
      gridTemplateColumns: "2fr 1px 8fr",
    },
  },

  inputWrapperRight: {
    ".MuiInputBase-root": {
      borderLeft: "none",
      borderTop: "1px solid",
      borderRight: "1px solid",
      borderBottom: "1px solid",
      borderRadius: "0 8px 8px 0",
      borderColor: "dominant.cloud",

      ".Mui-focused": {
        border: "1px solid red",
      },
    },
  },

  inputWrapperLeft: {
    ".MuiBox-root": {
      display: "none",
    },

    ".MuiInputBase-root": {
      borderRight: "none",
      borderTop: "1px solid",
      borderLeft: "1px solid",
      borderBottom: "1px solid",
      borderRadius: "8px 0 0 8px",
      borderColor: "dominant.cloud",

      ".Mui-focused": {
        border: "1px solid red",
      },
    },
  },

  input: {
    height: "19px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "1.16",
    padding: "11.5px 12px",
    color: "content.primary",
    textOverflow: "ellipsis",

    "&:read-only": {
      cursor: "pointer",
    },

    "&::placeholder": {
      opacity: 1,
      color: "content.grey-200",
    },

    "&:disabled": {
      cursor: "default",
      borderColor: "content.grey-100",
      backgroundColor: "background.default",
    },
  },

  wrapper: {
    height: "44px",
    padding: "0px",
    overflow: "hidden",
    border: "1px solid",
    borderRadius: "8px",
    position: "relative",
    borderColor: "cloud",
    backgroundColor: "dominant.white",
    transition: "border-color .3s ease, box-shadow .3s ease",

    "&.MuiInputBase-adornedEnd": {
      paddingRight: "12px",
    },

    "&.MuiInputBase-adornedStart": {
      paddingLeft: "12px",
    },

    "& > svg": {
      flexShrink: 0,
      transition: "color .3s",
    },

    "&:not(.Mui-disabled, .error)": {
      "&.ring:hover,  &.ring.active": {
        borderColor: "accent.brand.primary",
        boxShadow: "0px 0px 0px 4px rgba(29, 133, 255, 0.2)",
      },
    },

    "&.Mui-disabled": {
      color: "content.grey-200",
      backgroundColor: "content.grey-100",

      "& .Mui-disabled": {
        color: "content.grey-200",
        backgroundColor: "content.grey-100",
      },
    },
  },
};
