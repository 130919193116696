export enum QUERY_OPTIONS {
  adults = "ad",
  children = "ch",
  arrival = "arr",

  departure = "dep",
  rangeEndDay = "to",
  childrenAges = "chAge",

  rangeStartDay = "from",
  selectedNight = "night",
  selectedRegionIds = "regId",
}

export enum BACKEND_KEYS {
  hotels = "hotels",
  nights = "nights",

  adults = "adults",
  sortBy = "sort_by",
  children = "children",
  rangeEndDay = "date_to",

  sortOrder = "sort_order",
  arrival = "arrival_country",
  rangeStartDay = "date_from",
  rangeEndNight = "nights_to",

  childrenAges = "children_ages",
  rangeStartNight = "nights_from",
  departure = "departure_location",

  selectedRegionIds = "arrival_locations",
}

export type TSearchData = {
  [BACKEND_KEYS.adults]: number;
  [BACKEND_KEYS.nights]: number;
  [BACKEND_KEYS.arrival]: string;
  [BACKEND_KEYS.children]: number;
  [BACKEND_KEYS.departure]: string;
  [BACKEND_KEYS.rangeEndDay]: string;
  [BACKEND_KEYS.rangeStartDay]: string;
  [BACKEND_KEYS.rangeEndNight]: number;
  [BACKEND_KEYS.rangeEndNight]: number;
  [BACKEND_KEYS.rangeStartNight]: number;
  [BACKEND_KEYS.childrenAges]: (null | number)[];
  [BACKEND_KEYS.selectedRegionIds]: null | string[];
};

export type TSearchValues = {
  [QUERY_OPTIONS.arrival]: string;
  [QUERY_OPTIONS.departure]: string;
  [QUERY_OPTIONS.rangeEndDay]: string;

  [QUERY_OPTIONS.rangeStartDay]: string;
  [QUERY_OPTIONS.adults]: string | number;
  [QUERY_OPTIONS.children]: string | number;

  [QUERY_OPTIONS.selectedRegionIds]?: string[];
  [QUERY_OPTIONS.selectedNight]: string | number;
  [QUERY_OPTIONS.childrenAges]?: string[] | number[];
};

export type TAdditionalCardInfo = {
  index: number;
  arrivalName: string;
  departureName: string;
};

export type TLastSeenCardInfo = TSearchValues & TAdditionalCardInfo;
