import NextImage from "next/image";
import { FC, useMemo, useState, SyntheticEvent } from "react";

import { Box, SxProps } from "@mui/material";
import useResizeObserver from "use-resize-observer";

import { TRoundedProps, getImageRoundingSize } from "./lib";
import { styles, inlineImageStyles } from "./styles";

import {
  PLACEHOLDER_IMAGE,
  IMAGE_DEFAULT_QUALITY,
} from "@/shared/lib/constants";
import { createGradient, getResizedLink } from "@/shared/lib/helpers";
type TProps = {
  alt?: string;
  src?: string;
  width?: number;
  height?: number;
  quality?: number;
  gradient?: boolean;
  onClick?: () => void;
  placeholder?: string;
  rounded?: TRoundedProps;
  imageWrapperSx?: SxProps;
};

export const Image: FC<TProps> = ({
  src,
  width,
  height,
  onClick,
  alt = "",
  gradient = true,
  rounded = false,
  imageWrapperSx = {},
  quality = IMAGE_DEFAULT_QUALITY,
  placeholder = PLACEHOLDER_IMAGE,
}) => {
  const { ref, width: refWidth, height: refHeight } = useResizeObserver();
  const [error, setError] = useState<SyntheticEvent<HTMLImageElement>>();
  const isDimensions = Boolean(width && height);
  const imageWidth = isDimensions ? width : refWidth;
  const imageHeight = isDimensions ? height : refHeight;
  const borderRadius = useMemo(
    () =>
      rounded ? { borderRadius: `${getImageRoundingSize(rounded)}px` } : {},
    [rounded],
  );
  const gradientSx = gradient ? createGradient() : {};
  const imageStyles = {
    ...inlineImageStyles,
    ...borderRadius,
  };

  const imageWrapperSizes = {
    ...(imageWidth ? { width: imageWidth } : {}),
    ...(imageHeight ? { height: imageHeight } : {}),
  };

  const imageWrapperParams = isDimensions ? {} : { ref };
  const imageWpapperStyles = {
    ...styles.imageWrapper,
    ...imageWrapperSizes,
    ...imageWrapperSx,
    ...borderRadius,
    ...gradientSx,
  };

  return (
    <Box {...imageWrapperParams} sx={imageWpapperStyles as SxProps}>
      <NextImage
        fill
        alt={alt}
        unoptimized
        loading="lazy"
        onClick={onClick}
        onError={setError}
        style={imageStyles}
        src={
          error
            ? placeholder
            : getResizedLink({
                src,
                quality,
                width: imageWidth,
                height: imageHeight,
              }) ?? ""
        }
      />
    </Box>
  );
};
