const MEDIA_BELOW_980PX = "@media screen and (max-width: 980px)";

export const styles = {
  tooltipText: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "12px",
  },

  tooltipTitle: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "16px",
  },

  tooltip: {
    height: "30px",
    display: "flex",
    alignItems: "center",
    color: "grey.extralight",
  },

  deferredPaymentBox: {
    gap: "4px",
    display: "flex",
    alignItems: "center",

    deferredPaymentBadge: {
      fontSize: "12px",
      color: "#39362A",
      width: "fit-content",
      backgroundColor: "#FFDD65",

      [MEDIA_BELOW_980PX]: {
        fontSize: "14px",
        width: "max-content",
      },
    },
  },

  deferredPayment: {
    fontWeight: 400,
    color: "#767676",
    fontSize: "12px",
    lineHeight: "16px",

    [MEDIA_BELOW_980PX]: {
      fontSize: "14px",
    },

    tooltipText: {
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "12px",
    },

    tooltipTitle: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
    },

    tooltip: {
      width: "156px",
      position: "absolute",
      inset: "auto auto -12px -7px !important",
    },
  },
};
