import { FC, memo } from "react";

import { ExpandMore } from "@mui/icons-material";
import { Box, MenuItem, Typography, Select as MuiSelect } from "@mui/material";

import { NewInput } from "../Input/NewInput";
import { TNewInput } from "../Input/types";
import { styles, selectStyles } from "./styles";
import { TNewSelect } from "./types";

const Trigger: FC<TNewInput> = memo(function Trigger({ ...props }) {
  return (
    <Box sx={styles.selectBox}>
      <NewInput {...props} />
    </Box>
  );
});

export const NewSelect: FC<TNewSelect> = ({
  label,
  value,
  options,
  onChange,
  disabled,
  listItemSx,
  placeholder,
  errorMessage,
}) => {
  return (
    <>
      <MuiSelect
        value={value}
        disabled={disabled}
        displayEmpty={true}
        onChange={onChange}
        IconComponent={() => null}
        endAdornment={<ExpandMore fontSize="small" />}
        input={<Trigger label={label} errorMessage={errorMessage} />}
        MenuProps={{
          disablePortal: true,
          MenuListProps: {
            sx: [styles.contentStyles, selectStyles.list],
          },
          PaperProps: {
            sx: {
              boxShadow: "none",
            },
          },
        }}
        renderValue={(value) =>
          value !== "" ? (
            <Typography sx={[styles.triggerText]}>
              {options?.find((item) => item.value === value)?.label ?? value}
            </Typography>
          ) : (
            <Typography sx={[styles.placeholderText, styles.triggerText]}>
              {placeholder}
            </Typography>
          )
        }
      >
        {options?.map((item, index) => (
          <MenuItem key={index} sx={listItemSx} value={item.value ?? ""}>
            {item.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </>
  );
};
