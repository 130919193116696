export const FLIGHT_CLASSES: Record<string, string> = {
  other: "OTHER",
  Econom: "ECONOMY",
};

export const PASSENGER_KEY = "passengers.";

export const DEFAULT_USER_DATA = {
  save: true,
  sex: "male",
  // TODO: unused field on the FE, but required on the BE, need to discuss with BE to consider if they can remove it
  second_name: "",
  document_type: "passport",
  citizenship_country_id: "KAZ",
};

export const FIELD_NAMES = [
  "first_name",
  "last_name",
  "birthdate",
  "document_type",
  "citizenship_country_id",
  "document_number",
  "document_given",
  "document_issued",
  "document_expire",
];

export enum EContentTypes {
  Price = "price",
  Content = "content",
  Analytics = "analytics",
}

export const CALC_ERROR_TEXTS = {
  subtitle: "PLEASE_SELECT_ANOTHER_TOUR",
  title: "TOUR_PACKAGE_RECALCULATION_ERROR",
};
