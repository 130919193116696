import { usePathname } from "next/navigation";
import { FC, Fragment } from "react";

import {
  List,
  Divider,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useGate, useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { ECounterVariants } from "../../lib/constants";
import { CounterBlock } from "../CounterBlock";
import { LatestOrderCard } from "../LatestOrderCard";
import { styles } from "./styles";

import { PROFILE_LINKS, MY_ORDERS_LINK } from "@/entities/profile";
import { LogoutButton } from "@/entities/profile/LogoutButton";

import { Condition } from "@/shared/lib/condition";
import { BONUSES_LINK } from "@/shared/lib/constants";
import { priceFormatter } from "@/shared/lib/helpers";
import { useCustomTheme } from "@/shared/lib/hooks";
import { getBonusesAmountQuery } from "@/shared/model/api/bonuses";
import {
  getCountersQuery,
  getLatestOrderQuery,
} from "@/shared/model/api/profile";
import { closeProfileAndLinksDrawer } from "@/shared/model/store/navbar";
import { ProfileGate } from "@/shared/model/store/profile";
import { Link } from "@/shared/ui";

type TProps = {
  itemsId?: string;
  withDivider?: boolean;
};

export const ProfileLinks: FC<TProps> = ({ withDivider, itemsId = "" }) => {
  const pathname = usePathname();
  const t = useTranslations();

  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  useGate(ProfileGate, isPhone);

  const { data: bonuses } = useUnit(getBonusesAmountQuery);
  const { data: profileCounters } = useUnit(getCountersQuery);
  const { data: latestOrder, pending: isLatestLoading } =
    useUnit(getLatestOrderQuery);

  const closeProfileAndLinksDrawerFn = useUnit(closeProfileAndLinksDrawer);

  const isLatestOrderCardShown = isPhone && latestOrder && !isLatestLoading;

  return (
    <List sx={styles.list}>
      {PROFILE_LINKS.map(({ id, name, link, icon }) => (
        <Fragment key={id}>
          <ListItem
            onClick={closeProfileAndLinksDrawerFn}
            sx={styles.listItem(!!pathname?.includes(link))}
          >
            <Link href={link} style={styles.link} id={`${itemsId + id}_test`}>
              <>
                <Typography sx={styles.icon}>{icon}</Typography>
                <Typography sx={styles.linkText}>{t(name)}</Typography>
              </>
            </Link>

            <Condition
              match={Boolean(bonuses?.amount) && link === BONUSES_LINK}
            >
              <Typography sx={styles.bonuses}>
                {priceFormatter(bonuses?.amount)}
              </Typography>
            </Condition>

            <Condition match={Boolean(profileCounters)}>
              <CounterBlock
                profileCounters={profileCounters}
                name={t(name) as ECounterVariants}
              />
            </Condition>
          </ListItem>

          <Condition
            match={link === MY_ORDERS_LINK && Boolean(isLatestOrderCardShown)}
          >
            <LatestOrderCard latestOrder={latestOrder} />
          </Condition>
        </Fragment>
      ))}

      <Condition match={Boolean(withDivider)}>
        <Divider sx={styles.hr} />
      </Condition>
      <LogoutButton />
    </List>
  );
};
