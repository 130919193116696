import { FC } from "react";

import { Box, Skeleton, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

type TProps = {
  isLoading?: boolean;
  orderNumber?: number | string;
};

export const OrderNumber: FC<TProps> = ({ isLoading, orderNumber = "-" }) => {
  const t = useTranslations();
  if (isLoading) {
    return <Skeleton height={"42px"} width={"161px"} variant="rounded" />;
  }

  return (
    <Box sx={styles.wrapper}>
      <Typography sx={styles.label}>{`${t("ORDER_NUMBER")}:`}</Typography>
      <Typography sx={styles.number}>{orderNumber}</Typography>
    </Box>
  );
};
