import { attach } from "effector";

import { TFFRequest, TPagination } from "../../types/common/entities/common";
import {
  TPageCategory,
  TNavbarElement,
  TToursArrivalsData,
  TContructorPageResponse,
  TAllDeparturesAndArrivals,
  TConstructorCitiesResponse,
  TConstructorHotTourResponse,
} from "../../types/constructor-parser";

import { requestFx } from "@/shared/config";
import { TFilters } from "@/shared/model/types/tours";

type TConstructorPageResponse = TFFRequest<
  | TContructorPageResponse
  | TConstructorCitiesResponse
  | TConstructorHotTourResponse
>;
type TToursDeparturesResponse = TFFRequest<TNavbarElement[]>;
type TToursArrivalsResponse = TFFRequest<TToursArrivalsData[]>;
type TPagesCategoriesResponse = TFFRequest<TPageCategory[]>;
type TAllPagesResponse = TFFRequest<{
  pagination: TPagination;
  items: TContructorPageResponse[];
}>;
type TAllPagesRequest = {
  page?: number;
  per_page?: number;
  categoryId: string;
};
type TConstructorPageRequest = {
  url?: string;
  headers?: Record<string, string>;
  params?: {
    [key: string]: string | string[];
  };
};
type TAllDeparturesAndArrivalsResponse = TFFRequest<
  TAllDeparturesAndArrivals[]
>;

type THotTourFilters = TFFRequest<TFilters[]>;

const getContructorPageFx = attach<
  TConstructorPageRequest,
  TConstructorPageResponse
>({
  effect: requestFx,
  mapParams: ({ url, params, headers }) => {
    return {
      params,
      headers,
      url: `pages/${url}`,
    };
  },
});

const getToursDeparturesFx = attach<undefined, TToursDeparturesResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: `pages/navbar`,
  }),
});

const getToursArrivalsFx = attach<string, TToursArrivalsResponse>({
  effect: requestFx,
  mapParams: (departureId) => ({
    url: `pages/arrival-countries`,
    params: {
      departure_location_id: departureId,
    },
  }),
});

const getPagesCategoriesFx = attach<undefined, TPagesCategoriesResponse>({
  effect: requestFx,
  mapParams: () => ({
    url: "/pages/categories",
  }),
});

const getAllPagesFx = attach<TAllPagesRequest, TAllPagesResponse>({
  effect: requestFx,
  mapParams: ({ page, per_page, categoryId }) => ({
    url: `/pages`,
    params: {
      page,
      per_page,
      sort: "-created_at",
      "filter[page_category_id]": categoryId,
    },
  }),
});

const getAllDepartureAndsArrivalsFx = attach<
  void,
  TAllDeparturesAndArrivalsResponse
>({
  effect: requestFx,
  mapParams: () => ({
    url: "pages/departures-with-arrivals",
  }),
});

const getHotTourFiltersFx = attach<void, THotTourFilters>({
  effect: requestFx,
  mapParams: () => ({
    url: "pages/hot-tours/filters",
  }),
});

export {
  getAllPagesFx,
  getToursArrivalsFx,
  getContructorPageFx,
  getHotTourFiltersFx,
  getToursDeparturesFx,
  getPagesCategoriesFx,
  getAllDepartureAndsArrivalsFx,
};
