export const styles = {
  wrapper: {
    display: "flex",
    margin: "0 auto",
    borderRadius: "50%",
    alignItems: "center",
    color: "dominant.white",
    justifyContent: "center",
  },
};
