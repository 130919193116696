import { FC } from "react";

import { Button } from "@mui/material";

import { styles } from "./styles";

import { Link, RoundedBorderContainer } from "@/shared/ui";

type TProps = {
  name: string;
  href: string;
  isSelected?: boolean;
};

export const DepartureSelector: FC<TProps> = ({ name, href, isSelected }) => {
  return (
    <Link href={href}>
      <Button
        component="span"
        variant={isSelected ? "outlined" : "text"}
        sx={{
          ...styles.button,
          justifyContent: "flex-start",
          color: isSelected ? "primary.main" : "content.primary",
        }}
      >
        {name}
      </Button>
    </Link>
  );
};

export const DeparturesWrapper: FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  return (
    <RoundedBorderContainer sx={styles.pageWrapper}>
      {children}
    </RoundedBorderContainer>
  );
};
