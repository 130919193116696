/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/kk";
import "dayjs/locale/ru";
import duration from "dayjs/plugin/duration";
import { GRADIENT_ANGLE, GRADIENT_COLORS } from "shared/ui/styles";

import {
  EColSizes,
  HOT_TOUR_SLICE,
  EElementPosition,
} from "@/entities/tours/HotTours/lib/constants";

import { containerProps } from "@/shared/lib/constants";
import {
  TMedia,
  TAmenity,
  THotelData,
  TCurrencies,
  TVideosType,
  THotelImages,
  EFlightTypes,
  TConditionalDates,
  THotelDescriptions,
} from "@/shared/model/types/common";

dayjs.extend(duration);

export const changeDurationFormat = (duration?: string) => {
  if (!duration) return "0 ч 0 м";

  const [hours, minutes] = duration.split(":");
  return `${hours} ч ${minutes} м`;
};

export const formatDateToTextType = (date: string) => {
  return dayjs(date).format("D MMM, dd");
};

export function getContainerWidth(type?: string, addPadding = true) {
  const { main, defaultPadding } = containerProps;
  const padding = addPadding ? 2 * defaultPadding : 0;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const width = (containerProps[type] as number) || main;
  return `${width + padding}px`;
}

export function betweenInRange(num: number, min: number, max: number) {
  return num >= min && num <= max;
}

// temporary solution
export const splitter = (str = "") => {
  return "https" + str.split("http")[1];
};

export const createGradient = (
  angle = GRADIENT_ANGLE,
  colors = GRADIENT_COLORS,
  speed = "1.2",
) => {
  if (Array.isArray(colors)) {
    const gradient = colors.join(", ");

    return {
      background: `linear-gradient(${angle}, ${gradient})`,
      // eslint-disable-next-line perfectionist/sort-objects
      backgroundSize: "400% 400%",
      "@keyframes pulse": {
        "0%": {
          backgroundPosition: "0% 0%",
        },
        "100%": {
          backgroundPosition: "-135% 0%",
        },
      },
      // eslint-disable-next-line perfectionist/sort-objects
      animation: `pulse ${speed}s ease-in-out infinite`,
    };
  }

  return {};
};

export const priceFormatter = (
  number?: string | number,
  options = { digits: 3, currency: "₸", separetor: " " },
) => {
  if (!number) return 0;

  const { currency, digits = 3, separetor = " " } = options;
  const re = new RegExp(`(\\d)(?=(\\d{${digits}})+(?!\\d))`, "g");
  return number.toString().replace(re, `$1${separetor}`) + ` ${currency}`;
};

export const phoneNumberFormatter = ({
  phone,
  withDash,
}: {
  phone: string;
  withDash?: boolean;
}) => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    const intlCode = match[1] ? "+7 " : "";

    if (withDash) {
      return [
        intlCode,
        "",
        match[2],
        " ",
        match[3],
        "-",
        match[4],
        "-",
        match[5],
      ].join("");
    }

    return [
      intlCode,
      "(",
      match[2],
      ") ",
      match[3],
      " ",
      match[4],
      " ",
      match[5],
    ].join("");
  }

  return phone;
};

export const getPriceWithCurrency = (
  price: string | number,
  currency: TCurrencies,
): string => {
  const currencies: Record<TCurrencies, string> = {
    KZT: "₸",
    USD: "$",
    EUR: "€",
    AED: "AED",
  };

  const formattedPrice = Number(price).toLocaleString("ru-RU");

  return `${formattedPrice} ${currencies[currency] || ""}`;
};

export const checkValidationRule = (value?: null | string, rule?: string) => {
  if (!rule || !value) return true;

  if (new RegExp(rule.replaceAll("/", "")).test(value)) return true;

  return false;
};

export const computedRatingStyle = (rating: number) => {
  if (betweenInRange(rating, 4, 5)) return "success.main";
  if (betweenInRange(rating, 3, 3.9)) return "warning.secondary";
  if (betweenInRange(rating, 0, 2.9)) return "error.secondary";
};

export const getPartnerImageLink = (name: string) => {
  return `/img/partners/${name}.png`;
};

export const getWaitingTime = (date1: Dayjs, date2: Dayjs) => {
  const diff = Math.abs(date1.diff(date2));
  return changeDurationFormat(dayjs.duration(diff).format("HH:mm"));
};

export const generateMediaArray = (
  images: THotelImages[],
  videos?: TVideosType[],
  galleryMode?: boolean,
): TMedia[] => {
  if (videos && videos.length > 0) {
    const [firstImage, ...remainingImages] = images;
    const videoLink = videos[0]?.link;

    const mediaArray = [firstImage, { videoLink }, ...remainingImages];

    return galleryMode ? mediaArray.slice(0, 6) : mediaArray;
  }

  return galleryMode ? images.slice(0, 6) : images;
};

/**
 * returns string with hotel locations
 * @param hotelInfo hotel object
 * @returns string with locations separated by ","
 */
export const getHotelAddress = (hotelInfo: THotelData) => {
  const countryName = hotelInfo.country?.name;
  const hotelLocations = hotelInfo?.locations ?? [];

  const address = `${countryName + "," || ""} ${hotelLocations
    .map((location) => location.name || "")
    .join(", ")}`;

  return address;
};

/**
 *
 * @param length string
 * @param index number
 * @param isDesktop boolean
 * @returns number of columns
 */

export const getColumnSize = (
  length: number,
  index: number,
  isDesktop: boolean,
) => {
  if (!isDesktop) {
    switch (true) {
      case length === EColSizes.SINGLE_COL:
        return 4;
      case length === 2:
        return EColSizes.HALF_COL;
      case length > 2 && length <= HOT_TOUR_SLICE:
        return index === 0 || (index === length - 1 && index % 2 !== 0)
          ? EColSizes.FULL_COL
          : EColSizes.HALF_COL;

      case length > HOT_TOUR_SLICE:
        return index === length - 1 && index % 2 === 0
          ? EColSizes.FULL_COL
          : EColSizes.HALF_COL;
      default:
        return EColSizes.HALF_COL;
    }
  }

  if (length === 1) return EColSizes.FULL_COL;
  if (length === 2) return EColSizes.HALF_COL;

  if (length === 3) {
    return index === EElementPosition.FIRST
      ? EColSizes.HALF_COL
      : EColSizes.SINGLE_COL;
  }

  if (length === 4) return index % 4 === 0 ? EColSizes.SINGLE_COL : undefined;

  if (length === 5) {
    return index < EElementPosition.THIRD
      ? EColSizes.HALF_COL
      : index === EElementPosition.THIRD
        ? EColSizes.HALF_COL
        : EColSizes.SINGLE_COL;
  }

  if (length === 6) {
    return index === EElementPosition.FIRST ||
      index === EElementPosition.SECOND ||
      (index - 2) % 4 === 4
      ? EColSizes.HALF_COL
      : EColSizes.SINGLE_COL;
  }

  if (length === 7) {
    return index === EElementPosition.FIRST
      ? EColSizes.HALF_COL
      : EColSizes.SINGLE_COL;
  } else {
    return index === 0 ? EColSizes.HALF_COL : EColSizes.SINGLE_COL;
  }
};

export const getHotelAmenities = (
  descriptions: THotelDescriptions[],
  isDesktop = true,
) => {
  if (descriptions.length === 0) return [];

  const sliceLength = isDesktop ? 4 : 3;

  return descriptions
    .reduce<TAmenity[]>((acc, description) => {
      if (acc.length === 4) return acc;

      const amenity = description.amenities.find((amenity) => amenity.main);

      if (amenity) {
        acc.push(amenity);
      }

      return acc;
    }, [])
    .slice(0, sliceLength);
};

export const getHeaderStyle = (pathname: string) => {
  return pathname === "/" ? "transparent" : "default";
};

export const getConditionalDatesText = ({
  checkIn,
  checkOut,
  nightsText,
}: TConditionalDates) => {
  const conditionalDateFormat =
    dayjs(checkIn).format("MM") === dayjs(checkOut).format("MM")
      ? "DD"
      : "DD MMM";

  const datesText = `${dayjs(checkIn).format(conditionalDateFormat)} - ${dayjs(
    checkOut,
  ).format("DD MMM")}, ${nightsText}`;

  return datesText;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatToDayMonth = (date: string): string => {
  return dayjs(date).format("D MMMM");
};

export const getCountryNameFromHotTourUrl = (url: string) => {
  return url.split("/").pop() || "";
};

export const getCityNameFromHTUrl = (path: string): null | string => {
  if (path) {
    const match = path.match(/^\/?(?:s\/)?hot-tours\/([^/]+)/);
    return match ? match[1] : null;
  }
  return null;
};

export const getCountryNameFromHTUrl = (
  path: string | undefined,
): null | string => {
  if (path) {
    const match = path.match(/\/?([^/]+)$/);
    return match ? match[1] : null;
  }
  return null;
};

export const getPathLength = (
  pathname: string,
  prefix: string = "/s/",
): number => {
  return pathname.replace(new RegExp(`^${prefix}`), "").split("/").length;
};

export const getFlightTypeText = (type: null | EFlightTypes) => {
  switch (type) {
    case EFlightTypes.Charter:
      return "CHARTER";
    case EFlightTypes.Gds:
      return "REGULAR_FLIGHT";
    default:
      return "-";
  }
};
