import { FC, memo } from "react";

import { NearMe } from "@mui/icons-material";
import { SxProps, Typography } from "@mui/material";
import { useUnit } from "effector-react";

import { styles } from "./styles";

import {
  $defaultDeparture,
  openDepartureModal,
} from "@/shared/model/store/default-departure";
import { LoaderButton } from "@/shared/ui";

type TProps = {
  color?: string;
  buttonSx?: SxProps;
  onClick?: () => void;
  isDefaultColor?: boolean;
};

export const DepartureButton: FC<TProps> = memo(
  ({ onClick, buttonSx = {}, color = "white", isDefaultColor = false }) => {
    const [defaultDeparture, openModal] = useUnit([
      $defaultDeparture,
      openDepartureModal,
    ]);

    const handleClick = () => {
      if (onClick) {
        onClick();
      } else {
        openModal();
      }
    };

    return (
      <LoaderButton
        onClick={handleClick}
        startIcon={<NearMe />}
        loading={!defaultDeparture}
        sx={{
          ...styles.headerGeolocation,
          ...buttonSx,
          color: isDefaultColor ? "grey.dark" : color,
        }}
      >
        <Typography>{defaultDeparture?.name ?? ""}</Typography>
      </LoaderButton>
    );
  },
);
