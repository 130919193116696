import { TAirline } from "./common";

export type TTourGroup = {
  id: string;
  name: string;
  airlines?: TAirline[];
  tour_content: string[];
};

export enum EDiscounts {
  fixed = "fixed",
  percent = "percent",
}

export type TDiscount = {
  id: string;
  code?: "promo";
  percent: number;
  discount: number;
  type: EDiscounts;
  description: string;
};
