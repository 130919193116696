import { FC, memo, useCallback } from "react";

import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  Typography,
  SelectChangeEvent,
  Select as MuiSelect,
} from "@mui/material";

import { styles, selectStyles } from "./styles";
import { TSelect, TSelectOptions } from "./types";

import { Input } from "@/shared/ui";

const Trigger: FC<any> = memo(function Trigger({ ...props }) {
  return (
    <Box sx={styles.selectBox}>
      <Input {...props} />
    </Box>
  );
});

export const Select: FC<TSelect> = memo(
  ({
    id,
    children,
    selectKey,
    label = "",
    value = "",
    options = [],
    sxListItem = {},
    disabled = false,
    placeholder = "",
    onChange = () => null,
  }: TSelect) => {
    const handleListItemClick = useCallback(
      (value: TSelectOptions | SelectChangeEvent<null | number | string>) => {
        onChange(value);
      },
      [onChange],
    );

    return (
      <MuiSelect
        id={id}
        value={value}
        disabled={disabled}
        displayEmpty={true}
        IconComponent={() => null}
        onChange={handleListItemClick}
        input={<Trigger label={label} />}
        endAdornment={<ExpandMore fontSize="small" />}
        MenuProps={{
          disablePortal: true,
          MenuListProps: {
            sx: [styles.contentStyles, selectStyles.list],
          },
          PaperProps: {
            sx: {
              boxShadow: "none",
            },
          },
        }}
        renderValue={(value) =>
          value !== "" ? (
            <Typography sx={[styles.triggerText]}>
              {options.find((item) => item.value === value)?.label ?? value}
            </Typography>
          ) : (
            <Typography sx={[styles.placeholderText, styles.triggerText]}>
              {placeholder}
            </Typography>
          )
        }
      >
        {options.map((item) =>
          children ? (
            children({ item, onClick: handleListItemClick })
          ) : (
            <MenuItem
              key={item.id}
              sx={sxListItem}
              value={item.value ?? ""}
              id={selectKey ? `${selectKey}_${item.id}` : item.id ?? undefined}
            >
              {item.label}
            </MenuItem>
          ),
        )}
      </MuiSelect>
    );
  },
);
