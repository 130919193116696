import { createQuery } from "@farfetched/core";
import { attach } from "effector";

import { TFFRequest } from "../../types/common/entities/common";
import { TOrderCategory, TOrderResponseData } from "../../types/orders";
import { getCountersFx, getLatestOrderFx } from "./endpoints";

import { requestFx } from "@/shared/config";
import { QueryTools } from "@/shared/lib/helpers/farfetched";

type TOrdersResponse = TFFRequest<TOrderResponseData[]>;
type TOrderCategoriesResponse = TFFRequest<TOrderCategory[]>;

export const getCountersQuery = createQuery({
  name: "getCounters",
  effect: getCountersFx,
  mapData: ({ result }) => result.data.data,
});

export const getLatestOrderQuery = createQuery({
  name: "getLatestOrder",
  effect: getLatestOrderFx,
  mapData: ({ result }) => result.data.data,
});

export const fetchOrdersFx = attach<string | undefined, TOrdersResponse>({
  effect: requestFx,
  mapParams: (filter) => ({
    url: "orders",
    params: {
      "filter[status]": filter,
    },
  }),
});

export const getOrdersQuery = createQuery({
  name: "getOrders",
  effect: fetchOrdersFx,
  mapData: ({ result }) => {
    return result.data.data;
  },
});

export const fetchOrderCategoriesFx = attach<
  string | undefined,
  TOrderCategoriesResponse
>({
  effect: requestFx,
  mapParams: () => ({
    url: "orders/status-groups",
  }),
});

export const getOrderCategoriesQuery = createQuery({
  name: "getOrderCategories",
  effect: fetchOrderCategoriesFx,
  mapData: ({ result }) => {
    return result.data.data;
  },
});

QueryTools(getCountersQuery).errors.handleAll();
QueryTools(getLatestOrderQuery).errors.handleAll();
QueryTools(getOrdersQuery).errors.handleAll();
QueryTools(getCountersQuery).clearPrivateData();
QueryTools(getLatestOrderQuery).clearPrivateData();
QueryTools(getOrdersQuery).clearPrivateData();
QueryTools(getOrderCategoriesQuery).clearPrivateData();
