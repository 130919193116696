/* eslint-disable @typescript-eslint/no-explicit-any */
import { Query, Mutation } from "@farfetched/core";
import { invoke } from "@withease/factories";
import { StoreWritable } from "effector";

import { handleAll, ErrorHandlers } from "@/shared/lib/factories/error";
import { handleReset } from "@/shared/lib/factories/reset";
import { timeUntilStale } from "@/shared/lib/factories/time-until-stale";

export const QueryTools = (query: Query<any, any, any, any>) => ({
  timeUntilStale: (timeout: number) =>
    invoke(timeUntilStale, { query, timeout }),
  clearPrivateData: (store?: StoreWritable<any>) =>
    invoke(handleReset, { query, store }),
  errors: {
    handleAll: (handlers?: ErrorHandlers) =>
      invoke(handleAll, { query, handlers }),
  },
});

export const MutationTools = (mutation: Mutation<any, any, any>) => ({
  errors: {
    handleAll: (handlers?: ErrorHandlers) =>
      invoke(handleAll, { mutation, handlers }),
  },
});
