import { FC } from "react";

import { Box } from "@mui/material";

import { styles } from "./styles";

import { Link } from "@/shared/ui";

type TProps = {
  link: string;
  text: string;
};

export const PaymentLink: FC<TProps> = ({ link, text }) => {
  return (
    <Box sx={styles.redirectButton}>
      <Link href={link}>{text}</Link>
    </Box>
  );
};
