import * as CSS from "csstype";

import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  imageWrapper: {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
    position: "relative",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },

  hotToursContainer: {
    padding: "16px",
    paddingBottom: "96px",

    "@media screen and (max-width: 960px)": {
      paddingBottom: "36px",
    },

    heading: {
      display: "flex",
      paddingBottom: "16px",
      justifyContent: "space-between",

      iconButtons: {
        cursor: "pointer",
        textDecoration: "none",

        forward: {
          marginLeft: "11px",
          marginRight: "20px",
        },
      },
    },
  },

  hotTourCard: {
    listItem: {
      cursor: "pointer",
      transition: "0.2s",
      overflow: "hidden",
      borderRadius: "8px",

      content: {
        padding: "12px 0 12px 0",
      },

      "&:hover img": {
        transform: "scale(1.09)",
      },

      "&:after": {
        inset: 0,
        content: "''",
        position: "absolute",
        background:
          "linear-gradient(12.45deg, rgba(4, 22, 44, 0.7) 4.68%, rgba(4, 22, 44, 0) 40.26%)",
      },

      badge: {
        zIndex: 1,
        gap: "4px",
        left: "16px",
        bottom: "20px",
        display: "block",
        position: "absolute",
        color: "dominant.white",
        textShadow: "2px 2px 4px rgba(22, 22, 22, 0.4)",

        labels: {
          display: "flex",
          paddingTop: "4px",

          date: {
            fontSize: "18px",
            [mainTheme.breakpoints.down("lg")]: {
              fontSize: "16px",
              display: "block",
            },
            [mainTheme.breakpoints.down("md")]: {
              fontSize: "14px",
              display: "block",
            },
          },
          price: {
            fontSize: "32px",
            [mainTheme.breakpoints.down("lg")]: {
              fontSize: "24px",
              display: "block",
            },
            [mainTheme.breakpoints.down("md")]: {
              fontSize: "18px",
              display: "block",
            },
          },
          countryName: {
            fontSize: "26px",
            [mainTheme.breakpoints.down("lg")]: {
              fontSize: "18px",
              display: "block",
            },
            [mainTheme.breakpoints.down("md")]: {
              fontSize: "14px",
              display: "block",
            },
          },
        },
      },
    },
  },
};

export const inlineImageStyles: CSS.Properties = {
  maxWidth: "100%",
  transition: "0.2s",
  objectFit: "cover",
};
