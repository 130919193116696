import { FC, memo } from "react";

import { Typography } from "@mui/material";

import { ECounterVariants } from "../../lib/constants";
import { styles } from "./styles";

import { TProfileCounters } from "@/shared/model/types/profile";

type TProps = {
  isLoading?: boolean;
  name: ECounterVariants;
  profileCounters: null | TProfileCounters;
};

const getCounter = (
  name: ECounterVariants,
  profileCounters: null | TProfileCounters,
) => {
  switch (name) {
    case ECounterVariants.Orders:
      return profileCounters?.orders;
    case ECounterVariants.Favorites:
      return profileCounters?.favorites;
    case ECounterVariants.Notifications:
      return profileCounters?.notifications;
    default:
      return 0;
  }
};

export const CounterBlock: FC<TProps> = memo(({ name, profileCounters }) => {
  const counterValue = getCounter(name, profileCounters);

  if (!counterValue) {
    return null;
  }

  return <Typography sx={styles.counter}>{counterValue}</Typography>;
});
