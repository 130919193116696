export const styles = {
  wrapper: {
    padding: "12px",
  },

  link: {
    fontSize: "16px",
    lineHeight: "18px",
  },

  orderNumber: {
    fontSize: "16px",
    lineHeight: "18px",
  },

  text: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "content.secondary",
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  content: {
    display: "flex",
    columnGap: "8px",
    margin: "16px 0",
    alignItems: "center",
  },

  linkBox: {
    display: "flex",
    columnGap: "4px",
    alignItems: "center",
    color: "primary.main",
  },

  hotelTitle: {
    maxWidth: "90%",
    fontWeight: 500,
    fontSize: "16px",
    overflow: "hidden",
    lineHeight: "18px",
    marginBottom: "8px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};
