import { FC, memo } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { CustomTooltip } from "@/shared/ui";

type TProps = {
  title?: string;
  fieldId?: string;
  isError?: boolean;
  fieldName: string;
  subtitle?: string;
  onClick: () => void;
  children?: JSX.Element;
  tooltipText?: string | boolean;
};

export const MobileSearchBox: FC<TProps> = memo(
  ({
    title,
    fieldId,
    onClick,
    subtitle,
    fieldName,
    isError = false,
    children = null,
    tooltipText = "",
  }) => {
    const t = useTranslations();

    return (
      <CustomTooltip
        open
        arrow
        placement="top"
        title={isError ? tooltipText : ""}
      >
        <Box id={fieldId} onClick={onClick} sx={styles.wrapper}>
          <Typography sx={styles.fieldName(isError)}>{fieldName}</Typography>
          <Typography
            sx={styles.title}
            color={title ? "grey.dark" : "grey.light"}
          >
            {title || t("SELECT")}
          </Typography>

          {subtitle && <Typography sx={styles.subtitle}>{subtitle}</Typography>}

          {children}
        </Box>
      </CustomTooltip>
    );
  },
);
