import { createSharedPathnamesNavigation } from "next-intl/navigation";

import { LOCALE_PREFIX, AVAILABLE_LOCALES } from "./config";

export const {
  Link: NextIntlLink,
  redirect: nextIntlRedirect,
  useRouter: useNextIntlRouter,
  usePathname: useNextIntlPathname,
} = createSharedPathnamesNavigation({
  locales: AVAILABLE_LOCALES,
  localePrefix: LOCALE_PREFIX,
});
