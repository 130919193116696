import { FC, memo, useMemo, useState, ChangeEvent, useCallback } from "react";

import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Radio,
  SxProps,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import { styles, radioSelectStyles } from "./styles";
import type { TRadioSelect, TRadioSelectTrigger } from "./types";

import { Condition } from "@/shared/lib/condition";
import { Input, Popover } from "@/shared/ui";

const Trigger: FC<TRadioSelectTrigger> = memo(
  ({
    open,
    value,
    onClick,
    placeholder,
    inputWrapperStyles,
  }: TRadioSelectTrigger) => {
    return (
      <Input
        readOnly
        value={value}
        focused={open}
        onClick={onClick}
        placeholder={placeholder}
        endAdornment={<ExpandMore fontSize="small" />}
        sxInput={{
          ...styles.selectBox.inputStyles,
          width: value.length == 3 ? `25px` : `${value.length * 9}px`,
        }}
        sxWrapper={
          {
            ...styles.selectBox.inputWrapperStyles,
            ...inputWrapperStyles,
          } as SxProps
        }
      />
    );
  },
);

// Computed Styles
const listStyles = () => ({
  ...styles.contentStyles,
  ...radioSelectStyles.listStyles,
});

// eslint-disable-next-line react/display-name
export const RadioSelect: FC<TRadioSelect> = memo(
  ({
    id,
    options,
    label = "",
    value = "",
    placeholder = "",
    onChange = () => null,
    inputWrapperStyles = {},
    popoverStyles = { left: "unset" },
  }: TRadioSelect) => {
    const [open, setOpen] = useState(false);

    // Popover controllers
    const handleOpenPopover = () => setOpen(true);
    const handlePopoverClose = () => setOpen(false);

    const handleRadioGroupChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
        handlePopoverClose();
      },
      [onChange],
    );

    const currentValue = useMemo(
      () => options.find((x) => x.value === value)?.label ?? "",
      [options, value],
    );

    return (
      <Box sx={styles.selectBox}>
        <Condition match={Boolean(label)}>
          <label>{label}</label>
        </Condition>
        <Popover
          open={open}
          closePopover={handlePopoverClose}
          popoverStyles={{ marginTop: "12px", ...popoverStyles }}
          trigger={
            <Trigger
              open={open}
              value={currentValue}
              placeholder={placeholder}
              onClick={handleOpenPopover}
              inputWrapperStyles={inputWrapperStyles}
            />
          }
        >
          <Box sx={listStyles}>
            <RadioGroup
              id={id}
              value={value}
              onChange={handleRadioGroupChange}
              sx={radioSelectStyles.itemsWrapper}
            >
              {options.map(({ value, label }) => (
                <FormControlLabel
                  key={label}
                  label={label}
                  value={value}
                  id={`${value}_test`}
                  control={<Radio sx={radioSelectStyles.radioButton} />}
                  sx={
                    currentValue === label
                      ? {
                          ...radioSelectStyles.radioText,
                          ...radioSelectStyles.radioTextSelected,
                        }
                      : radioSelectStyles.radioText
                  }
                />
              ))}
            </RadioGroup>
          </Box>
        </Popover>
      </Box>
    );
  },
);

Trigger.displayName = "Trigger";
