import Box from "@mui/material/Box";
import { styled } from "@mui/system";

import { getContainerWidth } from "@/shared/lib/helpers/helpers";

type TStyledContainer = {
  disablePadding?: boolean;
};

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disablePadding",
})(({ disablePadding }: TStyledContainer) => {
  return {
    width: "100%",
    margin: "0 auto",
    maxWidth: getContainerWidth(),
    paddingInline: !disablePadding ? "16px" : "",
  };
});
