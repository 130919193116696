import { mainTheme } from "shared/ui/styles";

export const styles = {
  buttonsWrapper: {
    display: "flex",
    columnGap: "16px",
  },

  linkButton: {
    display: "flex",
    maxWidth: "135px",
    borderRadius: "7px",
    alignItems: "center",
  },

  linkButtonWrapper: {
    maxWidth: "135px",
    transition: "0.2s",
    borderRadius: "7px",
    backgroundColor: "content.primary",
    "&:hover": {
      opacity: "0.7",
    },

    [mainTheme.breakpoints.down("sm")]: {
      "&:hover": {
        opacity: "1",
      },
    },
  },
};
