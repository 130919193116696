import { Chip } from "@mui/material";
import {
  ChipPropsSizeOverrides,
  ChipPropsColorOverrides,
  ChipPropsVariantOverrides,
} from "@mui/material/Chip/Chip";
import { styled } from "@mui/system";
import { OverridableStringUnion } from "@mui/types";
import { COLORS } from "shared/ui/styles";

type TCustomChipProps = {
  id?: string;
  sx?: object;
  disabled?: boolean;
  onClick?: () => void;
  label?: string | number;
  endAdornment?: React.ReactElement;
  startAdornment?: React.ReactElement;
  onDelete?: React.EventHandler<never>;
  size?: OverridableStringUnion<"small" | "medium", ChipPropsSizeOverrides>;
  variant?: OverridableStringUnion<
    "filled" | "outlined",
    ChipPropsVariantOverrides
  >;
  color?: OverridableStringUnion<
    | "info"
    | "error"
    | "warning"
    | "success"
    | "primary"
    | "default"
    | "secondary",
    ChipPropsColorOverrides
  >;
};

const StyledCustomChip = styled(Chip)(() => {
  return {
    gap: "4px",
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    borderRadius: "100px",
    "&:focus": {
      border: "1px solid",
      borderColor: COLORS.BLUE,
    },
    "&:active": {
      color: COLORS.BLUE,
      border: "1px solid",
      borderColor: COLORS.BLUE,
    },
    "&:hover": {
      border: "1px solid",
      borderColor: COLORS.WHITE_GRAY,
      backgroundColor: COLORS.ALICE_BLUE,
    },
  };
});

// eslint-disable-next-line react/display-name
export const CustomChip = ({
  id,
  onClick,
  sx = {},
  onDelete,
  label = "",
  endAdornment,
  size = "small",
  startAdornment,
  disabled = false,
  color = "default",
  variant = "outlined",
}: TCustomChipProps) => {
  return (
    <StyledCustomChip
      id={id}
      sx={sx}
      size={size}
      label={label}
      color={color}
      onClick={onClick}
      variant={variant}
      onDelete={onDelete}
      disabled={disabled}
      icon={startAdornment}
      deleteIcon={endAdornment}
    />
  );
};
