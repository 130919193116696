import { FC } from "react";
import ReactInputMask from "react-input-mask";
import { Props as IInputMaskProps } from "react-input-mask";

import { NewInput } from "./NewInput";
import { TNewInput } from "./types";

export type TInputMask = IInputMaskProps & TNewInput;

export const NewInputMask: FC<TInputMask> = (props) => {
  return (
    // TODO: types
    // @ts-expect-error
    <ReactInputMask {...props}>
      {/* @ts-expect-error */}
      {(inputProps: TInput) => <NewInput {...inputProps} {...props} />}
    </ReactInputMask>
  );
};
