"use client";
import { FC, useState } from "react";

import { Box, SxProps, Typography, CircularProgress } from "@mui/material";
import { sanitize } from "isomorphic-dompurify";
import { useLocale, useTranslations } from "next-intl";

import { styles } from "./styles";

import { ELangOptions, LANGUAGE_OPTIONS } from "@/shared/lib/constants/orders";
import { Link, CustomTab, CustomTabs } from "@/shared/ui";

type TProps = {
  contentKz?: string;
  contentRu?: string;
  isLoading?: boolean;
  contentWrapperSx?: SxProps;
};

export const AgreementReader: FC<TProps> = ({
  contentKz,
  contentRu,
  isLoading,
  contentWrapperSx = {},
}) => {
  const locale = useLocale();
  const t = useTranslations();
  const [currentTab, setCurrentTab] = useState(
    locale === "ru" ? ELangOptions.Rus : ELangOptions.Kaz,
  );

  if (isLoading) {
    return (
      <Box sx={styles.errorWrapper}>
        <CircularProgress />
      </Box>
    );
  }

  if (!contentKz || !contentRu) {
    return (
      <Box sx={styles.errorWrapper}>
        <Typography sx={styles.errorText}>
          Ошибка при получении договора
        </Typography>
        <Link href="/">{t("TO_HOME")}</Link>
      </Box>
    );
  }

  const isRuContentSelected = currentTab === ELangOptions.Rus;

  return (
    <>
      <CustomTabs
        value={currentTab}
        onChange={(_, v: ELangOptions) => setCurrentTab(v)}
      >
        {LANGUAGE_OPTIONS.map(({ label, value }) => (
          <CustomTab
            key={value}
            value={value}
            sx={styles.tab}
            label={t(label)}
          />
        ))}
      </CustomTabs>

      <Box
        sx={{ ...styles.documentWrapper, ...contentWrapperSx } as SxProps}
        dangerouslySetInnerHTML={{
          __html: sanitize(isRuContentSelected ? contentRu : contentKz),
        }}
      />
    </>
  );
};
