export const styles = {
  infoSpan: {
    color: "content.primary",
  },

  infoText: {
    color: "content.secondary",
  },

  dialogWrapperTitle: {
    padding: "0 0 24px",
  },

  infoButton: {
    padding: 0,
    height: "auto",
  },

  swipeableDrawerPaper: {
    height: "auto",
    paddingBottom: "32px",
  },

  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  dialogWrapperProps: {
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },

  dialogWrapperPaper: {
    padding: "24px",
    maxWidth: "432px",
    width: "100% !important",
  },

  swipeableDrawerChildrenWrapper: {
    padding: 0,
    marginX: "16px",
    borderBottom: "1px solid",
    borderColor: "dominant.cloud",
  },
};
