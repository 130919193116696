import { FC } from "react";

import { Button, ButtonProps, CircularProgress } from "@mui/material";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";

type TProps = {
  loading: boolean;
  disabled?: boolean;
  customIcon?: JSX.Element;
  component?: React.ElementType;
  color?:
    | "info"
    | "error"
    | "success"
    | "inherit"
    | "warning"
    | "primary"
    | "secondary";
};

export const LoaderButton: FC<TProps & ButtonProps> = ({
  loading,
  customIcon,
  color = "primary",
  ...props
}) => {
  return (
    <Button {...props} disabled={loading || props.disabled}>
      <Condition match={Boolean(customIcon) && !loading}>
        {customIcon}
      </Condition>

      {loading && (
        <CircularProgress size={16} color={color} sx={styles.progress} />
      )}
      {props.children}
    </Button>
  );
};
