import dynamic from "next/dynamic";
import { FC } from "react";

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box, SxProps } from "@mui/material";

import { styles } from "@/shared/ui/VideoPlayer/styles";

import PlayIcon from "@/public/icons/common/play.svg";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

type TVideoPlayerProps = {
  url: string;
  width?: string;
  height?: string;
  playing?: boolean;
  controls?: boolean;
  isMobile?: boolean;
  sxWrapper?: SxProps;
  showControls?: boolean;
  preventPlaying?: boolean;
  showPlayButton?: boolean;
  cover?: string | boolean;
  playIcon?: ReactJSXElement;
};

export const VideoPlayer: FC<TVideoPlayerProps> = ({
  url,
  cover = true,
  sxWrapper = {},
  width = "100%",
  playing = false,
  height = "100%",
  controls = false,
  isMobile = false,
  showControls = false,
  showPlayButton = false,
  playIcon = <PlayIcon />,
}) => {
  return (
    <ReactPlayer
      url={url}
      light={cover}
      width={width}
      height={height}
      playing={playing}
      controls={controls}
      showControls={showControls}
      showPlayButton={showPlayButton}
      style={{ ...styles.frame, ...(sxWrapper as React.CSSProperties) }}
      playIcon={
        isMobile ? <Box sx={styles.mobilePlayIcon}>{playIcon}</Box> : playIcon
      }
    />
  );
};
