import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const selectStyles = {
  list: {
    maxHeight: "325px",
    ...mainTheme.scrollbars.default,
    overflowX: "auto",
    overflowY: "auto",
    borderRadius: "8px",
    boxShadow: "0px 0px 15px 0px rgba(74, 85, 104, 0.10)",
  },
};

export const radioSelectStyles = {
  listStyles: {
    padding: "16px",
  },

  itemsWrapper: {
    rowGap: "8px",
  },

  radioButton: {
    padding: 0,
    display: "none",
  },

  radioTextSelected: {
    color: "accent.brand.primary",
    backgroundColor: "accent.brand.lightest",
  },

  radioText: {
    margin: 0,
    fontWeight: 400,
    fontSize: "16px",
    columnGap: "4px",
    transition: "0.2s",
    borderRadius: "4px",
    padding: "8px 13px",

    "&:hover": {
      backgroundColor: "accent.brand.lightest",
    },
  },
};

export const styles = {
  placeholderText: {
    color: "content.grey-200",
  },

  triggerText: {
    fontSize: "16px",
    lineHeight: "24px",
  },

  popoverStyles: (isInModal: boolean) => ({
    [mainTheme.breakpoints.down("smd")]: {
      marginTop: isInModal ? "12px" : "0px",
    },
  }),

  contentStyles: {
    padding: "16px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },

  selectBox: {
    gap: "8px",
    display: "flex",
    paddingTop: "0px",
    flexDirection: "column",

    inputStyles: {
      fontSize: "14px",
      padding: "10.5px 5px 10.5px 16px",
    },

    inputWrapperStyles: {
      gap: "4px",
      display: "flex",
      marginTop: "16px",
      cursor: "pointer",
      flexDirection: "row",

      "&:first-of-type": {
        marginTop: 0,
      },

      "& label": {
        "& span": {
          color: "content.grey-200",
        },
      },
    },
  },
};
