import { COLORS, mainTheme } from "shared/ui/styles";

export const styles = {
  dialogContent: {
    overflowY: "initial",
  },

  dialogFooter: {
    padding: "20px 16px 28px 16px",
  },

  dialogWrapper: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#818a95",
    },

    "& ::-webkit-scrollbar": {
      width: "4px",
      padding: "10px",
    },

    "& ::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: "#C0C5CA",
    },

    "& .MuiDialog-container": {
      alignItems: {
        sm: "center",
        xs: "flex-end",
      },
    },

    "& .MuiDialog-paper": {
      margin: {
        xs: "0",
      },
      width: {
        xs: "100%",
        sm: "auto",
      },
      borderRadius: {
        sm: "16px",
        xs: "16px 16px 0 0",
      },
    },
  },

  dialogTitle: {
    gap: "auto",
    display: "flex",
    fontWeight: "400",
    alignItems: "center",
    justifyContent: "space-between",

    wrapper: {
      padding: "20px 16px 28px 16px",
    },

    closeIcon: {
      color: "grey.dark",
      marginLeft: "auto",
    },

    secondLabel: {
      width: "340px",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "24px",

      [mainTheme.breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "16px",
      },
    },

    startBox: {
      gap: "6px",
      padding: "0px",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",

      [mainTheme.breakpoints.down("md")]: {
        fontSize: "20px",
        fontWeight: "500",
      },
    },

    endBox: {
      gap: "6px",
      padding: "0px",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",

      step: {
        fontSize: "26px",
        fontWeight: "400",
        lineHeight: "30px",
        color: COLORS.LIGHT_GRAY,

        [mainTheme.breakpoints.down("md")]: {
          fontSize: "20px",
        },
      },
    },
  },
};
