import dayjs from "dayjs";

import { LAST_SEEN_KEY } from "../constants";

import { QUERY_OPTIONS, TLastSeenCardInfo } from "@/shared/model/types/common";

const isDateInPast = (compareDate: string) => {
  return dayjs(compareDate).isAfter(dayjs());
};

export const actualizeLastSeen = (lastSeen?: string) => {
  if (!lastSeen) {
    return [];
  }

  const lastSeenArray = JSON.parse(lastSeen) as TLastSeenCardInfo[];

  const filteredArray =
    lastSeenArray.filter((search) =>
      isDateInPast(search[QUERY_OPTIONS.rangeStartDay]),
    ) || [];

  if (filteredArray.length) {
    localStorage.setItem(LAST_SEEN_KEY, JSON.stringify(filteredArray));
    return filteredArray;
  }

  return [];
};

export const setLastSeen = (searchValues: TLastSeenCardInfo) => {
  const lastSeenArray = getLastSeen();

  if (!lastSeenArray) {
    localStorage.setItem(LAST_SEEN_KEY, JSON.stringify([searchValues]));
    return;
  }

  const copiedArray = [...lastSeenArray];

  if (copiedArray.length === 3) copiedArray.pop();

  localStorage.setItem(
    LAST_SEEN_KEY,
    JSON.stringify([searchValues, ...copiedArray]),
  );
};

export const getLastSeen = () => {
  if (typeof window === "undefined") {
    return null;
  }

  const lastSeen = localStorage.getItem(LAST_SEEN_KEY);

  if (lastSeen) {
    return JSON.parse(lastSeen) as TLastSeenCardInfo[];
  }

  return null;
};
