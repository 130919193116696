export const PER_PAGE = 9999;

export const EVENT_NAME = "TOUR_SEARCH_STATUS";

export const MAIN_SEARCH_INCLUDES = {
  INIT: ["filters", "hotel", "tour_operator"],
  ACTUALIZED: ["filters", "hotel", "tour_operator"],
};

export const HOTELS_SEARCH_INCLUDES = {
  INIT: ["hotel", "room", "tour_operator", "meal"],
  ACTUALIZED: ["room", "tour_operator", "meal", "airlines"],
};
