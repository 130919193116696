"use client";
import { FC, forwardRef } from "react";

import { Snackbar, AlertProps, Alert as MuiAlert } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import {
  $notification,
  clearNotification,
} from "@/shared/model/store/notifications";

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert ref={ref} elevation={6} {...props} />;
  },
);

export const GlobalNotices: FC = () => {
  const t = useTranslations();

  const [notification, resetNotification] = useUnit([
    $notification,
    clearNotification,
  ]);

  if (!notification) {
    return null;
  }

  return (
    <Snackbar
      open
      autoHideDuration={3000}
      onClose={resetNotification}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        sx={styles}
        onClose={resetNotification}
        severity={notification?.severity}
      >
        {t(notification?.message ?? "OOPS_SOMETHING_WENT_WRONG")}
      </Alert>
    </Snackbar>
  );
};
