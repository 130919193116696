import { mainTheme } from "shared/ui/styles";

export const styles = {
  subtitle: {
    fontSize: 18,
    textAlign: "center",
    color: "content.secondary",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },

  title: {
    fontSize: "26px",
    lineHeight: "30px",

    [mainTheme.breakpoints.down("md")]: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "22px",
    },
  },

  notFoundBox: {
    margin: 0,
    width: "100%",
    rowGap: "12px",
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
};
