import { TAirline, TCurrencies } from "./common";

export type TSegment = {
  uid: string;
  duration: string;
  legs: TFlightLeg[];
};

export enum EFlightTypes {
  Gds = "gds",
  Charter = "charter",
}

type TAirport = {
  id: string;
  name: string;
  code: string;
};

type TLocation = {
  code: string;
  name: string;
  id: null | string;
};

type TDeparture = {
  time: string;
  date: string;
  airport: TAirport;
  location: TLocation;
};

export type TFlight = {
  duration: string;
  legs: TFlightLeg[];
};

export type TFlightLeg = {
  class: string;
  duration: string;
  airline: TAirline;
  type: EFlightTypes;
  arrival: TDeparture;
  departure: TDeparture;
  flight_number: string;
};

type TCommonFlightItem = {
  id: string;
  group_id: string;
  markup: null | string;
  currency: null | TCurrencies;
};

export type TFlightDirections = "return" | "outbound";

type TChanterFlightItem = {
  class: string;
  baggage: string;
  hand_luggage: string;
  direction: TFlightDirections;
};

export type TGdsFlightListItem = {
  segments: TFlight[];
} & TCommonFlightItem;

export type TCharterFlightListItem = {
  legs: TFlightLeg[];
} & TCommonFlightItem &
  TChanterFlightItem;

export type TFlightListItem = TCommonFlightItem & {
  segments: TGdsFlightListItem[] | TCharterFlightListItem[];
};
