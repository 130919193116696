export enum EOrderQuery {
  TourId = "tourId",
  PromoCode = "promoCode",
  OrderNumber = "orderNumber",
}

export enum EFormSteps {
  AUTH = 0,
  DETAILS = 1,
  PASSENGERS = 2,
}

export enum EPageTitle {
  Ready = "ready",
  Error = "error",
  Unauth = "unauth",
  Expired = "expired",
  Payment = "payment",
  Passengers = "passengers",
}

export enum EFaqCategories {
  Search = "search",
  Contract = "contract",
  Cancellation = "cancellation",
}

export enum ETourPriceModalTypes {
  Equal = "equal",
  Increased = "increased",
  Descreased = "decreased",
}

export const TEN_MINUTES = 10 * 1000 * 60;

export const PAYMENT_PATHNAME = "/payment/[orderNumber]";
