import { FC, useRef, useMemo, forwardRef } from "react";

import { Box, SxProps, InputBase } from "@mui/material";
import cm from "classnames";

import { ErrorMessage } from "../ErrorMessage";
import { styles } from "./styles";
import { TNewInput } from "./types";

import { Popper } from "@/shared/ui";

// TODO: new input types
// @ts-expect-error
export const NewInput: FC<TNewInput> = forwardRef(
  (
    {
      label = "",
      value = "",
      errorMessage,
      endAdornment,
      inputSx = {},
      type = "text",
      popperSx = {},
      startAdornment,
      wrapperSx = {},
      inputProps = {},
      focused = false,
      disabled = false,
      popperOptions = {},
      showPopper = false,
      inputWrapperSx = {},
      showFocusRing = true,
      onChange = () => null,
      popperInsideBoxSx = {},
      popperContent = () => null,
      ...otherProps
    },
    ref,
  ) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const wrapperCombinedSx = useMemo(
      () => ({ ...styles.inputWrapper, ...inputWrapperSx }),
      [inputWrapperSx],
    );

    const inputWrapperCombinedSx = useMemo(
      () => ({
        ...(styles.wrapper as SxProps),
        // TODO: rewrite with TS
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        ...(value?.length === 0 ? styles.inputEmpty : {}),
        ...(errorMessage ? styles.inputError : {}),
        ...wrapperSx,
      }),
      [errorMessage, value?.length, wrapperSx],
    );

    const inputCombinedProps = useMemo(
      () => ({
        spellCheck: false,
        sx: {
          ...styles.input,
          ...inputSx,
        },
        ...inputProps,
      }),
      [inputProps, inputSx],
    );

    const computedClasses = useMemo(
      () => cm({ active: focused, ring: showFocusRing, error: !!errorMessage }),
      [errorMessage, focused, showFocusRing],
    );

    return (
      <Box ref={wrapperRef} sx={wrapperCombinedSx as SxProps}>
        {label && <label>{label}</label>}
        <InputBase
          ref={ref}
          type={type}
          value={value}
          disabled={disabled}
          onChange={onChange}
          endAdornment={endAdornment}
          className={computedClasses}
          startAdornment={startAdornment}
          inputProps={inputCombinedProps}
          sx={inputWrapperCombinedSx as SxProps}
          {...otherProps}
        />
        <Popper
          placement="top"
          open={showPopper}
          anchorEl={wrapperRef.current}
          style={{ width: wrapperRef.current?.clientWidth, ...popperSx }}
          {...popperOptions}
          sx={popperInsideBoxSx}
        >
          {popperContent}
        </Popper>

        <ErrorMessage errorMessage={errorMessage} />
      </Box>
    );
  },
);
