export const styles = {
  tooltip: {
    fontWeight: 400,
    padding: "12px",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    borderRadius: "4px",
    letterSpacing: "0.2px",
    color: "dominant.white",
    backgroundColor: "content.primary",
  },
  tooltipArrow: {
    zIndex: -1,
    "&::after": {
      width: "16px",
      content: "''",
      height: "16px",
      display: "block",
      borderRadius: "2px",
      transform: "rotate(45deg)",
      backgroundColor: "content.primary",
    },
  },
  tooltipWrapper: {
    zIndex: 1300,
    "&[data-popper-placement^='top'] .tooltip-arrow": {
      bottom: "-4px",
    },
    "&[data-popper-placement^='bottom'] .tooltip-arrow": {
      top: "-4px",
    },
    "&[data-popper-placement^='left'] .tooltip-arrow": {
      right: "-4px",
    },
    "&[data-popper-placement^='right'] .tooltip-arrow": {
      left: "-4px",
    },
  },
};
