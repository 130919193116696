"use client";

import Image from "next/image";
import { FC } from "react";

import { SxProps, Typography } from "@mui/material";

import { styles } from "./styles";

import { RoundedBorderContainer } from "@/shared/ui";

import ErrorImage from "@/public/img/errors/500.png";

type TProps = {
  text: string;
  title: string;
  subtitle: string;
  linkText?: string;
  image?: JSX.Element;
  wrapperSx?: SxProps;
};

export const ErrorLayout: FC<TProps> = ({
  text,
  image,
  title,
  subtitle,
  linkText,
  wrapperSx = {},
}) => {
  return (
    <RoundedBorderContainer
      sx={{ ...styles.container, ...wrapperSx } as SxProps}
    >
      {image ?? (
        <Image
          width={280}
          alt="error"
          height={160}
          src={ErrorImage}
          style={{
            height: "auto",
            maxWidth: "100%",
          }}
        />
      )}

      <Typography variant="h1" sx={styles.title}>
        {title}
      </Typography>
      <Typography variant="h2" sx={styles.subtitle}>
        {subtitle}
      </Typography>
      <Typography sx={styles.paragraph}>{text}</Typography>

      <a href="/" className="default-link">
        {linkText}
      </a>
    </RoundedBorderContainer>
  );
};
