import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { computedRatingStyle } from "@/shared/lib/helpers";

type TProps = {
  rating: number;
  reviewsCount?: number;
  singleHotelView?: boolean;
};

export const HotelRating: FC<TProps> = ({
  rating = 0,
  reviewsCount = 0,
  singleHotelView = false,
}) => {
  const t = useTranslations();

  const reviewCountText = t("REVIEWS_DECLINATIONS", { count: reviewsCount });

  if (!rating) {
    return null;
  }

  const ReviewComponent = singleHotelView ? (
    <Typography
      sx={{
        ...styles.singleHotelViewRating,
        backgroundColor: computedRatingStyle(rating),
      }}
    >
      {rating}
      <Typography component="span" sx={styles.singleHotelViewRating.separator}>
        / 5
      </Typography>
    </Typography>
  ) : (
    <Typography
      component="span"
      sx={{
        ...styles.rating,
        backgroundColor: computedRatingStyle(rating),
      }}
    >
      {rating}
    </Typography>
  );

  if (reviewsCount) {
    return singleHotelView ? (
      <Box sx={styles.singleHotelViewRatingContainer}>
        {ReviewComponent}
        <Typography component="span" sx={styles.singleHotelViewReviewsCount}>
          {reviewCountText} (Tophotels)
        </Typography>
      </Box>
    ) : (
      <Box sx={styles.ratingContainer}>
        <Typography component="span" sx={styles.reviewsCount}>
          {reviewCountText}
        </Typography>
        {ReviewComponent}
      </Box>
    );
  }

  return <Box sx={styles.ratingContainer}>{ReviewComponent}</Box>;
};
