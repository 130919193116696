import { useRouter } from "next/navigation";
import { FC } from "react";

import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { PAYMENT_TEXTS, PASSENGERS_TEXTS } from "../../../lib/constants";
import { getCommonPaymentInfo } from "../../../lib/helpers";
import { PartialPaymentContent } from "../PartialPaymentContent";
import { PaymentLink } from "../PaymentLink";
import { styles } from "./styles";

import { ALMATY_DEPARTURE_CODE } from "@/shared/lib/constants";
import { getSearchParamsString } from "@/shared/lib/helpers/router/getSearchParamsString";
import { useGetOrderNumber } from "@/shared/lib/hooks";
import { getOrderDetailsQuery } from "@/shared/model/api/orders";
import { $defaultDeparture } from "@/shared/model/store/default-departure";
import { THotelCountry, QUERY_OPTIONS } from "@/shared/model/types/common";
import { TPaymentInfo } from "@/shared/model/types/orders";

type TProps = {
  amount?: number;
  isPartialPaid: boolean;
  paymentInfo?: TPaymentInfo;
  isPaymentPossible: boolean;
  arrivalCountry?: THotelCountry;
  orderStep: "order" | "payment";
};

export const PaymentStep: FC<TProps> = ({
  orderStep,
  isPartialPaid,
  arrivalCountry,
  isPaymentPossible,
}) => {
  const t = useTranslations();
  const router = useRouter();
  const { orderNumber } = useGetOrderNumber();

  const defaultDeparture = useUnit($defaultDeparture);
  const { data: orderData } = useUnit(getOrderDetailsQuery);

  const paymentInfo = getCommonPaymentInfo(orderData);

  const redirectToTourSearch = () => {
    const queryParams = getSearchParamsString({
      [QUERY_OPTIONS.arrival]: arrivalCountry?.code,
      [QUERY_OPTIONS.departure]:
        defaultDeparture?.code ?? ALMATY_DEPARTURE_CODE,
    });

    router.push(`/search?${queryParams}`);
  };

  const { hint, linkText } =
    orderStep === "order" ? PASSENGERS_TEXTS : PAYMENT_TEXTS;

  if (isPartialPaid) {
    return <PartialPaymentContent paymentInfo={paymentInfo} />;
  }

  if (isPaymentPossible) {
    return (
      <>
        <Typography sx={styles.text}>{t(hint)}</Typography>
        <PaymentLink text={t(linkText)} link={`/${orderStep}/${orderNumber}`} />
      </>
    );
  }

  return (
    <Box>
      <Typography sx={styles.text}>
        {t("UNFORTUNATELY_THE_PAYMENT_TIME_HAS_EXPIRED")}
      </Typography>

      <Button
        sx={styles.button}
        variant="outlined"
        onClick={redirectToTourSearch}
      >
        {t("FIND_A_NEW_TOUR")} &ldquo;{defaultDeparture?.name} -{" "}
        {arrivalCountry?.name}
        &ldquo;
        <ArrowForward fontSize="small" />
      </Button>
    </Box>
  );
};
