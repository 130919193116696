import { FC } from "react";

import { Box } from "@mui/material";
import * as CSS from "csstype";

import { styles, inlineImageStyles } from "./styles";

type TProps = {
  alt?: string;
  src?: string;
  sx?: CSS.Properties;
};

export const ImageLoading: FC<TProps> = ({ sx = {}, src = "", alt = "" }) => {
  return (
    <Box sx={{ ...styles.imageLoader, ...sx }}>
      <img alt={alt} src={src} style={{ ...inlineImageStyles, ...sx }} />
    </Box>
  );
};
