import { FC } from "react";

import { Lock } from "@mui/icons-material";
import { Box, SxProps, CircularProgress } from "@mui/material";

import { RoundedBorderContainer } from "../RoundedBorderContainer";
import { styles } from "./styles";

type TProps = {
  wrapperSx?: SxProps;
  isRounded?: boolean;
  isLoading?: boolean;
};

const BoxWithLock: FC<Pick<TProps, "isLoading">> = ({ isLoading }) => {
  return (
    <Box sx={styles.lock}>
      {isLoading ? <CircularProgress /> : <Lock color="disabled" />}
    </Box>
  );
};

export const LockedBox: FC<TProps> = ({
  wrapperSx = {},
  isRounded = true,
  isLoading = false,
}) => {
  if (isRounded) {
    return (
      <RoundedBorderContainer sx={wrapperSx}>
        <BoxWithLock isLoading={isLoading} />
      </RoundedBorderContainer>
    );
  }

  return (
    <Box sx={wrapperSx}>
      <BoxWithLock isLoading={isLoading} />
    </Box>
  );
};
