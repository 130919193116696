import { mainTheme } from "shared/ui/styles";

const pxToRem = mainTheme.typography.pxToRem;

export const styles = {
  linkText: {
    marginLeft: "5px",
    whiteSpace: "nowrap",
  },

  hr: {
    margin: "0",
    backgroundColor: "content.grey-100",
  },

  link: {
    width: "100%",
    height: "100%",
    padding: "8px",
  },

  bonuses: {
    fontSize: "18px",
    lineHeight: "22px",
    marginRight: "12px",
    whiteSpace: "nowrap",
    color: "content.primary",
  },

  icon: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [mainTheme.breakpoints.down("md")]: {
      color: "primary.main",
    },
  },

  counter: {
    width: "24px",
    fontWeight: 400,
    display: "flex",
    borderRadius: "6px",
    alignItems: "center",
    fontSize: pxToRem(12),
    color: "dominant.white",
    justifyContent: "center",
    backgroundColor: "accent.brand.primary",
  },

  listItem: (isSelected: boolean) => ({
    padding: 0,
    display: "flex",
    transition: "0.2s",
    borderRadius: "8px",
    alignItems: "center",
    backgroundColor: isSelected ? "primary.whiteblue" : "unset",

    [mainTheme.breakpoints.down("md")]: {
      borderRadius: 0,
      padding: "9px 0",
      borderTop: "1px solid",
      borderColor: "accent.brand.lightest",
    },
  }),

  list: {
    margin: "0",
    padding: "0",
    rowGap: "4px",
    display: "flex",
    flexDirection: "column",

    [mainTheme.breakpoints.down("md")]: {
      rowGap: 0,
      fontSize: "18px",
      lineHeight: "22px",
    },

    "& a": {
      display: "flex",
      transition: "0.2s",
      alignItems: "center",
      textDecoration: "none",
      color: "content.primary",

      "&:hover": {
        color: "primary.main",
      },
    },
  },
};
