type TContactTypes = EContactTypes;

export enum EContactTypes {
  Bin = "bin",
  Phone = "phone",
  Email = "email",
  Youtube = "youtube",
  Telegram = "telegram",
  Facebook = "facebook",
  Instagram = "instagram",
  Name = "organization_name",
  Address = "organization_address",
}

export type TContact = {
  id: string;
  link?: string;
  value: string;
  type: TContactTypes;
};

export type TBackendWA = {
  is_whatsapp?: boolean;
};

export type TFrontendWA = {
  isWhatsApp?: boolean;
};

export type TNormalizedContact = TContact & TFrontendWA;
