/* eslint-disable perfectionist/sort-objects */
import { sample, createEvent, createStore } from "effector";
import { previous } from "patronum";

import { TNavDeparture, TDepartureLocation } from "../../types/header-menu";
import { $departure, setDeparture } from "../departure";

import { ALMATY_DEPARTURE_ID } from "@/shared/lib/constants";
import {
  getLocationsSelectableQuery,
  setDefaultDepartureToCookies,
} from "@/shared/model/api/header";
import { getHeaderMenuQuery } from "@/shared/model/api/tours";

export const openDepartureModal = createEvent();
export const closeDepartureModal = createEvent();
export const setDefaultDeparture = createEvent<TNavDeparture>();

export const $defaultDeparture = createStore<null | TNavDeparture>(null);
export const $previousDefaultDeparture = previous($defaultDeparture);

export const $isDepartureModalOpen = createStore<boolean>(false);

export const $locationsSelectable = getLocationsSelectableQuery.$data.map(
  (v) => v,
);

export const $promotedDepartures = getLocationsSelectableQuery.$data.map(
  (locations) => {
    const promotedLocations = locations?.filter((item) => item.promoted) ?? [];
    const otherLocations = locations?.filter((item) => !item.promoted) ?? [];

    return [...promotedLocations, ...otherLocations];
  },
);

sample({
  clock: closeDepartureModal,
  source: {
    locations: $locationsSelectable,
    defaultDeparture: $defaultDeparture,
  },
  filter: ({ defaultDeparture }) => !defaultDeparture,
  fn: ({ locations }) => locations?.[0] as TNavDeparture,
  target: setDefaultDeparture,
});

sample({
  clock: $defaultDeparture,
  fn: (departure) => departure?.id ?? ALMATY_DEPARTURE_ID,
  target: getHeaderMenuQuery.refresh,
});

sample({
  clock: setDefaultDeparture,
  filter: $departure.map((v) => !v),
  fn: (departure) => departure as unknown as TDepartureLocation,
  target: setDeparture,
});

$isDepartureModalOpen.on(openDepartureModal, () => true);
$isDepartureModalOpen.on(closeDepartureModal, () => false);

$defaultDeparture.on(setDefaultDeparture, (_, v) => {
  setDefaultDepartureToCookies(v);
  return v;
});
