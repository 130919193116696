import { FC } from "react";

import { Check, Close, PriorityHigh } from "@mui/icons-material";
import { Box } from "@mui/material";

import { styles } from "./styles";

type TProps = {
  size?: "small" | "large" | "medium";
  status?: "error" | "warning" | "success";
};

const getProperties = ({ size = "large", status = "success" }: TProps) => {
  switch (status) {
    case "error":
      return {
        backgroundColor: "error.main",
        icon: <Close fontSize={size} />,
      };
    case "warning":
      return {
        backgroundColor: "warning.main",
        icon: <PriorityHigh fontSize={size} />,
      };
    default:
      return {
        backgroundColor: "success.main",
        icon: <Check fontSize={size} />,
      };
  }
};

const getSize = (size: TProps["size"]) => {
  switch (size) {
    case "small":
      return "28px";
    case "medium":
      return "40px";
    default:
      return "52px";
  }
};

export const CircleStatusIcon: FC<TProps> = ({
  size = "large",
  status = "success",
}) => {
  const { icon, backgroundColor } = getProperties({ size, status });
  const iconSize = getSize(size);

  return (
    <Box
      sx={{
        width: iconSize,
        backgroundColor,
        height: iconSize,
        ...styles.wrapper,
      }}
    >
      {icon}
    </Box>
  );
};
