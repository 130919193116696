import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import { TCustomTheme } from "@/shared/ui/styles/theme/types";

export const CustomSwitch = styled(Switch)(({
  theme,
}: {
  theme?: TCustomTheme;
}) => {
  return {
    padding: "9px",
    "& .MuiSwitch-track": {
      opacity: 1,
      borderRadius: "9999px",
      backgroundColor: theme?.palette.accent.brand.lightest,
    },
    "& .MuiSwitch-thumb": {
      margin: 2,
      width: 16,
      height: 16,
      boxShadow: "none",
      backgroundColor: theme?.palette.dominant.white,
    },
    "& > .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          opacity: 1,
        },
      },
      "&.Mui-disabled": {
        "& .MuiSwitch-thumb": {
          backgroundColor: theme?.palette.content["grey-200"],
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme?.palette.content["grey-100"],
        },
      },
    },
  };
});
