export const COLORS = {
  BLACK: "#000",
  RED: "#FF2C2C",
  GRAY: "#435161",
  BLUE: "#2B6BB2",
  WHITE: "#FFFFFF",
  CLOUD: "#EAEDF2",
  GREY200: "#C0C5CA",
  SOLITUDE: "#E6EDF5",
  LIGHT_RED: "#F26D6D",
  MAIN_GRAY: "#F8F8F8",
  EVERGREEN: "#0EAB69",
  DARK_GRAY: "#04172C",
  DARK_BLUE: "#1B579B",
  PINK_LIGHT: "#ffccd5",
  WHITE_BLUE: "#E6ECF2",
  LIGHT_GRAY: "#818A95",
  WHITE_GRAY: "#E6E8EA",
  ALICE_BLUE: "#F2F5F7",
  ACCENT_STARS: "#FAB042",
  LIGHT_EVERGREEN: "#1BC47D",
  EXTRA_LIGHT_BLUE: "#CFE1F5",
  SUCCESS: "rgba(30, 255, 88, 1.0)",
};

export const GRADIENT_ANGLE = "-90deg";

export const GRADIENT_COLORS = [
  `${COLORS.WHITE_GRAY} 0%`,
  `${COLORS.WHITE} 50%`,
  `${COLORS.WHITE_GRAY} 100%`,
];
