export const styles = {
  mainLoaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 86px)",

    loader: {
      width: "120px",
      height: "120px",

      "@keyframes fill": {
        to: {
          y: 0,
        },
      },
      "#fillRect": {
        animation: `fill 1.5s ease-in-out infinite`,
      },
    },
  },
};
