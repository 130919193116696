export const styles = {
  closeButton: {
    padding: 0,
  },

  clearIcon: {
    color: "#C0C5CA",
  },

  inputWrapper: {
    flexGrow: 1,
    width: "100%",
  },

  wrapper: {
    width: "100%",
    display: "flex",
    columnGap: "8px",
  },

  sxWrapper: {
    "&:hover": {
      boxShadow: "0px 0px 15px rgba(74, 85, 104, 0.1)",
    },
  },
};
