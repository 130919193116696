import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { getPaymentInfo, getPaymentBadgeColor } from "../../../../lib/helpers";
import { styles } from "./styles";

import { TOrderPaymentDetails } from "@/shared/model/types/orders";
import { Badge } from "@/shared/ui";

type TProps = {
  paymentIndex: number;
  paymentDetails: TOrderPaymentDetails;
};

export const PaymentDetails: FC<TProps> = ({
  paymentIndex,
  paymentDetails,
}) => {
  const t = useTranslations();

  const {
    rate,
    amount,
    paid_at: paidAt,
    currency_to: currencyTo,
    payment_type: paymentType,
    currency_from: currencyFrom,
    payment_status: paymentStatus,
  }: TOrderPaymentDetails = paymentDetails;

  const rateDifference = `1 ${currencyFrom} = ${rate} ${currencyTo}`;

  const paymentInfo = getPaymentInfo(amount, rateDifference, paidAt);

  const paymentStatusSx = {
    ...styles.paymentStatusSx,
    ...getPaymentBadgeColor(paymentStatus.code),
  };

  return (
    <>
      <Box sx={styles.titleRow}>
        <Typography component="h3" sx={styles.title}>
          {`${t("PAYMENT_2")} ${paymentIndex + 1}`}
        </Typography>
        <Badge sx={styles.paymentTypeSx}>{paymentType.name}</Badge>
      </Box>

      {paymentInfo.map(({ label, value }, idx) => (
        <Box key={idx} sx={styles.row}>
          <Typography sx={styles.label}>{t(label)}:</Typography>
          <Typography sx={styles.value}>{value}</Typography>
        </Box>
      ))}

      <Box sx={styles.row}>
        <Typography sx={styles.label}>{t("PAYMENT_STATUS")}:</Typography>
        <Badge sx={paymentStatusSx}>{paymentStatus.name}</Badge>
      </Box>
    </>
  );
};
