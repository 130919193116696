import { FC } from "react";

import { Box, BoxProps } from "@mui/material";

import { styles } from "./styles";

export const RoundedBorderContainer: FC<BoxProps> = ({
  children,
  ...otherProps
}) => {
  const computedStyles = {
    ...styles.roundedBorderContainer,
    ...otherProps.sx,
  };
  return <Box sx={computedStyles}>{children}</Box>;
};
