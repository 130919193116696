import { FC, useRef, useState, useEffect, useCallback } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box, Slide, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

export const ScrollToTop: FC = () => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [show, setShow] = useState(false);

  const handleButtonClick = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleScroll = useRef(() => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;

    if (scrollY > 400) {
      setShow(true);
    } else {
      setShow(false);
    }
  }).current;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  if (isDesktop) {
    return (
      <Slide in={show} mountOnEnter unmountOnExit direction="up">
        <Button
          variant="outlined"
          sx={styles.toTopButton}
          onClick={handleButtonClick}
          startIcon={<ExpandLessIcon />}
        >
          {t("BACK_TO_TOP")}
        </Button>
      </Slide>
    );
  }

  return (
    <Slide in={show} mountOnEnter unmountOnExit direction="up">
      <Box sx={styles.toTopButton} onClick={handleButtonClick}>
        <Box sx={styles.button}>
          <ExpandLessIcon htmlColor="white" />
        </Box>
      </Box>
    </Slide>
  );
};
