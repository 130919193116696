import { styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const StyledProgressBar = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== "hasStripe",
})<{ hasStripe?: boolean }>(({ theme, hasStripe = false }) => {
  const stripeStyles = hasStripe
    ? {
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: "9999px",
          backgroundSize: "2rem 2rem",
          backgroundColor: theme.palette.primary.light,
          animation: "stripeAnimation 0.8s linear infinite",
          backgroundImage:
            "linear-gradient( -45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent )",
        },
      }
    : {};

  return {
    height: "18px",
    borderRadius: "9999px",
    ...stripeStyles,
    "@keyframes stripeAnimation": {
      "0%": {
        backgroundPosition: "0 0",
      },
      "100%": {
        backgroundPosition: "0 2rem",
      },
    },
  };
});
