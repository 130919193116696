import { COLORS } from "shared/ui/styles";

export const styles = {
  singleHotelViewRatingContainer: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
  },

  ratingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  reviewsCount: {
    fontSize: 12,
    fontWeight: 400,
    marginRight: "4px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: COLORS.LIGHT_GRAY,
  },

  singleHotelViewReviewsCount: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: "8px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: COLORS.LIGHT_GRAY,
  },

  rating: {
    width: "30px",
    padding: "4px",
    color: "white",
    display: "flex",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  singleHotelViewRating: {
    width: "56px",
    padding: "4px",
    fontWeight: 500,
    display: "flex",
    marginTop: "8px",
    fontSize: "16px",
    minWidth: "60px",
    lineHeight: "18px",
    borderRadius: "4px",
    alignItems: "center",
    color: "dominant.white",
    justifyContent: "center",
    separator: {
      top: "2px",
      opacity: "0.6",
      fontSize: "12px",
      marginLeft: "4px",
      lineHeight: "16px",
      position: "relative",
      whiteSpace: "nowrap",
    },
  },
};
