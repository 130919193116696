import {
  IMAGE_DEFAULT_QUALITY,
  SUPPORTED_IMAGE_FORMATS,
  LOCAL_IMAGES_START_PATHS,
} from "../constants";

type TGetResizedLink = {
  src?: string;
  quality?: number;
  width?: number | "auto";
  height?: number | "auto";
};

export const getResizedLink = ({
  src,
  width = "auto",
  height = "auto",
  quality = IMAGE_DEFAULT_QUALITY,
}: TGetResizedLink) => {
  if (
    !src ||
    LOCAL_IMAGES_START_PATHS.some((prefix) => src.startsWith(prefix)) ||
    !SUPPORTED_IMAGE_FORMATS.includes(src.split(".").pop()?.toLowerCase() || "")
  ) {
    return src;
  }

  const size = `${width}_${height}_${quality}_`;

  const array = src.split("/");

  const firstPart = [...array];
  firstPart.pop();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const lastPart = [...array].pop()!;

  const ext = lastPart.split(".").pop();
  const name = lastPart.split(".").slice(0, -1).join(".");
  const newLastPart = `${size}${name}.${ext}`;

  const newSrc = [...firstPart, newLastPart]
    .join("/")
    .replace("/storage/", "/storage/resize/");
  return newSrc;
};
