import { createQuery } from "@farfetched/core";

import { getHotelDetailsFx } from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getHotelDetailsQuery = createQuery({
  effect: getHotelDetailsFx,
  name: "getHotelDetailsQuery",
  mapData: ({ result }) => result.data.data,
});

QueryTools(getHotelDetailsQuery).errors.handleAll();
