import { mainTheme } from "shared/ui/styles";

export const styles = {
  wrapper: {
    fontSize: "14px",
  },

  link: {
    color: mainTheme.palette.primary.main,
  },
};
