/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable perfectionist/sort-objects */
import { Query, Mutation } from "@farfetched/core";
import { createFactory } from "@withease/factories";
import { sample, createEvent } from "effector";
import { condition } from "patronum";

import { NetworkException } from "@/shared/config/api/exceptions";
import { openNotification } from "@/shared/model/store/notifications";
import { TNotice, NetworkExceptionType } from "@/shared/model/types/common";

export type ErrorHandlers = {
  [Type in NetworkExceptionType]?: string;
};

const DEFAULT_HANDLERS: Required<ErrorHandlers> = {
  Unknown: "UNKNOWN_ERROR_CONTACT_SUPPORT",
  NotFound: "DATA_NOT_FOUND_ON_SERVER_CONTACT_SUPPORT",
  Unauthorized: "YOU_ARE_NOT_AUTHORIZED_PLEASE_LOGIN",
  TooManyRequests: "YOU_ARE_REQUESTING_TOO_OFTEN_RETRY_LATER",
};

export type ErrorsHandleAllParams = {
  handlers?: ErrorHandlers;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: Query<any, any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation?: Mutation<any, any, any>;
};

export const handleAll = createFactory((params: ErrorsHandleAllParams) => {
  const { query, mutation, handlers = {} } = params;
  const queryOrMutation = query || mutation;

  if (!queryOrMutation) {
    throw new TypeError("You should provide either Query or Mutation");
  }

  const finalHandlers = { ...DEFAULT_HANDLERS, ...handlers };

  const networkExceptionOccured = createEvent<NetworkException>();

  const gotRequestError = queryOrMutation.finished.failure.map<Error>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    ({ error }) => error,
  );

  condition({
    source: gotRequestError,
    if: (error) => error instanceof NetworkException,
    then: networkExceptionOccured,
    else: networkExceptionOccured.prepend((error: any) => {
      if (typeof error.message === "string") {
        return new NetworkException(error.message);
      }

      return new NetworkException("Unknown");
    }),
  });

  sample({
    clock: networkExceptionOccured,
    fn: (exception) =>
      ({
        severity: "error",
        message: finalHandlers[exception.type] ?? exception.type,
      }) as TNotice,
    target: openNotification,
  });
});
