export const MOBILE_IMAGE_ROW_HEIGHT = 164;
export const MOBILE_DISPLAYED_IMAGE_ROWS_COUNT = 3;
export const MOBILE_IMAGE_LIST_GAP = 15;

export const MOBILE_COLLAPSE_HEIGHT =
  MOBILE_IMAGE_ROW_HEIGHT * MOBILE_DISPLAYED_IMAGE_ROWS_COUNT +
  MOBILE_IMAGE_LIST_GAP * MOBILE_DISPLAYED_IMAGE_ROWS_COUNT;

export const DESKTOP_IMAGE_ROW_HEIGHT = 310;
export const DESKTOP_DISPLAYED_IMAGE_ROWS_COUNT = 1;
export const DESKTOP_IMAGE_LIST_GAP = 24;

export const DESKTOP_COLLAPSE_HEIGHT =
  DESKTOP_IMAGE_ROW_HEIGHT * DESKTOP_DISPLAYED_IMAGE_ROWS_COUNT +
  DESKTOP_IMAGE_LIST_GAP * DESKTOP_DISPLAYED_IMAGE_ROWS_COUNT;

export enum EColSizes {
  FULL_COL = 4,
  HALF_COL = 2,
  SINGLE_COL = 1,
}

export enum EElementPosition {
  THIRD = 2,
  FIRST = 0,
  SECOND = 1,
}

export const HOT_TOUR_SLICE = 6;
