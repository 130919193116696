/* eslint-disable perfectionist/sort-objects */

import * as amplitude from "@amplitude/analytics-browser";
import { sample, createEvent, createEffect } from "effector";

import type { TAmplitudeEvent } from "@/shared/lib/constants/analytics/amplitude";

const sendEventToAmplitudeFx = createEffect(
  ({
    event,
    eventProperties = {},
  }: {
    event: TAmplitudeEvent;
    eventProperties?: Record<string, unknown>;
  }) => {
    amplitude.track(event, eventProperties);
  },
);

export const sendAmplitudeEvent = createEvent<{
  event: TAmplitudeEvent;
  data?: Record<string, unknown>;
}>();

sample({
  clock: sendAmplitudeEvent,
  fn: ({ data, event }) => {
    return {
      event,
      eventProperties: data,
    };
  },
  target: sendEventToAmplitudeFx,
});
