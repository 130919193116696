import { createQuery, createMutation } from "@farfetched/core";

import { getHotelDetailsQuery } from "../hotels";
import {
  editOrderFx,
  getOrdersFx,
  createOrderFx,
  cancelBookingFx,
  postPayerInfoFx,
  bookWithManagerFx,
  getOrderDetailsFx,
  postCancelOrderFx,
  getActualizeOrderFx,
  getTouristContractFx,
  gerOrderPaymentDataFx,
  getOrderPaymentTypesFx,
  postPassengerAsPayerFx,
  postUserCreditHistoryFx,
  postAvailableCashAmountFx,
  getOrderPaymentsDetailsFx,
  deleteUserCreditHistoryFx,
} from "./endpoints";

import { QueryTools } from "@/shared/config";

export const getTouristContractQuery = createQuery({
  effect: getTouristContractFx,
  name: "getTouristContractQuery",
  mapData: ({ result }) => result.data.data,
});

export const getOrderDetailsQuery = createQuery({
  name: "getOrderData",
  effect: getOrderDetailsFx,
  mapData: ({ result }) => result.data.data,
});

export const getActualizeOrderQuery = createQuery({
  effect: getActualizeOrderFx,
  name: "getActualizeOrderQuery",
  mapData: ({ result }) => result.data.data,
});

export const getOrderPaymentTypesQuery = createQuery({
  effect: getOrderPaymentTypesFx,
  name: "getOrderPaymentTypesQuery",
  mapData: ({ result }) => result.data.data,
});

export const refreshOrderPaymentTypesQuery = createQuery({
  effect: getOrderPaymentTypesFx,
  name: "refreshOrderPaymentTypesQuery",
  mapData: ({ result }) => result.data.data,
});

export const postEditOrderMutation = createMutation({
  effect: editOrderFx,
  name: "postEditOrderMutation",
});

export const getOrderPaymentDataQuery = createQuery({
  effect: gerOrderPaymentDataFx,
  name: "getOrderPaymentDataQuery",
  mapData: ({ result }) => result.data.data,
});

export const getOrderPaymentWithRedirectQuery = createQuery({
  effect: gerOrderPaymentDataFx,
  name: "getOrderPaymentWithRedirectQuery",
  mapData: ({ result }) => result.data.data,
});

export const postFixAvailableCashAmount = createMutation({
  effect: postAvailableCashAmountFx,
  name: "postFixAvailableCashAmount",
});

export const getOrdersQuery = createQuery({
  effect: getOrdersFx,
  name: "getOrdersQuery",
  mapData: ({ result }) => result.data.data,
});

export const cancelBookingMutation = createMutation({
  effect: cancelBookingFx,
  name: "cancelBookingMutation",
});

export const bookWithManagerMutation = createMutation({
  effect: bookWithManagerFx,
  name: "bookWithManagerMutation",
});

export const getOrderPaymentsDetailsQuery = createQuery({
  effect: getOrderPaymentsDetailsFx,
  name: "getOrderPaymentsDetailsQuery",
  mapData: ({ result }) => result.data.data,
});

export const createOrderMutation = createMutation({
  effect: createOrderFx,
  name: "createOrderMutation",
});

export const cancelOrderMutation = createMutation({
  effect: postCancelOrderFx,
  name: "cancelOrderMutation",
});

export const sendUserCreditHistoryQuery = createQuery({
  name: "sendUserCreditHistory",
  effect: postUserCreditHistoryFx,
  mapData: ({ result }) => result.data.data,
});

export const deleteUserCreditHistory = createMutation({
  name: "deleteUserCreditHistory",
  effect: deleteUserCreditHistoryFx,
});

export const postPayerInfoMutation = createMutation({
  effect: postPayerInfoFx,
  name: "postPayerInfoMutation",
});

export const postPassengerAsPayerMutation = createMutation({
  effect: postPassengerAsPayerFx,
  name: "postPassengerAsPayerMutation",
});

QueryTools(getHotelDetailsQuery).errors.handleAll();
QueryTools(getTouristContractQuery).errors.handleAll();
QueryTools(getOrderDetailsQuery).errors.handleAll();
QueryTools(getActualizeOrderQuery).errors.handleAll();
QueryTools(getOrderPaymentTypesQuery).errors.handleAll();
QueryTools(getOrderPaymentDataQuery).errors.handleAll();
QueryTools(getOrdersQuery).errors.handleAll();
QueryTools(getOrderPaymentsDetailsQuery).errors.handleAll();
QueryTools(sendUserCreditHistoryQuery).errors.handleAll();
