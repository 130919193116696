export enum EOrderPayment {
  Paid = "paid",
  Unpaid = "unpaid",
  Refunded = "refunded",
  PartlyPaid = "partly_paid",
  RefundPending = "refund_pending",
}

export enum EOrderStatus {
  Ready = "ready",
  Created = "created",
  Expired = "expired",
  Canceled = "canceled",
  Confirmed = "confirmed",
  Unconfirmed = "unconfirmed",
  CancelPending = "cancel_pending",
  ConfirmationPending = "confirmation_pending",
}

export enum ELangOptions {
  Rus = "RUS",
  Kaz = "KAZ",
}

export const LANGUAGE_OPTIONS = [
  {
    label: "RUSSIAN",
    value: ELangOptions.Rus,
  },
  {
    label: "KAZAKH",
    value: ELangOptions.Kaz,
  },
];
