import { mainTheme } from "../styles";

export const styles = {
  skeleton: {
    width: "100%",
    height: "100%",
  },

  singleCardList: {
    display: "flex",
    margin: "28px 0",
  },

  wrapper: (isSingleCard: boolean) => ({
    width: "100%",
    overflowX: isSingleCard ? "visible" : "auto",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  }),

  list: {
    width: "100%",
    display: "flex",
    margin: "28px 0",
    columnGap: "22px",

    [mainTheme.breakpoints.down("md")]: {
      padding: 0,
      gap: "16px",
      display: "grid",
      gridTemplateRows: "repeat(auto, 2fr)",
      gridTemplateColumns: "repeat(2, 2fr)",
    },
  },
};
