import { FC, useMemo } from "react";

import { Box, Avatar, SxProps } from "@mui/material";
import { useUnit } from "effector-react";

import { styles } from "./styles";

import { $userStore } from "@/shared/model/store/auth";

export type TProps = {
  size?: number;
  isMobile?: boolean;
  lettersSx?: SxProps;
  onClick?: () => void;
  avatarSrc: null | string | undefined;
};

export const UserAvatar: FC<TProps> = ({
  size,
  onClick,
  avatarSrc,
  isMobile = false,
  lettersSx = styles.lettersSx,
}) => {
  const userData = useUnit($userStore);

  const isClickable = onClick ? styles.clickable : styles.nonClickable;

  const imageSize = useMemo(() => {
    if (size) {
      return size;
    }

    if (isMobile && !size) {
      return "32px";
    }

    return "42px";
  }, [size, isMobile]);

  const dimensions = {
    width: imageSize,
    height: imageSize,
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        ...styles.avatar,
        ...isClickable,
        ...dimensions,
      }}
    >
      {avatarSrc ? (
        <Avatar
          alt={"Avatar"}
          src={avatarSrc}
          variant="square"
          sx={styles.avatarSx}
        />
      ) : (
        <Avatar sx={lettersSx} variant="square">
          {userData
            ? `${userData.first_name?.[0] ?? ""}${
                userData.last_name?.[0] ?? ""
              }`
            : "KT"}
        </Avatar>
      )}
    </Box>
  );
};
