import { FC } from "react";

import { Rating } from "@mui/material";
import toNumber from "lodash/toNumber";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { Badge } from "@/shared/ui";

type TProps = {
  rating: null | string | number;
};

export const HotelStarsRating: FC<TProps> = ({ rating }) => {
  const numericRating = toNumber(rating);
  const t = useTranslations();

  if (!isNaN(numericRating)) {
    return (
      <Rating
        readOnly
        size="small"
        sx={styles.rating}
        max={numericRating}
        value={numericRating}
      />
    );
  }

  return (
    <Badge size="small" sx={styles.categoryTag}>
      {rating ?? t("STAR_RATING_NOT_SPECIFIED")}
    </Badge>
  );
};
