import { stepClasses } from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { stepLabelClasses } from "@mui/material/StepLabel";
import Stepper, { stepperClasses } from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { COLORS } from "shared/ui/styles";

export const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 3,
    right: "calc(0%)",
    left: "calc(-100%)",
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderRadius: 1,
    borderTopWidth: 3,
    borderColor: COLORS.CLOUD,
  },
}));

export const StyledStepper = styled(Stepper)(() => ({
  [`&.${stepperClasses.root}`]: {
    [`& .${stepClasses.alternativeLabel}`]: {
      padding: 0,

      "&:last-of-type": {
        [`& .${stepLabelClasses.alternativeLabel}`]: {
          alignItems: "flex-end",
        },
      },
      "&:first-of-type": {
        [`& .${stepLabelClasses.alternativeLabel}`]: {
          alignItems: "flex-start",
        },
      },
    },
  },
}));

export const QontoStepIconRoot = styled("div")(() => ({
  height: 8,
  display: "flex",
  color: COLORS.CLOUD,
  alignItems: "center",

  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: COLORS.CLOUD,
  },
}));
