import { mainTheme } from "shared/ui/styles";

export const tabStyles = {
  secondary: {
    color: "content.secondary",
  },

  primary: {
    color: "accent.brand.primary",
  },

  common: {
    zIndex: 20,
    fontSize: "16px",
    transition: "0.2s",
    lineHeight: "18px",
    borderRadius: "8px",
    paddingBottom: "8px",
    textTransform: "none",
    backgroundColor: "unset",
  },
};

export const tabsStyles = {
  secondary: {
    color: "content.secondary",
    backgroundColor: "content.grey-100",

    ".Mui-selected": {
      color: `${mainTheme.palette.content.primary} !important`,
    },

    ".MuiTabs-indicator": {
      height: "100%",
      borderRadius: "8px",
      backgroundColor: "dominant.white",
    },
  },

  primary: {
    color: "red !important",
    backgroundColor: "accent.brand.lightest",

    ".Mui-selected": {
      color: `${mainTheme.palette.dominant.white} !important`,
    },

    ".MuiTabs-indicator": {
      height: "100%",
      borderRadius: "8px",
      backgroundColor: "accent.brand.primary",
    },
  },

  common: {
    width: "100%",
    height: "42px",
    display: "flex",
    padding: "3px 6px",
    borderRadius: "8px",
    alignItems: "center",

    ".Mui-selected": {
      color: "content.primary !important",
    },

    ".MuiTabs-indicator": {
      height: "100%",
      borderRadius: "8px",
    },

    "& button": {
      padding: "0",
      height: "36px",
      minHeight: "unset",
    },
  },
};
