const FILE_SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
const DEFAULT_FILE_DIVIDER = 1000;

export const formatFileSize = (bytes: number, decimalPoint = 2) => {
  if (bytes == 0) return "N/A";

  const k = DEFAULT_FILE_DIVIDER,
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint))} ${
    FILE_SIZES[i]
  }`;
};
