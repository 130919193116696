import { forwardRef } from "react";

import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button } from "@mui/material";

import { styles } from "./styles";

type Ref = HTMLButtonElement;

export type TProps = {
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  arrow: "left" | "right";
};

export const SliderButton = forwardRef<Ref, TProps>((props, ref) => (
  <Button sx={styles.arrow} variant="outlined" {...props} ref={ref}>
    {props.arrow === "left" ? <ChevronLeft /> : <ChevronRight />}
  </Button>
));
