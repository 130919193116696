export const styles = {
  value: {
    whiteSpace: "nowrap",
  },

  label: {
    whiteSpace: "nowrap",
  },

  dots: {
    width: "100%",
    paddingTop: "12px",
    borderBottom: "1px dotted",
  },

  infoText: {
    fontSize: "16px",
    lineHeight: "18px",
    color: "content.secondary",
  },

  wrapper: {
    rowGap: "10px",
    display: "flex",
    margin: "16px 0 0 0",
    flexDirection: "column",
    color: "content.grey-400",
    backgroundColor: "dominant.main",
    borderColor: "accent.brand.lightest",
  },

  singleLine: (isTotal: boolean) => ({
    display: "flex",
    columnGap: "4px",
    fontSize: "16px",
    lineHeight: "18px",
    alignItems: "center",
    fontWeight: isTotal ? 500 : 400,
    justifyContent: "space-between",
    color: isTotal ? "content.primary" : "unset",
  }),
};
