import { createApi, createStore } from "effector";

import { TDepartureLocation } from "../../types/header-menu";

export const $departure = createStore<null | TDepartureLocation>(null);
export const $departureFilter = createStore<string>("");
export const $isDepartureError = createStore<boolean>(false);

export const { setDeparture, resetDeparture } = createApi($departure, {
  resetDeparture: () => null,
  setDeparture: (_, payload: null | TDepartureLocation) => payload,
});

export const { setDepartureError, resetDepartureError } = createApi(
  $isDepartureError,
  {
    setDepartureError: () => true,
    resetDepartureError: () => false,
  },
);

export const { setDepartureFilter, resetDepartureFilter } = createApi(
  $departureFilter,
  {
    resetDepartureFilter: () => "",
    setDepartureFilter: (_, payload: string) => payload,
  },
);
