import { useParams } from "next/navigation";

import { EOrderQuery } from "@/widgets/orders/OrderLayout/lib/constants";

export const useGetOrderNumber = () => {
  const params = useParams();

  const orderNumber = params?.[EOrderQuery.OrderNumber];

  if (!orderNumber) {
    return { orderNumber: "" };
  }

  if (Array.isArray(orderNumber)) {
    return { orderNumber: orderNumber[0] };
  }

  return { orderNumber };
};
