import Image from "next/image";
import { FC } from "react";

import { Box } from "@mui/material";
import { useUnit } from "effector-react";

import { styles } from "./styles";

import { APP_LINKS } from "@/shared/lib/constants";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { Link } from "@/shared/ui";

type TProps = {
  osName?: "ios" | "android";
};

const AppDownloadButton: FC<TProps> = ({ osName }) => {
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const handleAppDownloadClick = (link: string) => {
    sendDataLayerEventFn({
      event: "appDownload",
      data: {
        clickUrl: link,
      },
    });
  };

  return (
    <Box sx={styles.linkButtonWrapper}>
      <Link
        target="_blank"
        style={styles.linkButton}
        href={osName ? APP_LINKS[osName].link : "#"}
        onClick={() =>
          handleAppDownloadClick(osName ? APP_LINKS[osName].link : "#")
        }
      >
        <Image
          width={135}
          height={40}
          alt={osName ?? "platform"}
          src={osName ? APP_LINKS[osName].image : ""}
          style={{
            height: "auto",
            maxWidth: "100%",
          }}
        />
      </Link>
    </Box>
  );
};

export const AppDownloadButtons: FC<TProps> = ({ osName }) => {
  if (osName) {
    return <AppDownloadButton osName={osName} />;
  }
  return (
    <Box sx={styles.buttonsWrapper}>
      <AppDownloadButton osName="ios" />
      <AppDownloadButton osName="android" />
    </Box>
  );
};
