import { mainTheme } from "shared/ui/styles";

export const styles = {
  leftGrid: {
    width: "100%",
    marginRight: "auto",
  },

  rightGrid: {
    margin: "0 0 50px 0",

    [mainTheme.breakpoints.down("md")]: {
      margin: "0",
    },
  },
};
