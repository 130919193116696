import {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  RefAttributes,
  PropsWithoutRef,
  ForwardRefExoticComponent,
} from "react";

import { Close } from "@mui/icons-material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, IconButton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import cm from "classnames";
import { Swiper as SwiperTypes } from "swiper";
import { Controller } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { TMedia, THotelImages } from "@/shared/model/types/common";
import { Image, Container, SliderButton } from "@/shared/ui";
import { VideoPlayer } from "@/shared/ui/VideoPlayer";

const SLIDE_WIDTH = "100%";
const SLIDE_MOBILE_HEIGHT = "207px";

const ARROW_RIGHT_KEY_CODE = 39;
const ARROW_LEFT_KEY_CODE = 37;

type TTourSliderProps = {
  onClose?: () => void;
  mediaArray?: TMedia[];
  initialSlide?: number;
  images?: THotelImages[];
};

const getSwiperSlides = (
  mediaArray: TMedia[],
  height: string,
  isDesktop: boolean,
) => {
  return mediaArray.map((media, index) => {
    if (media.videoLink) {
      return (
        <SwiperSlide key={index}>
          <VideoPlayer
            controls={true}
            width={SLIDE_WIDTH}
            url={media.videoLink}
            sxWrapper={styles.image}
            height={isDesktop ? "100%" : height}
          />
        </SwiperSlide>
      );
    } else {
      return (
        <SwiperSlide key={index} style={styles.swiperSlide}>
          <Image
            rounded
            alt="image"
            src={media.src}
            imageWrapperSx={styles.image}
          />
        </SwiperSlide>
      );
    }
  });
};

export const TourSlider: ForwardRefExoticComponent<
  PropsWithoutRef<TTourSliderProps> & RefAttributes<HTMLDivElement>
> = forwardRef(({ onClose, mediaArray = [], initialSlide = 0 }, ref) => {
  const [swiper, setSwiper] = useState<null | SwiperTypes>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<null | SwiperTypes>(null);
  const [swiperRealIndex, setSwiperRealIndex] = useState(initialSlide);

  const theme = useCustomTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const onRealIndexChange = useCallback(
    (swiper: SwiperTypes) => setSwiperRealIndex(swiper.realIndex),
    [],
  );

  const onThumbSlideClick = useCallback(
    (slideIndex: number) => swiper && swiper.slideTo(slideIndex || 0),
    [swiper],
  );

  const mainSwiperProps = useMemo(
    () => ({
      initialSlide,
      slidesPerView: 1,
      spaceBetween: 32,
      onRealIndexChange,
      onSwiper: setSwiper,
      modules: [Controller],
      controller: {
        control: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
      },
    }),
    [initialSlide, onRealIndexChange, thumbsSwiper],
  );

  const thumbSwiperProps = useMemo(
    () => ({
      threshold: 2,
      spaceBetween: 12,
      modules: [Controller],
      onSwiper: setThumbsSwiper,
      watchSlidesProgress: true,
      initialSlide: initialSlide,
      slidesPerView: isDesktop ? 8 : isTablet ? 4 : 3,
    }),
    [initialSlide, isDesktop, isTablet],
  );

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (swiper && e.keyCode === ARROW_RIGHT_KEY_CODE) {
        e.preventDefault();
        swiper.slideNext();
      }

      if (swiper && e.keyCode === ARROW_LEFT_KEY_CODE) {
        e.preventDefault();
        swiper.slidePrev();
      }
    };

    document.addEventListener("keydown", keydownHandler);

    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [swiper]);

  const slides = getSwiperSlides(mediaArray, SLIDE_MOBILE_HEIGHT, isDesktop);

  return (
    <Container ref={ref} sx={styles.mainContainer}>
      <IconButton
        onClick={onClose}
        sx={styles.closeButton}
        aria-label="close-modal"
      >
        <Close />
      </IconButton>
      <Box sx={styles.counter}>
        <Typography>{swiperRealIndex + 1}</Typography>
        <Typography>/</Typography>
        <Typography>{mediaArray.length}</Typography>
      </Box>
      <Box
        sx={
          isDesktop
            ? {
                ...styles.swiperContainer,
                ...styles.mainSwiperContainer,
              }
            : { ...styles.mobileSwiperContainer }
        }
      >
        <Condition match={isDesktop}>
          <SliderButton
            arrow={"left"}
            disabled={swiperRealIndex === 0}
            onClick={() => swiper && swiper.slidePrev()}
          />
        </Condition>
        <Condition match={!isDesktop}>
          <NavigateBeforeIcon
            fontSize="large"
            onClick={() => swiper && swiper.slidePrev()}
            sx={styles.mainSwiperContainer.mobileButtons}
          />
        </Condition>

        <Swiper
          {...mainSwiperProps}
          initialSlide={mainSwiperProps.initialSlide}
          keyboard={{
            enabled: true,
            onlyInViewport: true,
          }}
          controller={{
            control: mainSwiperProps.controller.control ?? undefined,
          }}
        >
          {slides}
        </Swiper>

        <Condition match={!isDesktop}>
          <NavigateNextIcon
            fontSize="large"
            onClick={() => swiper && swiper.slideNext()}
            sx={{
              ...styles.mainSwiperContainer.mobileButtons,
              right: "17.5px",
            }}
          />
        </Condition>
        <Condition match={isDesktop}>
          <SliderButton
            arrow={"right"}
            onClick={() => swiper && swiper.slideNext()}
            disabled={swiperRealIndex === mediaArray.length - 1}
          />
        </Condition>
      </Box>

      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*@ts-expect-error*/}
      <Box sx={({ ...styles.swiperContainer }, { ...styles.thumbContainer })}>
        <Swiper {...thumbSwiperProps}>
          {mediaArray.map((media, idx) =>
            media.videoLink ? (
              <SwiperSlide
                key={idx}
                onClick={() => onThumbSlideClick(idx)}
                className={cm({
                  "active-thumb-slide": idx === swiperRealIndex,
                })}
              >
                <VideoPlayer
                  width={"100%"}
                  isMobile={true}
                  playing={false}
                  controls={false}
                  url={media.videoLink}
                  showPlayButton={false}
                  height={isDesktop ? "100%" : "110px"}
                  sxWrapper={styles.disablePlayerOverlay}
                />
              </SwiperSlide>
            ) : (
              <SwiperSlide
                key={idx}
                onClick={() => onThumbSlideClick(idx)}
                className={cm({
                  "active-thumb-slide": idx === swiperRealIndex,
                })}
              >
                <Image
                  alt="image"
                  rounded="sm"
                  height={111}
                  gradient={false}
                  src={media.src ?? ""}
                  imageWrapperSx={{ ...styles.image, ...styles.thumbImage }}
                />
              </SwiperSlide>
            ),
          )}
        </Swiper>
      </Box>
    </Container>
  );
});

TourSlider.displayName = "TourSlider";
