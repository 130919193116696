export type TRoundedProps = "lg" | "md" | "sm" | boolean;

export const getImageRoundingSize = (size: TRoundedProps) => {
  switch (size) {
    case "sm":
      return 4;
    case "md":
      return 6;
    default:
      return 8;
  }
};
