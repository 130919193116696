export const styles = {
  button: {
    display: "flex",
    columnGap: "5px",
  },

  text: {
    fontSize: "16px",
    lineHeight: "18px",
    marginBottom: "12px",
    color: "content.secondary",
  },
};
