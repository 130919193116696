import { FC } from "react";

import { Box } from "@mui/material";
import "dayjs/locale/kk";
import "dayjs/locale/ru";
import { useTranslations } from "next-intl";

import { TAmenitiesProps } from "../model/types";
import { styles } from "./styles";

import { SingleAmenity } from "@/shared/ui";

export const AmenitiesComponent: FC<TAmenitiesProps> = ({
  isMobile,
  amenitiesContent,
}) => {
  const t = useTranslations();

  return (
    <Box sx={styles.tourPackage}>
      <Box sx={styles.tourPackage.heading}>{t("TOUR_PRICE_INCLUDES")}:</Box>
      <Box sx={styles.tourPackage.details}>
        {amenitiesContent.map(({ type, value, isHiddenOnMobile }, index) => {
          if (isHiddenOnMobile && isMobile) {
            return null;
          }

          return (
            <SingleAmenity
              key={index}
              type={type}
              value={value ?? "-"}
              isDesktop={!isMobile}
              size={isMobile ? 32 : 24}
            />
          );
        })}
      </Box>
    </Box>
  );
};
