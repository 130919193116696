import { FC } from "react";

import { useTranslations } from "next-intl";

import { PaymentModalContent } from "../PaymentModalContent";
import { styles } from "./styles";

import { DialogWrapper, SwipeableDrawer } from "@/shared/ui";

type TProps = {
  isOpen: boolean;
  isPhone: boolean;
  onClose: () => void;
};

export const PaymentsDetailsModal: FC<TProps> = ({
  isOpen,
  isPhone,
  onClose,
}) => {
  const t = useTranslations();

  if (isPhone) {
    return (
      <SwipeableDrawer
        isOpen={isOpen}
        onClose={onClose}
        onOpen={() => null}
        hideBackdrop={false}
        title={t("PAYMENT_DETAILS")}
        paperSx={styles.swipeableDrawerPaper}
        childrenWrapperSx={styles.swipeableDrawerChildrenWrapper}
      >
        <PaymentModalContent />
      </SwipeableDrawer>
    );
  }

  return (
    <DialogWrapper
      open={isOpen}
      onClose={onClose}
      title={t("PAYMENT_DETAILS")}
      sxTitle={styles.dialogWrapperTitle}
      WrapperProps={styles.dialogWrapperProps}
      PaperProps={{ sx: styles.dialogWrapperPaper }}
    >
      <PaymentModalContent />
    </DialogWrapper>
  );
};
