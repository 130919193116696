import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import { useTranslations } from "next-intl";

import { useGetDeclinations } from "./useGetDeclinations";

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);

type TIncomingData = {
  isInput?: boolean;
  endDate?: null | string;
  startDate?: null | string;
};

const DATE_FORMAT = "D MMMM";

const generateDatesText = ({
  texts,
  endDate,
  startDate,
  isSameMonth,
  isInput = false,
}: Omit<TIncomingData, "isDayDisabled"> & {
  isSameMonth: boolean;
  texts: { emptyEnd: string; emptyStart: string };
}) => {
  const { emptyEnd, emptyStart } = texts;

  if (!startDate && !endDate) {
    return "";
  }

  if (!startDate) {
    return isInput ? "" : emptyStart;
  }

  if (!endDate) {
    return isInput ? dayjs(startDate).format(DATE_FORMAT) : emptyEnd;
  }

  if (startDate && endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    return `${start.format(`D${isSameMonth ? "" : " MMMM"}`)} - ${end.format(
      DATE_FORMAT,
    )}`;
  }

  return "-";
};

// TODO: temp
export const useGetCalendarTexts = ({
  endDate,
  startDate,
  isInput = false,
}: TIncomingData) => {
  const start = dayjs(startDate);
  const end = endDate ? dayjs(endDate) : null;

  const isSameMonth = start.isSame(end, "month");
  const nights = end ? end.diff(start, "days") : null;

  const t = useTranslations();
  const { daysText } = useGetDeclinations({
    nights: nights ?? undefined,
  });

  const nightsText = nights && nights > 0 ? daysText : "";

  const datesText = generateDatesText({
    isInput,
    endDate,
    startDate,
    isSameMonth,
    texts: {
      emptyStart: t("SELECT_ARRIVAL_DATE"),
      emptyEnd: t("SELECT_DEPARTURE_DATE"),
    },
  });

  const combinedText = `${datesText}${nightsText ? ` (${nightsText})` : ""}`;

  return {
    datesText,
    nightsText,
    combinedText,
  };
};
