export const styles = {
  dollarIcon: {
    padding: "0 2px",
  },

  image: {
    height: "auto",
    maxWidth: "100%",
  },

  amenitiesInfo: {
    display: "flex",
    flexDirection: "column",
  },

  icon: {
    width: "20px",
    height: "20px",
    minWidth: "20px",
  },

  amenitiesDescription: {
    opacity: 0.5,
    fontSize: "14px",
    marginTop: "6px",
    color: "content.secondary",
  },

  wrapper: {
    gap: "8px",
    display: "flex",
    fontSize: "16px",
    lineHeight: "18px",
    alignItems: "start",
  },
};
