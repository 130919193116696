import { FC } from "react";

import { Step } from "@mui/material";
import { useTranslations } from "next-intl";

import {
  StyledStepper,
  StyledStepIcon,
  StyledStepLabel,
  ProgressStepperWrapper,
} from "./styles";

const STEPS = [
  "TOUR_SELECTION_2",
  "TOUR_DETAILS",
  "TOURIST_DATA",
  "PAYMENT_METHOD",
];

type TProps = {
  activeStep: number;
};

export const ProgressStepper: FC<TProps> = ({ activeStep }) => {
  const t = useTranslations();

  return (
    <ProgressStepperWrapper>
      <StyledStepper activeStep={activeStep - 1}>
        {STEPS.map((label) => {
          return (
            <Step key={label}>
              <StyledStepLabel StepIconComponent={StyledStepIcon}>
                {t(label)}
              </StyledStepLabel>
            </Step>
          );
        })}
      </StyledStepper>
    </ProgressStepperWrapper>
  );
};
