import Cookies from "js-cookie";
import { AbstractIntlMessages } from "next-intl";
import { createTranslator } from "next-intl";
import { getLocale } from "next-intl/server";

import { isClient } from "../isClient";

import { AVAILABLE_LOCALES } from "@/shared/config/locales/config";

export const getAppMessages = async (locale?: string) =>
  (await import(`@/shared/lib/locales/${locale ?? AVAILABLE_LOCALES[0]}.json`))
    ?.default as AbstractIntlMessages;

// TODO: locales issue
export const getAppLocale = async () => {
  return (
    (isClient ? Cookies.get("NEXT_LOCALE") : await getLocale()) ??
    AVAILABLE_LOCALES[0]
  );
};

export const getLangString = (locale?: string) =>
  locale === "kz" ? "kk" : "ru";

// TODO: move logic to effector
let messages = {};

export const getTranslateFn = async () => {
  const locale = await getAppLocale();

  messages = Object.keys(messages).length
    ? messages
    : await getAppMessages(locale);
  const t = createTranslator({
    messages,
    locale: locale,
  });

  return t;
};
