import { FC } from "react";

import { ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { styles } from "./styles";

import { MENU_PLACEHOLDER_IMAGE } from "@/shared/lib/constants";
import { EOrderStatus, EOrderPayment } from "@/shared/lib/constants/orders";
import {
  priceFormatter,
  getConditionalDatesText,
  generateLinkAccordingToOrderStatus,
} from "@/shared/lib/helpers";
import { useGetDeclinations } from "@/shared/lib/hooks";
import { closeProfileAndLinksDrawer } from "@/shared/model/store/navbar";
import { TOrderResponseData } from "@/shared/model/types/orders";
import {
  Link,
  Image,
  PaymentStatusBadge,
  RoundedBorderContainer,
} from "@/shared/ui";

type TProps = {
  latestOrder: null | TOrderResponseData;
};

export const LatestOrderCard: FC<TProps> = ({ latestOrder }) => {
  const t = useTranslations();

  const { passengersText, nightsTotalText } = useGetDeclinations({
    adults: latestOrder?.details.adults,
    nights: latestOrder?.details.nights,
    nightsTotal: latestOrder?.details.nights_total,
    children:
      Number(latestOrder?.details.children) +
      Number(latestOrder?.details.infants),
  });

  const closeProfileAndLinksDrawerFn = useUnit(closeProfileAndLinksDrawer);

  if (!latestOrder) {
    return null;
  }

  const { number, details, order_status, payment_status } = latestOrder;

  const linkDetails = generateLinkAccordingToOrderStatus({
    orderNumber: number,
    isExpired: order_status.code === EOrderStatus.Expired,
    areAllPassengersFilled:
      details.passengers.length ===
      details.children + details.infants + details.adults,
    isOrderPaid:
      payment_status.code === EOrderPayment.Paid ||
      order_status.code === EOrderStatus.Ready ||
      order_status.code === EOrderStatus.Confirmed,
  });

  return (
    <RoundedBorderContainer sx={styles.wrapper}>
      <Box sx={styles.header}>
        <Typography
          sx={styles.orderNumber}
        >{`${t("ORDER")} №${number}`}</Typography>
        <PaymentStatusBadge
          label={payment_status.name}
          status={payment_status.code}
        />
      </Box>
      <Box sx={styles.content}>
        <Image
          rounded
          width={64}
          height={64}
          alt={details.hotel.name}
          src={details.hotel.cover}
          imageWrapperSx={{ height: "64px" }}
          placeholder={MENU_PLACEHOLDER_IMAGE}
        />
        <Box>
          <Typography sx={styles.hotelTitle}>{details.hotel.name}</Typography>
          <Typography sx={styles.text}>
            {getConditionalDatesText({
              checkOut: details.date_to,
              checkIn: details.date_from,
              nightsText: nightsTotalText,
            })}
          </Typography>
          <Typography sx={styles.text}>{passengersText}</Typography>
        </Box>
      </Box>
      <Box sx={styles.footer}>
        {priceFormatter(details.price)}

        {linkDetails && (
          <Link href={linkDetails.href} onClick={closeProfileAndLinksDrawerFn}>
            <Box sx={styles.linkBox}>
              <Typography sx={styles.link}>{t(linkDetails.text)}</Typography>
              <ArrowForward fontSize="small" />
            </Box>
          </Link>
        )}
      </Box>
    </RoundedBorderContainer>
  );
};
