import { mainTheme } from "@/shared/ui/styles/theme/mainTheme";

export const styles = {
  disablePlayerOverlay: {
    pointerEvents: "none",
  },

  slide: {
    top: 0,
    left: 0,
    position: "relative",
  },

  thumgImageMobile: {
    height: "85px",
    borderRadius: "8px",
  },

  swiperContainer: {
    margin: "0 auto",
    maxWidth: "1072px",
  },

  thumbImage: {
    height: 111,
    with: "100%",
    borderRadius: "8px",
  },

  closeButton: {
    top: "10px",
    right: "10px",
    position: "absolute",
    color: "dominant.white",
  },

  swiperSlide: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  mobileSwiperContainer: {
    display: "flex",
    alignItems: "center",

    "& .swiper": {
      width: "100%",
    },
  },

  playerOverlay: {
    top: 0,
    left: 0,
    zIndex: 1000,
    width: "200px",
    height: "120px",
    background: "red",
    position: "absolute",
  },

  image: {
    height: 676,
    maxWidth: "100%",
    userSelect: "none",
    borderRadius: "16px",
    WebkitUserDrag: "none",
    objectPosition: "center",

    [mainTheme.breakpoints.down("md")]: {
      height: 350,
      borderRadius: "8px",
    },
  },

  mainContainer: {
    outline: "none",
    overflow: "hidden",
    margin: "2rem auto",
    height: "calc(100% - 4rem)",

    [mainTheme.breakpoints.down("md")]: {
      display: "flex",
      bottom: "inherit",
      position: "absolute",
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  counter: {
    fontWeight: 400,
    display: "flex",
    fontSize: "22px",
    columnGap: "4px",
    lineHeight: "26px",
    textAlign: "center",
    alignItems: "center",
    color: "dominant.cloud",
    justifyContent: "center",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "16px",
      paddingBottom: "16px",
    },
  },
  mainSwiperContainer: {
    flex: 1,
    display: "flex",
    columnGap: "12px",
    marginTop: "10px",
    maxWidth: "1212px",
    alignItems: "center",
    height: "calc(100% - 120px - 24px - 10px - 26px)",
    mobileButtons: {
      zIndex: "2",
      position: "absolute",
      color: "dominant.white",
    },

    "& .swiper": {
      width: "100%",
      height: "100%",
      maxWidth: "1070px",
      maxHeight: "676px",
    },
  },

  thumbContainer: {
    height: "110px",
    marginTop: "24px",
    "& .swiper": {
      height: "100%",
    },
    "& .active-thumb-slide > img ": {
      outlineColor: mainTheme.palette.accent.brand.primary,
    },
    "& .swiper-slide:not(.active-thumb-slide) > img:hover": {
      boxShadow: "0px 0px 0px 4px rgba(29, 133, 255, 0.2)",
    },
    "& .swiper-slide": {
      cursor: "pointer",
      "@media screen and (max-width: 980px)": {
        height: "fit-content",
      },
    },
    "& .swiper-slide > img": {
      outlineOffset: "-3.5px",
      outline: "4px solid transparent",
      transition: "outline .15s ease, box-shadow .15s ease",
    },
  },
};
