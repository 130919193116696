import { FC, memo } from "react";

import { Box } from "@mui/material";

import { styles } from "./styles";

type TProps = {
  errorMessage?: string;
};

export const ErrorMessage: FC<TProps> = memo(({ errorMessage }) => {
  return !!errorMessage && <Box sx={styles.error}>{errorMessage}</Box>;
});
