export const styles = {
  backdrop: {
    background: "rgba(2, 57, 118, 0.2)",
  },

  container: {
    left: "0px",
    gap: "16px",
    bottom: "0px",
    display: "flex",
    width: "stretch",

    position: "absolute",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px 16px 24px",

    borderRadius: "16px 16px 0px 0px",
    backgroundColor: "dominant.white",
    boxShadow: "0px -1px 1px rgba(164, 179, 190, 0.25)",

    heading: {
      display: "flex",
      width: "stretch",
      justifyContent: "space-between",
    },
  },
};
