type TWrapperArgs = {
  order?: number;
  fullInputWidth?: boolean;
  position: "fixed" | "static" | "absolute" | "relative";
};

export const styles = {
  wrapperStyles: ({ position, fullInputWidth }: TWrapperArgs) => ({
    position,
    width: fullInputWidth ? "100%" : "unset",
  }),

  modal: {
    isOpen: {
      opacity: 100,
      display: "block",
    },

    common: {
      left: 0,
      right: 0,
      zIndex: 2,
      opacity: 0,
      width: "100%",
      display: "none",
      transition: "0.4s",
      position: "absolute",
    },
  },

  default: {
    isOpen: {
      opacity: 100,
      marginTop: "0px",
      visibility: "visible",
    },

    common: {
      left: 0,
      right: 0,
      zIndex: 2,
      opacity: 0,
      marginTop: "20px",
      transition: "0.4s",
      position: "absolute",
      visibility: "hidden",
    },
  },
};
