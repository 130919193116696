import { FC, useRef, useEffect, useCallback } from "react";
import ReactInputMask from "react-input-mask";

import { Box } from "@mui/material";

import { styles } from "./styles";
import type { TInput, TInputMask } from "./types";

import { Input } from "@/shared/ui";

export const InputMask: FC<TInputMask> = (props) => {
  const {
    mask = "",
    value = "",
    label = "",
    sxInput = {},
    type = "text",
    onBlur = () => null,
    specifiedPosition = 0,
    onChange = () => null,
    focusOnSpecifiedPosition = false,
    ...otherProps
  } = props;

  const inputRef = useRef<null | HTMLInputElement>(null);

  const handleInputChange = useCallback(
    (value: string | undefined) => {
      onChange(value);
    },
    [onChange],
  );

  const handleInputBlur = useCallback(
    (value: string | undefined) => {
      onBlur(value);
    },
    [onBlur],
  );

  const handleClick = () => {
    inputRef.current?.setSelectionRange(specifiedPosition, specifiedPosition);
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (!inputRef.current) return;

    if (focusOnSpecifiedPosition) {
      inputRef.current.addEventListener("click", handleClick, { once: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.inputWrapper}>
      {label && <label>{label}</label>}
      <ReactInputMask
        type={type}
        mask={mask}
        value={value}
        alwaysShowMask
        onBlur={() => handleInputBlur(inputRef.current?.value)}
        onChange={() => handleInputChange(inputRef.current?.value)}
        {...otherProps}
      >
        {(inputProps: TInput) => (
          <Input
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            sxInput={sxInput}
            disabled={props.disabled}
            //@ts-expect-error
            ref={(ref: null | HTMLInputElement) => {
              inputRef.current = ref;
            }}
            {...inputProps}
          />
        )}
      </ReactInputMask>
    </Box>
  );
};
