import { FC, memo, useState, useCallback } from "react";

import { ExpandMore } from "@mui/icons-material";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles, selectStyles } from "./styles";
import type { TMultiSelect, TMultiSelectTrigger } from "./types";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { List, Input, Popover, ListItem, CustomCheckbox } from "@/shared/ui";

const Trigger: FC<TMultiSelectTrigger> = memo(
  ({ id, open, label, value, onClick, disabled, triggerSx, placeholder }) => {
    return (
      <Box sx={styles.selectBox}>
        {label && <label>{label}</label>}

        <Input
          id={id}
          readOnly
          value={value}
          focused={open}
          onClick={onClick}
          disabled={disabled}
          inputWrapper={triggerSx}
          placeholder={placeholder}
          endAdornment={<ExpandMore fontSize="small" />}
          sxWrapper={styles.selectBox.inputWrapperStyles}
        />
      </Box>
    );
  },
);

export const MultiSelect: FC<TMultiSelect> = memo(
  ({
    id,
    value = [],
    label = "",
    sxList = {},
    options = [],
    triggerSx = {},
    sxListItem = {},
    disabled = false,
    placeholder = "",
    isInModal = false,
    onChange = () => null,
  }) => {
    const t = useTranslations();
    const theme = useCustomTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down("md"));

    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(
      () => !disabled && setOpen(true),
      [disabled],
    );
    const handleClose = useCallback(() => setOpen(false), []);
    const handleChange = useCallback(
      (value: string | number) => {
        onChange(value);
      },
      [onChange],
    );

    const triggerText = options
      .filter((option) => value.includes(option.value))
      .map(({ label }) => label)
      .join(", ");

    return (
      <Popover
        open={open}
        isInModal={isInModal}
        boxPosition="relative"
        closePopover={handleClose}
        popoverStyles={!isInModal || isPhone ? { marginTop: "12px" } : {}}
        trigger={
          <Trigger
            id={id}
            open={open}
            label={label}
            disabled={disabled}
            onClick={handleOpen}
            triggerSx={triggerSx}
            value={t(triggerText)}
            placeholder={placeholder}
          />
        }
      >
        <List sx={[selectStyles.list, sxList]}>
          {options.map((item, index) => (
            <ListItem
              sx={sxListItem}
              key={item.value}
              value={item.value}
              id={`${id}_${index}_test`}
              onClick={() => handleChange(item.value)}
            >
              <CustomCheckbox
                sx={{ marginRight: 0 }}
                checked={value.includes(item.value)}
              />
              {t(item.label)}
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  },
);
