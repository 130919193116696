import { Url } from "next/dist/shared/lib/router/router";
import { FC, MouseEventHandler } from "react";

import * as CSS from "csstype";

import { NextIntlLink } from "@/shared/config/locales/navigation";

type TProps = {
  id?: string;
  href: Url | string;
  passHref?: boolean;
  type?: "a" | "link";
  style?: CSS.Properties;
  legacyBehavior?: boolean;
  children: string | number | JSX.Element;
  target?: "_top" | "_self" | "_blank" | "_parent";
  onClick?: undefined | MouseEventHandler<HTMLAnchorElement>;
};

export const Link: FC<TProps> = ({
  id,
  href,
  style,
  onClick,
  passHref,
  children,
  type = "link",
  target = "_self",
  legacyBehavior = false,
}) => {
  const commonLinkProps = {
    id,
    style,
    target,
    onClick: onClick,
    className: "default-link",
    rel: target === "_blank" ? "noreferrer" : undefined,
  };

  if (type === "a") {
    return (
      <a href={href as string} {...commonLinkProps}>
        {children}
      </a>
    );
  }

  return (
    <NextIntlLink
      href={href}
      {...commonLinkProps}
      passHref={passHref}
      legacyBehavior={legacyBehavior}
    >
      {children}
    </NextIntlLink>
  );
};
