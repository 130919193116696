import { mainTheme } from "../styles";

export const styles = {
  linkWrapper: {
    width: "100%",
    color: "unset",
    textDecoration: "none",
  },

  title: {
    fontSize: "20px",
    lineHeight: "24px",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },

  image: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
    userSelect: "none",
    objectFit: "cover",
    WebkitUserDrag: "none",
    objectPosition: "center",
  },

  imageWrapper: {
    height: "310px",
    overflow: "hidden",
    borderRadius: "8px",
    position: "relative",

    [mainTheme.breakpoints.down("md")]: {
      height: "164px",
    },
  },

  wrapper: {
    width: "100%",
    color: "unset",
    height: "100%",
    cursor: "pointer",
    transition: "0.2s",
    position: "relative",
    textDecoration: "none",

    "&:hover img": {
      transform: "scale(1.1)",
    },
  },

  textWrapper: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    padding: "16px",
    display: "flex",
    position: "absolute",
    alignItems: "flex-end",
    color: "dominant.white",
    background:
      "linear-gradient(180deg, rgba(255,255,255,0.07) 40%, rgba(10,10,10,0.7) 100%)",

    [mainTheme.breakpoints.down("md")]: {
      padding: "10px",
      background:
        "linear-gradient(180deg, rgba(255,255,255,0.1) 10%, rgba(10,10,10,0.7) 100%)",
    },
  },
};
