/* eslint-disable perfectionist/sort-objects */
import { createMutation } from "@farfetched/core";
import { sample } from "effector";

import {
  postLogoutFx,
  postAuthOTPFx,
  postSignUpCompleteFx,
  postAuthOTPConfirmationFx,
} from "./endpoints";

import { MutationTools } from "@/shared/config";

export const sendOTPMutation = createMutation({
  effect: postAuthOTPFx,
  name: "sendOTP",
});

export const confirmOTPMutation = createMutation({
  effect: postAuthOTPConfirmationFx,
  name: "confirmOTP",
});

export const signUpMutation = createMutation({
  effect: postSignUpCompleteFx,
  name: "signUp",
});

export const logoutMutation = createMutation({
  effect: postLogoutFx,
  name: "logout",
});

export const authCompleted = sample({
  clock: [signUpMutation.finished.success, confirmOTPMutation.finished.success],
  filter: ({ result }) => !!result?.data?.data?.finished,
});

MutationTools(sendOTPMutation).errors.handleAll();
MutationTools(confirmOTPMutation).errors.handleAll();
MutationTools(signUpMutation).errors.handleAll();
