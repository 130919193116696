import { forwardRef } from "react";

import { SxProps, Checkbox, FormControlLabel } from "@mui/material";
import {
  CheckboxPropsSizeOverrides,
  CheckboxPropsColorOverrides,
} from "@mui/material/Checkbox/Checkbox";
import { OverridableStringUnion } from "@mui/types";

import SearchFilterCheckedIcon from "@/public/icons/inputs/checked.svg";
import SearchFilterUncheckedIcon from "@/public/icons/inputs/unchecked.svg";

type TCustomCheckboxProps = {
  id?: string;
  sx?: SxProps;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  fieldState?: object;
  onChange?: () => void;
  checkedIcon?: null | JSX.Element;
  unCheckedIcon?: null | JSX.Element;
  size?: OverridableStringUnion<"small" | "medium", CheckboxPropsSizeOverrides>;
  color?: OverridableStringUnion<
    | "info"
    | "error"
    | "warning"
    | "success"
    | "primary"
    | "default"
    | "secondary",
    CheckboxPropsColorOverrides
  >;
};

// eslint-disable-next-line react/display-name
export const CustomCheckbox = forwardRef(
  (
    {
      id,
      sx = {},
      onChange,
      label = "",
      checkedIcon,
      unCheckedIcon,
      checked = false,
      size = "medium",
      disabled = false,
      color = "primary",
      ...rest
    }: TCustomCheckboxProps,
    ref,
  ) => {
    return (
      <FormControlLabel
        id={id}
        sx={sx}
        ref={ref}
        label={label}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        control={
          <Checkbox
            size={size}
            color={color}
            icon={unCheckedIcon ?? <SearchFilterUncheckedIcon />}
            checkedIcon={checkedIcon ?? <SearchFilterCheckedIcon />}
          />
        }
        {...rest}
      />
    );
  },
);
