import { mainTheme } from "shared/ui/styles";

export const styles = {
  logoWrapper: {
    maxWidth: "125px",
  },

  arrowBack: {
    color: "grey.dark",
    transform: "rotate(180deg)",
  },

  defaultPaperProps: {
    height: "100vh",
    overflow: "visible",
    borderRadius: "16px 16px 0 0",
  },

  defaultChildrenWrapper: {
    overflow: "auto",
    padding: "0 16px 16px",
    ...mainTheme.scrollbars.default,
  },

  titleBox: {
    gap: "16px",
    padding: "0px",
    display: "flex",
    fontSize: "20px",
    alignItems: "center",
    flexDirection: "row",
  },

  footer: {
    borderTop: "1px solid",
    flexDirection: "column",

    padding: "16px 16px 36px 16px",
    borderColor: "content.grey-100",
    backgroundColor: "dominant.white",
  },

  header: {
    gap: "10px",
    display: "flex",
    fontSize: "20px",
    fontWeight: "500",
    color: "grey.dark",
    alignItems: "center",
    lineHeight: "23.2px",
    marginBottom: "16px",
    padding: "16px 16px 0",
    justifyContent: "space-between",
  },
};
