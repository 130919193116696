/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { TPriceDetails } from "../model/paymentTypes";
import { EContentTypes } from "./constants";

import { TDataForAnalytics } from "@/features/booking/BookOrderButton/model/types";

import { EFlightTypes } from "@/shared/model/types/common";
import { EFlightModalTypes } from "@/shared/model/types/tour-content";
import {
  TTourItem,
  TAadditionalTourItemFields,
} from "@/shared/model/types/tours";
import { TTourContentInfo } from "@/shared/ui/TourContentInfo/model/types";

export const getTransferText = (type: null | EFlightTypes) => {
  switch (type) {
    case EFlightTypes.Charter:
      return "GROUP_TRANSFER";
    case EFlightTypes.Gds:
      return "NOT_AVAILABLE_2";
    default:
      return "-";
  }
};

export const getModalTexts = (type: null | EFlightModalTypes) => {
  switch (type) {
    case EFlightModalTypes.Charter:
      return {
        title: "CALCULATING_TOUR_PACKAGE_COST",
        message: "CALCULATING_TICKET_AND_TOUR_COST",
      };
    case EFlightModalTypes.Gds:
      return {
        message: null,
        title: "SEARCHING_FOR_TICKETS_ON_REGULAR_FLIGHTS",
      };
    case EFlightModalTypes.Change:
      return {
        message: null,
        title: `RECALCULATING_COST_WITH_SELECTED_FLIGHT`,
      };
    default:
      return {
        message: "TRAVELS_QUICKLY_SOLD_OUT",
        title: "CHECKING_TOUR_AVAILABILITY",
      };
  }
};

export const generateDataForProps = (
  tourDetails: TTourItem & TAadditionalTourItemFields,
  type: EContentTypes,
) => {
  switch (type) {
    case EContentTypes.Analytics:
      return {
        price: tourDetails.price ?? 0,
        adults: String(tourDetails.adults),
        mealType: tourDetails.meal.name ?? "",
        country: tourDetails.hotel.country.name,
        dateTo: tourDetails.check_out ?? tourDetails.date_to,
        dateFrom: tourDetails.check_in ?? tourDetails.date_from,
        regions: tourDetails.hotel.locations.map((item) => item.name),
        children: Number(tourDetails.children) + Number(tourDetails.infants),
      } as TDataForAnalytics;

    case EContentTypes.Price:
      return {
        id: tourDetails.id,
        price: tourDetails.price ?? 0,
        fullPrice: tourDetails.full_price ?? 0,
        discounts: tourDetails.discounts ?? [],
        bonuses: tourDetails.bonus_amount ?? 0,
      } as TPriceDetails;

    case EContentTypes.Content:
      return {
        nights: tourDetails.nights,
        adults: String(tourDetails.adults),
        flightType: tourDetails.flight_type,
        mealName: tourDetails.meal.name ?? "",
        roomName: tourDetails.room.name ?? "",
        nightsTotal: tourDetails.nights_total,
        dateTo: tourDetails.check_out ?? tourDetails.date_to,
        dateFrom: tourDetails.check_in ?? tourDetails.date_from,
        childrenAmount:
          Number(tourDetails.children) + Number(tourDetails.infants),
      } as TTourContentInfo;
    default:
      return tourDetails;
  }
};
