import { COLORS } from "shared/ui/styles";

export const styles = {
  text: {
    fontSize: "16px",
    lineHeight: "16px",
    color: COLORS.LIGHT_GRAY,
  },

  lineContainer: {
    display: "flex",
    marginBottom: "12px",
    alignItems: "center",
    justifyContent: "space-between",
  },

  totalContainer: {
    fontWeight: 500,
    marginBottom: 0,
    fontSize: "24px",
    marginTop: "4px",
    lineHeight: "28px",
  },
};
