import { FC, SetStateAction } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, ModalProps, Typography } from "@mui/material";

import { styles } from "./styles";

type TMobileButtonPopup = ModalProps & {
  title: string;
  open?: boolean;
  onClose: () => void;
  children: JSX.Element;
  setOpen: (value: SetStateAction<boolean>) => void;
};

export const MobileButtonPopup: FC<TMobileButtonPopup> = ({
  children,
  title = "",
  open = false,
  onClose = (f) => f,
  setOpen = (f) => f,
}) => {
  return (
    <Modal
      open={open}
      sx={styles.backdrop}
      onBackdropClick={onClose}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.container}>
        <Box sx={styles.container.heading}>
          <Typography fontSize={20} fontWeight={500}>
            {title}
          </Typography>
          <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
        </Box>
        <Box sx={{ width: "100%" }}>{children}</Box>
      </Box>
    </Modal>
  );
};
