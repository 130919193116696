import { NextPageContext } from "next";
import Image from "next/image";
import { FC } from "react";

import { useTranslations } from "next-intl";

import { ErrorLayout } from "./ErrorLayout";

import NotFoundImage from "@/public/img/errors/404.png";

type TProps = {
  status?: number;
  error?: Error | NextPageContext["err"];
  textContent?: {
    text: string;
    subtitle: string;
  };
};

const DEFAULT_TEXTS = {
  subtitle: "INTERNAL_SERVER_ERROR",
  text: `UNEXPECTED_ERROR_ON_SERVER_IT_WILL_BE_FIXED_SOON`,
};

export const ErrorComponent: FC<TProps> = ({
  status,
  textContent = DEFAULT_TEXTS,
}) => {
  const t = useTranslations();
  const { text, subtitle } = textContent;

  return (
    <>
      <ErrorLayout
        text={t(text)}
        subtitle={t(subtitle)}
        linkText={t("GO_TO_HOMEPAGE")}
        title={t("ERROR_WITH_STATUS", { status })}
        image={
          status === 404 ? (
            <Image
              width={280}
              height={160}
              alt="Not found"
              src={NotFoundImage}
              style={{
                height: "auto",
                maxWidth: "100%",
              }}
            />
          ) : undefined
        }
      />
    </>
  );
};
