/* eslint-disable perfectionist/sort-objects */

import { sample, combine, createEvent, createStore } from "effector";

import {
  getHotTourFiltersQuery,
  getConstructorPageQuery,
} from "@/shared/model/api/constructor";

const $selectedFilters = createStore<string[]>([]);

const $hotTourFilters = getHotTourFiltersQuery.$data;

const setConstructorPageUrl = createEvent<string>();

const toggleFilterSelection = createEvent<{
  id: string;
  code: string;
}>();
const resetFilters = createEvent();

$selectedFilters
  .on(toggleFilterSelection, (selectedFilters, { id }) => {
    if (selectedFilters.includes(id)) {
      return selectedFilters.filter((itemId) => itemId !== id);
    } else {
      return [...selectedFilters, id];
    }
  })
  .reset(resetFilters);

const $url = getConstructorPageQuery.$data.map((item) => {
  return item?.url ?? "";
});

const $idToCodeMap = getHotTourFiltersQuery.$data.map((filters) => {
  return (
    filters?.reduce(
      (acc, { code, options }) => {
        return {
          ...acc,
          ...options.reduce(
            (optAcc, option) => ({
              ...optAcc,
              [option.value]: code,
            }),
            {},
          ),
        };
      },
      {} as Record<string, string>,
    ) ?? {}
  );
});

export const $hotTourRequestPayload = combine(
  $selectedFilters,
  $idToCodeMap,
  (selectedFilters, idToCodeMap) => {
    const requestPayload: Record<string, string | string[]> = {};

    selectedFilters.forEach((selectedId) => {
      const code = idToCodeMap[selectedId];
      if (!code) return;

      requestPayload[code] = requestPayload[code] || [];
      (requestPayload[code] as string[]).push(selectedId);
    });

    return requestPayload;
  },
);

sample({
  clock: $hotTourRequestPayload,
  source: $url,
  fn: (url, params) => {
    return {
      url,
      params,
    };
  },
  target: getConstructorPageQuery.start,
});

sample({
  clock: setConstructorPageUrl,
  fn: (url) => {
    return {
      url,
    };
  },
  target: getConstructorPageQuery.start,
});

export const $$hotTourModels = {
  $selectedFilters,
  resetFilters,
  toggleFilterSelection,
  setConstructorPageUrl,
  $hotTourFilters,
};
