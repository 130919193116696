import { BONUSES_LINK } from "@/shared/lib/constants";

import BonusesIcon from "@/public/icons/common/bonuses.svg";
import TouristsIcon from "@/public/icons/common/tourists.svg";
import FavoritesIcon from "@/public/icons/menu/favourites.svg";
import NotificationIcon from "@/public/icons/menu/notifications.svg";
import OrdersIcon from "@/public/icons/menu/orders.svg";
import SettingsIcon from "@/public/icons/menu/settings.svg";

export const MY_ORDERS_LINK = "/profile/my-orders";

export enum ECounterVariants {
  Bonuses = "BONUSES",
  Orders = "MY_ORDERS",
  Favorites = "FAVORITES",
  Tourists = "MY_TOURISTS",
  Settings = "PROFILE_SETTINGS",
  Notifications = "NOTIFICATIONS",
}

export const PROFILE_LINKS = [
  {
    id: "bonuses",
    link: BONUSES_LINK,
    name: ECounterVariants.Bonuses,
    // IOS issue, have to set exact dimentions.
    icon: <BonusesIcon width={24} height={24} />,
  },
  {
    id: "my_orders",
    icon: <OrdersIcon />,
    link: MY_ORDERS_LINK,
    name: ECounterVariants.Orders,
  },
  {
    id: "favorites",
    icon: <FavoritesIcon />,
    link: "/profile/favorites",
    name: ECounterVariants.Favorites,
  },
  {
    id: "passengers",
    icon: <TouristsIcon />,
    link: "/profile/passengers",
    name: ECounterVariants.Tourists,
  },

  {
    id: "settings",
    icon: <SettingsIcon />,
    link: "/profile/settings",
    name: ECounterVariants.Settings,
  },
  {
    id: "notifications",
    icon: <NotificationIcon />,
    link: "/profile/notifications",
    name: ECounterVariants.Notifications,
  },
];
