import { FC } from "react";

import Box from "@mui/material/Box";

import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";

type TCustomTags = {
  tags: string[];
};

export const CustomTags: FC<TCustomTags> = ({ tags = [] }) => {
  return (
    <Box sx={styles.hotelPage_info_list}>
      <Box sx={styles.hotelPage_info_list.ul}>
        <Condition match={!!tags}>
          {tags.map((item, index) => (
            <Box key={index} sx={styles.hotelPage_info_list.ul.li}>
              {item}
            </Box>
          ))}
        </Condition>
      </Box>
    </Box>
  );
};
