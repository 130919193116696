export const styles = {
  title: {
    marginTop: "6px",
    fontSize: "16px",
    lineHeight: "18.56px",
  },

  wrapper: {
    width: "100%",
    borderRadius: "8px",
    padding: "15px 10px",
  },

  subtitle: {
    marginTop: "2px",
    fontSize: "14px",
    color: "grey.light",
    lineHeight: "16.24px",
  },

  fieldName: (isError: boolean) => ({
    fontSize: "12px",
    lineHeight: "13.92px",
    textTransform: "uppercase",
    color: isError ? "error.main" : "grey.light",
  }),
};
