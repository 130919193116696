/* eslint-disable perfectionist/sort-objects */

import { sample, createEvent, createStore } from "effector";

import { EMenuTab } from "@/shared/lib/constants/navbar";
import { EPopupType } from "@/shared/model/types/header-menu";

export const $openedPopoverType = createStore<null | EPopupType>(null);

export const $isProfileDrawerOpen = createStore(false);
export const $isLinksDrawerOpen = createStore(false);

export const $profileMenuTab = createStore(EMenuTab.Main);

export const closePopover = createEvent();
export const openPopover = createEvent<EPopupType>();

$openedPopoverType.on(openPopover, (_, v) => v).reset(closePopover);

export const closeProfileAndLinksDrawer = createEvent();
export const openProfileDrawer = createEvent();
export const openLinksDrawer = createEvent();

export const changeProfileTab = createEvent<EMenuTab>();
export const resetProfileMenuTab = createEvent();

sample({
  clock: closeProfileAndLinksDrawer,
  fn: () => false,
  target: [$isProfileDrawerOpen, $isLinksDrawerOpen],
});

$isProfileDrawerOpen.on(openProfileDrawer, () => true);
$isLinksDrawerOpen.on(openLinksDrawer, () => true);

$profileMenuTab.on(changeProfileTab, (_, v) => v);
$profileMenuTab.reset([resetProfileMenuTab, closeProfileAndLinksDrawer]);
