"use client";
import { FC } from "react";

import { Typography } from "@mui/material";

import { styles } from "./styles";

import { RoundedBorderContainer } from "@/shared/ui";

type TProps = {
  title: string;
  subtitle?: string;
  children?: JSX.Element;
};

export const EmptyProfileList: FC<TProps> = ({ title, subtitle, children }) => {
  return (
    <RoundedBorderContainer sx={styles.notFoundBox}>
      <Typography sx={styles.title}>{title}</Typography>
      {subtitle && <Typography sx={styles.subtitle}>{subtitle}</Typography>}
      {children}
    </RoundedBorderContainer>
  );
};
