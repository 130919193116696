import { FC } from "react";

import { Box, SxProps, Typography, useMediaQuery } from "@mui/material";
import * as CSS from "csstype";
import { DollarSign } from "lucide-react";

import { TooltipWithTextSlice } from "../TooltipWithTextSlice";
import { AmenityIcon } from "./AmenityIcon";
import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";

type TProps = {
  name: string;
  code?: string;
  paid?: boolean;
  wrapperSx?: SxProps;
  description?: string;
  iconStyles?: CSS.Properties;
};

export const AmenityRenderer: FC<TProps> = ({
  name,
  code,
  paid,
  description,
  wrapperSx = {},
  iconStyles = {},
}) => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ ...styles.wrapper, ...wrapperSx }}>
      {paid ? (
        // TODO: use style until we rewrite project with shadcn/ui & tailwind
        <DollarSign size={20} style={{ ...styles.dollarIcon, ...iconStyles }} />
      ) : (
        <AmenityIcon code={code} iconStyles={iconStyles} />
      )}

      <Box sx={styles.amenitiesInfo}>
        <TooltipWithTextSlice title={name} sliceAt={isPhone ? Infinity : 28} />

        {description && (
          <Typography sx={styles.amenitiesDescription}>
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
