import { FC } from "react";

import { EqualizerOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslations } from "next-intl";

import { styles } from "@/shared/ui/ReviewCardPlaceHolder/styles";

export const ReviewCardPlaceHolder: FC = () => {
  const t = useTranslations();

  return (
    <Box sx={styles.placeholderCard}>
      <Box sx={styles.placeHolder}>
        <EqualizerOutlined sx={styles.equalizerIcon} />
        <Typography sx={styles.placeholderText}>
          {t("REVIEWS_AND_RATINGS_FOR_THIS_HOTEL_ARE_NOT_AVAILABLE")}
        </Typography>
      </Box>
    </Box>
  );
};
