import { attach } from "effector";

import { TFFRequest, THotelData } from "../../types/common";
import { THotelQueryParams } from "../../types/hotels";

import { requestFx } from "@/shared/config";

type TQueryResponse = TFFRequest<THotelData>;

export const getHotelDetailsFx = attach<THotelQueryParams, TQueryResponse>({
  effect: requestFx,
  mapParams: ({ include, headers, hotelSlug }) => ({
    headers,
    url: `hotels/${hotelSlug}?include=${include}`,
  }),
});
